export default StatusTypeRouterConfig;

// @ngInject
function StatusTypeRouterConfig($stateProvider) {
  $stateProvider.state('statustype', {
    url: '/statustype',
    template: '<shu-statustype-dummy-container></shu-statustype-dummy-container>',
    needAuthenticatedUser: false
  });
}

// @ngInject
function StatusTypeRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.statustypeId = $stateParams.statustypeId;
}