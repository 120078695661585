import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/status-type-list.service';

export default StatusTypeListStore;

const EVENTS = {
  STATUS_TYPE_LIST_STATE_CHANGED: 'shu-status-type-list.status-type-list-state-changed'
};

// @ngInject
function StatusTypeListStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STATUS_TYPE_LIST_RESULTS_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STATUS_TYPE_LIST_RESULTS_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STATUS_TYPE_LIST_RESULTS_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      statusType: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STATUS_TYPE_LIST_STATE_CHANGED);
  }

  function onLoadSuccess(event, statusType) {
    state = Immutable({
      statusType,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STATUS_TYPE_LIST_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      statusType: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STATUS_TYPE_LIST_STATE_CHANGED);
  }

}

export { EVENTS };
