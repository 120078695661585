import { STATUS } from '../base/services/url-resolver.service';
import { REFERENTIEL } from '../base/services/url-resolver.service';
import { NATURALISTE } from '../base/services/url-resolver.service';

export default {
  searchEndpoint: {
    server: STATUS,
    path: '/publication'
  },
  advancedSearchEndpoint: {
    server: STATUS,
    path: '/publication/search'
  },
  publicationDetailsEndpoint: {
    server: STATUS,
    path: '/publication/{publicationId}'
  },
  publicationExportEndpoint: {
    server: STATUS,
    path: '/publication/{publicationId}/csv'
  },
  searchTerrUnitEndPoint: {
    server: REFERENTIEL,
    path: '/terr_unit/search?'
  },
  searchStudyEndPoint: {
    server: NATURALISTE,
    path: '/study?'

  },
  publicationStatutesEndPoint: {
    server: STATUS,
    path: '/publication/{publicationId}/statutes'
  },
  publicationStatutesMetadataEndPoint: {
    server: STATUS,
    path: '/publication/{publicationId}/statutes/metadata'
  },
  publicationImportEndpoint: {
    server: STATUS,
    path: '/publication/{publicationId}/upload'
  },
  publicationTaxCheckEndpoint: {
    server: STATUS,
    path: '/publication/{publicationId}/taxon-check'
  },
  publicationInputMaskEndpoint: {
    server: STATUS,
    path: '/publication/{publicationId}/inputMask'
  }
};
