import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/publication.service';

export default PublicationServiceStore;

const EVENTS = {
  PUBLICATION_SERVICE_STATE_CHANGED: 'shu-publication-service.publication-service-state-changed',
  PUBLICATION_SIMILARITY_FOUND: 'shu-publication-service.publication-similarity-found',
  PUBLICATION_CREATED: 'SHU-PUBLICATION_CREATED',
  PUBLICATION_DELETED: 'SHU-PUBLICATION_DELETED',
  PUBLICATION_UPDATED: 'SHU-PUBLICATION_UPDATED',
};

// @ngInject
function PublicationServiceStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_TS_SEARCH_PROCESSING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_TS_SEARCH_DONE, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_TS_SEARCH_ERROR, onLoadError);

  //$rootScope.$on(SERVICE_EVENTS.PUBLICATION_SIMILARITY_PROCESSING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_SIMILARITY_FOUND, onSimilarityFound);
  //$rootScope.$on(SERVICE_EVENTS.PUBLICATION_SIMILARITY_ERROR, onLoadError);

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_USER_LIST_PROCESSING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_USER_LIST_DONE, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_USER_LIST_ERROR, onLoadError);

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_MODERATOR_LIST_PROCESSING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_MODERATOR_LIST_DONE, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_MODERATOR_LIST_ERROR, onLoadError);

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_CREATION_PROCESS_DONE, onCreateSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_DELETION_DONE, onDeleteSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_UPDATE_DONE, onUpdateSuccess);


  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      publication: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SERVICE_STATE_CHANGED);
  }

  function onLoadSuccess(event, publication) {
    state = Immutable({
      publication,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SERVICE_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      publication: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SERVICE_STATE_CHANGED);
  }

  function onSimilarityFound(event, publication) {
    state = Immutable({
      publication,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SERVICE_SIMILARITY_FOUND);
  }

  function onCreateSuccess() {
    $rootScope.$broadcast(EVENTS.PUBLICATION_CREATED);
  }

  function onDeleteSuccess() {
    $rootScope.$broadcast(EVENTS.PUBLICATION_DELETED);
  }

  function onUpdateSuccess(event, publication) {
    state = Immutable({
      publication,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_UPDATED);
  }

}

export { EVENTS };
