import angular from 'angular';

import _ from 'lodash';

import { EVENTS as STATUS_VALUE_LIST_STORE_EVENTS } from '../../../dictionary/stores/dictionary-ref.store'

import { DictionaryRefService } from '../../../dictionary/services/dictionary-ref.service'
import DictionaryRefStore from '../../../dictionary/stores/dictionary-ref.store'

require('./status-value-select.scss');

export default StatusValueSelectDirective;

// @ngInject
function StatusValueSelectDirective() {
  return {
    restrict: 'E',
    scope: {
      statusTypeIdProvider: '&statusTypeId',
      initialValueIdProvider: '&initialValueId',
      placeholder: '@',
      required: '@',
      multipleProvider: '&multiple',
      name: "@",
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit'
    },
    bindToController: true,
    controller: StatusValueSelectController,
    controllerAs: 'statusValueSelectCtrl',
    template: require('./status-value-select.html'),
    replace: true
  };
}

// @ngInject
function StatusValueSelectController($scope, DictionaryRefService: DictionaryRefService, DictionaryRefStore) {
  var statusValueSelectCtrl = this;

  statusValueSelectCtrl.onChange = onChange;
  statusValueSelectCtrl.currentValue = null;
  statusValueSelectCtrl.deletedValue = null;

  $scope.$on(STATUS_VALUE_LIST_STORE_EVENTS.DICTIONARY_STATUS_VALUE_STATE_CHANGED, reloadResults);
  statusValueSelectCtrl.availableValues = [];
  statusValueSelectCtrl.state = {};

  $scope.$watch(statusValueSelectCtrl.canEditProvider, (canEdit) => {
    statusValueSelectCtrl.canEdit = canEdit;
  });

  $scope.$watch(statusValueSelectCtrl.multipleProvider, (multiple) => {
    statusValueSelectCtrl.multiple = multiple;
  });

  $scope.$watch(statusValueSelectCtrl.statusTypeIdProvider, (statusTypeId) => {
    if (!statusTypeId) {
      return;
    }
    DictionaryRefService.getStatusValuesForStatus(statusTypeId);
    //DictionaryRefService.getStatusValuesForStatus(2320);
  });

  var removeWatcher = $scope.$watch(statusValueSelectCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId) {
      return;
    }

    statusValueSelectCtrl.initialValueId = initialValueId;
    updateInitialValue();

    removeWatcher();
  });

  function reloadResults() {
    statusValueSelectCtrl.state = DictionaryRefStore.getState();

    if (!statusValueSelectCtrl.state.statusValues) {
      return;
    }

    statusValueSelectCtrl.availableValues = statusValueSelectCtrl.state.statusValues.values;

    /*angular.forEach(_.reject(statusValueSelectCtrl.state.statusValues, 'deleted'), function(x) {
      statusValueSelectCtrl.availableValues.push(x.asMutable());
    })*/

    updateInitialValue();
  }

  function updateInitialValue() {
    if (!statusValueSelectCtrl.initialValueId || !statusValueSelectCtrl.state.loaded) {
      return;
    }

    var initialValue = _.find(statusValueSelectCtrl.state.statusValues, {key: statusValueSelectCtrl.initialValueId});
    if (!initialValue) {
      return;
    }

    onChange(initialValue);
    if (initialValue.deleted) {
      statusValueSelectCtrl.deletedValue = initialValue;
      return;
    }

    statusValueSelectCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {
    if (!newValue) {
      return;
    }
    statusValueSelectCtrl.onChangeFn() && statusValueSelectCtrl.onChangeFn()(newValue);
  }

}
