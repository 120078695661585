import _ from 'lodash';

const EVENTS = {
  // Action lancée quand un des champs du formulaire de recherche est modifié
  EXPERT_SEARCH_QUERY_UPDATE: 'shu-expert-search.expert-search-query-update',
  // Actions lancées quand l'utilisateur lance une recherche
  EXPERT_SEARCH_RESULTS_LOADING: 'shu-expert-search.expert-search-results-loading',
  EXPERT_SEARCH_RESULTS_LOAD_SUCCESS: 'shu-expert-search.expert-search-results-load-success',
  EXPERT_SEARCH_RESULTS_LOAD_ERROR: 'shu-expert-search.expert-search-results-load-error',
};

export default ExpertSearchService;

// @ngInject
function ExpertSearchService($rootScope, ExpertRepository, LxNotificationService) {
  return {onUpdateForm};

  function onUpdateForm(query) {
    $rootScope.$emit(EVENTS.EXPERT_SEARCH_QUERY_UPDATE, {query});
    $rootScope.$emit(EVENTS.EXPERT_SEARCH_RESULTS_LOADING);

    ExpertRepository.query(query)
      .then((result) => {
        $rootScope.$emit(EVENTS.EXPERT_SEARCH_RESULTS_LOAD_SUCCESS, result);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des experts. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.EXPERT_SEARCH_RESULTS_LOAD_ERROR);
      });
  }
}
export { EVENTS };
