import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/settings.service';

const EVENTS = {
  SETTINGS_STATE_CHANGED: 'shu-settings.settings-state-changed'
};

export default SettingsStore;

// @ngInject
function SettingsStore($rootScope, SettingsConfiguration, SettingsService) {
  var state = Immutable({settings: null, loading: false, loaded: false, error: false});

  $rootScope.$on(SERVICE_EVENTS.SETTINGS_LOADING, onSettingsLoading);
  $rootScope.$on(SERVICE_EVENTS.SETTINGS_LOAD_SUCCESS, onSettingsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.SETTINGS_LOAD_ERROR, onSettingsLoadError);
  $rootScope.$on(SERVICE_EVENTS.SETTINGS_UPDATE_SUCCESS, onUpdateSettingsSuccess);

  return {get};

  function get() {
    return state;
  }

  function onUpdateSettingsSuccess(events, settings) {
    state = Immutable({settings: _.defaults(settings, state.settings), loading: false, loaded: true});
    $rootScope.$broadcast(EVENTS.SETTINGS_STATE_CHANGED);
  }

  function onSettingsLoading() {
    state = Immutable({settings: null, loading: true, loaded: false});
    $rootScope.$broadcast(EVENTS.SETTINGS_STATE_CHANGED);
  }

  function onSettingsLoadSuccess(event, settings) {
    state = Immutable({settings: _.defaults(settings || {}, SettingsConfiguration.settings), loading: false, loaded: true});
    $rootScope.$broadcast(EVENTS.SETTINGS_STATE_CHANGED);
  }

  function onSettingsLoadError() {
    // Si l'initialisation échoue, on retourne les valeurs par défaut
    state = Immutable({settings: SettingsConfiguration.settings, loading: false, loaded: true});
    $rootScope.$broadcast(EVENTS.SETTINGS_STATE_CHANGED);
  }
}

export { EVENTS };
