import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study.service';

export default StudyListStore;

const EVENTS = {
  STUDY_LIST_STATE_CHANGED: 'shu-study-list.study-list-state-changed'
};

// @ngInject
function StudyListStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_LIST_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_LIST_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_LIST_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      studies: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_LIST_STATE_CHANGED);
  }

  function onLoadSuccess(event, studies) {
    state = Immutable({
      studies,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_LIST_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      studies: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_LIST_STATE_CHANGED);
  }

}

export { EVENTS };
