
require('./tx-group-icon.scss');

export default TxGroupIconDirective;

// @ngInject
function TxGroupIconDirective() {
  return {
    restrict: 'E',
    scope: {
      txGroup: '@'
    },
    bindToController: true,
    controller: TxGroupIconController,
    controllerAs: 'txGroupIconCtrl',
    template: require('./tx-group-icon.html'),
    replace: true
  };
}

// @ngInject
function TxGroupIconController($scope) {
  var txGroupIconCtrl = this;

  $scope.$watch(() => txGroupIconCtrl.txGroup, () => {
    if (txGroupIconCtrl.txGroup) {
      txGroupIconCtrl.content = require(`!!raw-loader!svgo-loader!./${txGroupIconCtrl.txGroup}.svg`);
    }
  });
}
