import { CONTENT_TYPE } from '../services/x-www-form-urlencoded.service';

export default XWwwFormUrlEncodedInterceptor;

// @ngInject
function XWwwFormUrlEncodedInterceptor(XWwwFormUrlEncoded) {
  return {
    'request': function(config) {
      if (config.method.toUpperCase() === 'POST' &&
          config.headers['Content-Type'] === CONTENT_TYPE) {
        config.data = XWwwFormUrlEncoded.transform(config.data);
      }
      return config;
    }
  };
}
