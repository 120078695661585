import _ from 'lodash';

require('./status-value-edit.scss');

export default StatusValueEditDirective;

// @ngInject
function StatusValueEditDirective() {
  return {
    restrict: 'E',
    scope: {
      taxaStatus: '=',
      onChangeFn: '&onChange',
      onRemoveFn: '&onRemove'
    },
    bindToController: true,
    controller: StatusValueEditController,
    controllerAs: 'statusValueEditCtrl',
    template: require('./status-value-edit.html'),
    replace: true
  };
}

// @ngInject
function StatusValueEditController($scope) {
  var statusValueEditCtrl = this;

  statusValueEditCtrl.onStatusValueChange = onStatusValueChange;
  statusValueEditCtrl.onEdit = onEdit;
  statusValueEditCtrl.onRemove = onRemove;
  statusValueEditCtrl.onSubmitEdition = onSubmitEdition;
  statusValueEditCtrl.onCancelEdition = onCancelEdition;

  statusValueEditCtrl.pendingNewStatusValue = null;

  statusValueEditCtrl.inEdition = false;


  $scope.$watch(() => statusValueEditCtrl.taxaStatus, () => {
    if (statusValueEditCtrl.taxaStatus) {
      //console.log(statusValueEditCtrl.taxaStatus);
    }
  });

  function onStatusValueChange(newValue) {
    statusValueEditCtrl.pendingNewStatusValue = newValue;
  }

  function onEdit() {
    statusValueEditCtrl.inEdition = true;
  }

  function onRemove() {
    statusValueEditCtrl.onRemoveFn() && statusValueEditCtrl.onRemoveFn()(statusValueEditCtrl.taxaStatus);
  }

  function onCancelEdition() {
      statusValueEditCtrl.inEdition = false;
  }

  function onSubmitEdition() {
    statusValueEditCtrl.inEdition = false;
    //console.log(statusValueEditCtrl.pendingNewStatusValue)

    statusValueEditCtrl.pendingNewStatusValue = _.rename(statusValueEditCtrl.pendingNewStatusValue, 'key', 'valueKey')
    statusValueEditCtrl.pendingNewStatusValue = _.rename(statusValueEditCtrl.pendingNewStatusValue, 'label', 'valueLabel')

    let newTaxaStatus = _.merge(statusValueEditCtrl.taxaStatus.asMutable(), statusValueEditCtrl.pendingNewStatusValue);

    statusValueEditCtrl.onChangeFn() && statusValueEditCtrl.onChangeFn()(newTaxaStatus);
  }

  _.rename = function(obj, key, newKey) {

    if(_.includes(_.keys(obj), key)) {
      obj[newKey] = _.clone(obj[key], true);

      delete obj[key];
    }

    return obj;
  };

}
