import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/publication-search.service';

const EVENTS = {
  PUBLICATION_SEARCH_QUERY_STATE_CHANGED: 'shu-publication-search.publication-search-query-state-changed',
  PUBLICATION_SEARCH_RESULTS_STATE_CHANGED: 'shu-publication-search.publication-search-results-state-changed',
  PUBLICATION_TERR_UNIT_SEARCH_RESULTS_STATE_CHANGED: 'shu-publication-search.publication-terr-unit-search-results-state-changed',
  PUBLICATION_STUDY_SEARCH_RESULTS_STATE_CHANGED: 'shu-publication-search.publication-study-search-results-state-changed'
};

export default PublicationSearchStore;

// @ngInject
function PublicationSearchStore($rootScope, PublicationSearchService) {
  var queryState = Immutable({});
  var resultsState = Immutable({});
  var resultsTerrUnitState = Immutable({});
  var resultsStudyState = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_SEARCH_RESULTS_LOADING, onPublicationSearchResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_SEARCH_RESULTS_LOAD_SUCCESS, onPublicationSearchResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_SEARCH_RESULTS_LOAD_ERROR, onPublicationSearchResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_LOADING, onTerrUnitSearchResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_LOAD_SUCCESS, onTerrUnitSearchResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_LOAD_ERROR, onTerrUnitSearchResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_LOADING, onStudySearchResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_LOAD_SUCCESS, onStudySearchResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_LOAD_ERROR, onStudySearchResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_SEARCH_NEXT_RESULTS_LOADING, onPublicationSearchNextResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, onPublicationSearchNextResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_SEARCH_NEXT_RESULTS_LOAD_ERROR, onPublicationSearchNextResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_SEARCH_QUERY_UPDATE, onUpdatePublicationSearchQuery);

  return { getQueryState, getResultsState, getResultsTerrUnitState, getResultsStudyState };

  function getQueryState() {
    return queryState;
  }

  function getResultsState() {
    return resultsState;
  }

  function getResultsTerrUnitState() {
    return resultsTerrUnitState;
  }

  function getResultsStudyState() {
    return resultsStudyState;
  }

  function onUpdatePublicationSearchQuery(events, {query, txGroups, types, terrUnits}) {
  
    queryState = Immutable({query, txGroups, types, terrUnits});
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_QUERY_STATE_CHANGED);
  }

  function onUpdateTerrUnitSearchQuery(events, {terrUnit}) {
    queryState = Immutable({terrUnit});
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_QUERY_STATE_CHANGED);
  }

  function onUpdateStudySearchQuery(events, {study}) {
    queryState = Immutable({study});
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_QUERY_STATE_CHANGED);
  }

  function onTerrUnitSearchResultsLoading() {
    //console.log("loading");
    resultsTerrUnitState = Immutable({
      results: null,
      totalResults: 0,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTerrUnitSearchResultsLoadSuccess(event, results, totalResults) {

    resultsTerrUnitState = Immutable({
      results,
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTerrUnitSearchResultsLoadError() {
    resultsTerrUnitState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onStudySearchResultsLoading() {
    //console.log("loading");
    resultsStudyState = Immutable({
      results: null,
      totalResults: 0,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onStudySearchResultsLoadSuccess(event, results, totalResults) {

    resultsStudyState = Immutable({
      results,
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onStudySearchResultsLoadError() {
    resultsStudyState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onPublicationSearchResultsLoading() {
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onPublicationSearchResultsLoadSuccess(event, results, totalResults) {
    resultsState = Immutable({
      results,
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onPublicationSearchResultsLoadError() {
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onPublicationSearchNextResultsLoading() {
    resultsState = Immutable({
      results: resultsState.results,
      totalResults: resultsState.totalResults,
      loading: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onPublicationSearchNextResultsLoadSuccess(event, results, totalResults) {
    resultsState = Immutable({
      results: [].concat.call(resultsState.results, results),
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onPublicationSearchNextResultsLoadError() {
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_SEARCH_RESULTS_STATE_CHANGED);
  }

}

export { EVENTS };
