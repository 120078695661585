// http://www.software-architects.com/devblog/2014/10/24/AngularJS-Provider-in-TypeScript

// The following class represents the provider
export class BasePath implements ng.IServiceProvider {
    private basePath;

    // Configuration function
    public setBasePath(basePath: string) {
        this.basePath = basePath;
    }

    // Provider's factory function
    public $get() {
        return this.basePath;
    }
}
