export default DictionnaryRouterConfig;

// @ngInject
function DictionnaryRouterConfig($stateProvider) {
  $stateProvider.state('dictionary', {
    url: '/dictionary',
    template: '<shu-dictionary-status-value-container></shu-dictionary-status-value-container>',
    needAuthenticatedUser: false
  });
}

// @ngInject
function DictionnaryRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.dictionnaryId = $stateParams.dictionnaryId;
}
