import * as models from '@biotope/shuriken-ng-naturaliste-api'
import { StudyApi } from '@biotope/shuriken-ng-naturaliste-api'
import _ from 'lodash';

export const EVENTS = {
  // Actions lancées quand l'utilisateur charge les txgroupes
  STUDY_LIST_LOADING: 'shu-study.study-list-results-loading',
  STUDY_LIST_LOAD_SUCCESS: 'shu-study.study-list-results-load-success',
  STUDY_LIST_LOAD_ERROR: 'shu-study.study-list-results-load-error',
}

// @ngInject
export class StudyService {

  private studyApi: StudyApi;
  private toaster: any;
  private $rootScope: any;
  //private $scope: any;

  /** @ngInject */
  constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, StudyApi: StudyApi) {
    this.studyApi = StudyApi;
    this.toaster = LxNotificationService;
    this.$rootScope = $rootScope;
  }

  public onUpdateStudyForm(query: string) {
    //console.log(this.$rootScope);

    this.$rootScope.$emit(EVENTS.STUDY_LIST_LOADING);

    return this.studyApi.searchTerrUnit(query)
    .then(
        (response) => {
        this.$rootScope.$emit(EVENTS.STUDY_LIST_LOAD_SUCCESS, response.data);
      }, (error: any) => {
        this.toaster.error('Une erreur est survenue au chargement des études. ' + (error.data || ''));
        this.$rootScope.$emit(EVENTS.STUDY_LIST_LOAD_ERROR, error);
      });
  }

  public resetStudy() {
    this.onUpdateStudyForm('');
  }

}
