import { EVENTS as PUBLICATION_DETAILS_STORE_EVENTS } from '../../../publication/stores/publication-details.store';
import { EVENTS as SECTION_DETAILS_STORE_EVENTS } from '../../../section/stores/section-details.store';
import { EVENTS as PROFILE_STORE_EVENTS } from '../../../profile/stores/profile.store';
import { EVENTS as SECTION_SERVICE_STORE_EVENTS } from '../../stores/section-service.store';

require('../../../section/components/section-details-container/section-details-container.scss');

import _ from 'lodash';

export default SectionDetailsContainerDirective;

/** @ngInject */
function SectionDetailsContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      publicationId: '@',
      sectionId: '@'
    },
    bindToController: true,
    controller: SectionDetailsContainerController,
    controllerAs: 'sectionDetailsContainerCtrl',
    template: require('../../../section/components/section-details-container/section-details-container.html'),
    replace: true
  };
}

/** @ngInject */
function SectionDetailsContainerController($scope, $state, HeaderService, PublicationDetailsService, PublicationDetailsStore,
  SectionService: any, SectionDetailsStore, ProfileStore) {
  var sectionDetailsContainerCtrl = this;

  sectionDetailsContainerCtrl.publicationState = {};
  sectionDetailsContainerCtrl.sectionState = {};
  sectionDetailsContainerCtrl.profileState = ProfileStore.get();

  sectionDetailsContainerCtrl.headerUpdated = false;

  sectionDetailsContainerCtrl.isLoaded = isLoaded;
  sectionDetailsContainerCtrl.isProcessing = isProcessing;

  sectionDetailsContainerCtrl.onSectionChanged = onSectionChanged;
  sectionDetailsContainerCtrl.onSubmit = onSubmit;

  $scope.$on(PUBLICATION_DETAILS_STORE_EVENTS.PUBLICATION_DETAILS_STATE_CHANGED, reloadPublicationDetails);
  $scope.$on(SECTION_DETAILS_STORE_EVENTS.SECTION_DETAILS_STATE_CHANGED, reloadSectionDetails);
  $scope.$on(SECTION_SERVICE_STORE_EVENTS.SECTION_UPDATED, redirectToOverview);
  $scope.$on(SECTION_SERVICE_STORE_EVENTS.SECTION_DELETED, redirectToOverview);
  $scope.$on(PROFILE_STORE_EVENTS.PROFILE_STATE_CHANGED, onProfileStateChanged);

  loadSection();

  function isLoaded() {
    return sectionDetailsContainerCtrl.publicationState.loaded && sectionDetailsContainerCtrl.publicationState.loaded;
  }

  function isProcessing() {
    return sectionDetailsContainerCtrl.publicationState.processing || sectionDetailsContainerCtrl.publicationState.processing;
  }

  function reloadPublicationDetails() {
    sectionDetailsContainerCtrl.publicationState = PublicationDetailsStore.getState();

    if (sectionDetailsContainerCtrl.publicationState.error) {
      return;
    }
    updateHeader();
  }

  function reloadSectionDetails() {
    sectionDetailsContainerCtrl.sectionState = SectionDetailsStore.getState();

    if (sectionDetailsContainerCtrl.sectionState.error) {
      return;
    }
    if (sectionDetailsContainerCtrl.sectionState.loaded && !sectionDetailsContainerCtrl.sectionState.section) {
      $state.go('publication-overview', {publicationId: sectionDetailsContainerCtrl.publicationId});
      return;
    }

    updateHeader();
  }

  function updateHeader() {
    if (!sectionDetailsContainerCtrl.publicationState.publication ||
        !sectionDetailsContainerCtrl.sectionState.section ||
        sectionDetailsContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: sectionDetailsContainerCtrl.publicationState.publication.publication.label,
        link: {
          sref: 'publication-overview',
          params: {publicationId: sectionDetailsContainerCtrl.publicationId}
        }
      },
      'Section N°' + sectionDetailsContainerCtrl.sectionState.section.id
    ]);
    sectionDetailsContainerCtrl.headerUpdated = true;
  }

  function redirectToOverview() {
    sectionDetailsContainerCtrl.publicationState = PublicationDetailsStore.getState();
    if (sectionDetailsContainerCtrl.publicationState.error) {
      return;
    }
    $state.go('publication-overview', {publicationId: sectionDetailsContainerCtrl.publicationState.publication.publication.id});
  }

  function loadSection() {
    PublicationDetailsService.load(sectionDetailsContainerCtrl.publicationId);
    SectionService.load(sectionDetailsContainerCtrl.sectionId);
  }

  function onSectionChanged(formData) {
    sectionDetailsContainerCtrl.formData = formData;
  }

  function onProfileStateChanged() {
    sectionDetailsContainerCtrl.profileState = ProfileStore.get();
  }

  function onSubmit(form) {
    SectionService.updateSection(
      sectionDetailsContainerCtrl.sectionId,
      sectionDetailsContainerCtrl.formData.data,
      form
    );
  }


}
