import angular from 'angular';

import statusValueSelect from './components/status-value-select/status-value-select.component';
import statusValueStore from './stores/status-value.store';
import { StatusValueService } from './services/status-value.service';
import statusValueEdit from './components/status-value-edit/status-value-edit.component';
import routerConfig from './routes';

const moduleName = 'shu-statusvalue';

angular.module(moduleName, ['lumx'])
  .directive('shuStatusValueEdit', statusValueEdit)
  .directive('shuStatusValueSelect', statusValueSelect)
  .service('StatusValueService', StatusValueService)
  .factory('StatusValueStore', statusValueStore)
  .config(routerConfig);

export default { moduleName };
