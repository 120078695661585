export default TaxaStatusRouterConfig;

// @ngInject
function TaxaStatusRouterConfig($stateProvider) {
  $stateProvider.state('taxastatus', {
    url: '/taxastatus',
    template: '<shu-taxastatus-dummy-container></shu-taxastatus-dummy-container>',
    needAuthenticatedUser: false
  });
}

// @ngInject
function TaxaStatusRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.taxastatusId = $stateParams.taxastatusId;
}