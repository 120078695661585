import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/status-value.service';

export default StatusValueStore;

const EVENTS = {
  STATUS_VALUE_STATE_CHANGED: 'shu-status-value-undefined.status-value-state-changed'
};

// @ngInject
function StatusValueStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STATUS_VALUE_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STATUS_VALUE_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STATUS_VALUE_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      statusValue: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STATUS_VALUE_STATE_CHANGED);
  }

  function onLoadSuccess(event, statusValue) {
    state = Immutable({
      statusValue,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STATUS_VALUE_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      statusValue: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STATUS_VALUE_STATE_CHANGED);
  }

}

export { EVENTS };
