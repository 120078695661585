import { EVENTS as IMPORT_FILE_STORE_EVENTS } from '../../stores/import-file.store';
import { EVENTS as IMPORT_FILE_SERVICE_EVENTS } from '../../services/import-file.service';
import { PUBLICATION_EXPERT_DATA_IMPORT,PUBLICATION_EXPERT_DATA_IMPORT_SOURCE } from '../../model.js';
require('./import.scss');
import * as models from '@biotope/shuriken-status-api'
export default ImportDirective;

// @ngInject
function ImportDirective() {
  return {
    restrict: 'E',
    scope: {
      publicationId: '@',
      importFileProvider: '&importFile',
      processingFileProvider: '&processingFile',
      uploadingXlsxProvider: '&uploadingXlsx',
      onSubmitFileFn: '&onSubmitFile',
      onSubmitFn: '&onSubmit',
      onConsolidationValidateFn: '&onConsolidationValidate',
      onRemoveFileFn: '&onRemoveFile',
      queryStateProvider: '&queryState',
      onUpdateFormFn: '&onUpdateForm',
      resultsStateProvider: '&resultsState',
      //  onTaxonSelectedFn: '&onTaxonSelected',
      onNextResultsActionFn: '&onNextResultsAction'
    },
    bindToController: true,
    controller: ImportController,
    controllerAs: 'importCtrl',
    template: require('./import.html'),
    replace: true
  };
}

// @ngInject
function ImportController($scope, $state, $timeout, ImportFileService, ImportFileStore, ImportRepository, LxNotificationService,
  PublicationDetailsService, PublicationDetailsStore, UrlResolverService, ImportConfiguration) {

  var importCtrl = this;

  importCtrl.PUBLICATION_EXPERT_DATA_IMPORT = PUBLICATION_EXPERT_DATA_IMPORT;
  importCtrl.fileImported = null;
  importCtrl.consolidationValue = null;
  importCtrl.wrongName;
  importCtrl.isUploaded = false;
  importCtrl.taxValid = false;
  importCtrl.consolidationReady = false;
  importCtrl.consolidationInProgress = false;
  importCtrl.fileInfo = null;
  importCtrl.onSubmit = onSubmit;
  importCtrl.onSelectFileServer = onSelectFileServer;
  importCtrl.referentiel = null;
  importCtrl.item = null;
  importCtrl.index = null;


  importCtrl.onQueryUpdated = onQueryUpdated;
  importCtrl.onSelectReferentielType = onSelectReferentielType;
  importCtrl.onSelectReferentielVersion = onSelectReferentielVersion;
  importCtrl.onConsolidationCheckValidate = onConsolidationCheckValidate;

  importCtrl.hasMoreResults = hasMoreResults;
  importCtrl.onTaxonSelected = onTaxonSelected;
  importCtrl.onNextResultsAction = onNextResultsAction;
  importCtrl.backToUpload = backToUpload;
  importCtrl.getMask = getMask;
  importCtrl.newName = [{}];
  importCtrl.newName.length = 0;

  importCtrl.getMask = getMask;

  var totalRowToConsolidate=0;


  $scope.$watch(importCtrl.resultsStateProvider, (resultsState) => {
    importCtrl.results = resultsState.results;
    importCtrl.totalResults = resultsState.totalResults;
    importCtrl.loading = resultsState.loading;
  });


  $scope.$watch(importCtrl.importFileProvider, (value) => {
    importCtrl.importFile = value;
  });

  $scope.$watch(importCtrl.processingFileProvider, (value) => {
    importCtrl.processingFile = value;
  });

  $scope.$watch(importCtrl.uploadingXlsxProvider, (value) => {
    importCtrl.uploadingXlsx = value;
  });

  $scope.$watch(importCtrl.importFileProvider, (value) => {
    importCtrl.importFile = value;
  });


  $scope.$on(IMPORT_FILE_STORE_EVENTS.IMPORT_FILE_STATE_CHANGED, onStateChanged);
  $scope.$on(IMPORT_FILE_STORE_EVENTS.TAXON_CHECK_STATE_CHANGED, onTaxCheckChanged);
  $scope.$on(IMPORT_FILE_SERVICE_EVENTS.TAXON_CHECK_SUCCESS, onTaxOk);

  function onSelectFileServer() {

    /* if (input.files.length <= 0) {
      return;
    }*/
    onSelectFile(importCtrl.fileImported, null, PUBLICATION_EXPERT_DATA_IMPORT.CSV);
  }


  function onSelectFile(file, path, format) {
    var lastDotIndex = file.name.lastIndexOf('.');
    if (lastDotIndex === -1) {
      importCtrl.fileNameFromFile = file.name;
    } else {
      importCtrl.fileNameFromFile = file.name.substring(0, lastDotIndex);
    }
    importCtrl.fileInfo = {
      file,
      path,
      source: PUBLICATION_EXPERT_DATA_IMPORT_SOURCE.SERVER,
      format
    };
    //      importCtrl.isUploaded = true;
    $timeout(function() {
      importCtrl.isUploaded = true;
    }, 1000);

  }

  function onStateChanged() {
    importCtrl.state = ImportFileStore.getState();

    if (importCtrl.state.error) {
      importCtrl.consolidationReady = false;
      $timeout(function() {
        window.location.reload();
      }, 5000);
      return;
    }
    if (importCtrl.state.processing) {
      $timeout(function() {
        importCtrl.isUploaded = false;
        importCtrl.consolidationReady = true;
      }, 1000);
      return;
    }

    importCtrl.consolidationValue = importCtrl.state.files;
    importCtrl.wrongName = importCtrl.consolidationValue.taxonConsolidation;
    importCtrl.txRefName = importCtrl.consolidationValue.txRefName;
    importCtrl.txRefVersion= importCtrl.consolidationValue.txRefVersion;
    importCtrl.filenName = importCtrl.consolidationValue.filenName;
    totalRowToConsolidate = importCtrl.wrongName.length;

    if (Array.isArray(importCtrl.wrongName) && importCtrl.wrongName.length === 0) {
      LxNotificationService.success('Import validé.');
      $state.go('publication-overview', {publicationId: importCtrl.publicationId});

    }
  }

  function backToUpload() {
    importCtrl.consolidationReady = false;
    window.location.reload();
  }

  function onTaxCheckChanged() {
    importCtrl.txState = ImportFileStore.getTaxonCheckState();
        if (importCtrl.txState.error) {
      importCtrl.consolidationReady = false;
      $timeout(function() {
        window.location.reload();
      }, 5000);


    }

  }


  function onTaxOk() {
    importCtrl.taxValid = true;
    $timeout(function() {
      backToUpload();
    }, 1700);
  }



  function onSubmit() {
    //FIXME Dirty hack !
    if(importCtrl.referentiel==null) {
      return;
    }


    importCtrl.selectedReferentiel = importCtrl.referentiel;

    ImportRepository.getLastRefVersion(importCtrl.referentiel)
    .then((result) => {
      importCtrl.selectedReferentielVersion = result.version;
    }, (error) => {
      LxNotificationService.error('Une erreur est survenue à la recherche de la version du référentiel');
    });

    /*importCtrl.selectedReferentielVersion = "1.0";
    if (importCtrl.referentiel == "TAXREF") {
      importCtrl.selectedReferentielVersion = "14.0";
    }*/

    importCtrl.onSubmitFileFn()(importCtrl.publicationId, importCtrl.fileNameFromFile, importCtrl.fileInfo, importCtrl.referentiel);
    onStateChanged();
  }


  function onQueryUpdated(query, item, index) {

    importCtrl.onUpdateFormFn()(query, importCtrl.selectedReferentiel, importCtrl.selectedReferentielVersion);
    importCtrl.item = item;
    importCtrl.index = index;

  }

  function onSelectReferentielType() {
    importCtrl.selectedReferentiel = importCtrl.referentiel;
    onQueryUpdated();
  }

  function onSelectReferentielVersion() {
    onQueryUpdated();
  }

  function hasMoreResults() {
    return importCtrl.results && importCtrl.totalResults > importCtrl.results.length;
  }


  function onTaxonSelected(name, key, txGroupKey) {

    var data = importCtrl.newName.findIndex(ob => ob['oldName'] === importCtrl.item);
    if(txGroupKey =='PLANTS (FR-METRO)'){

      txGroupKey = 'PLANTS'
    }
    //permet de supprimer les doublons
    if (data != -1) {
      importCtrl.newName.splice(data, 1);

    }

    if (name != undefined) {
      importCtrl.newName.push({
        'txGroupKey': txGroupKey,
        'goodName': name,
        'goodKey' : key,
        'oldName': importCtrl.item
      });
    }
    importCtrl.consolidationInProgress = true;
	//validate button become actif only if all row are consolidated
    //if(importCtrl.newName.length==totalRowToConsolidate){
    //  importCtrl.consolidationInProgress = true;
    //}
  }

  function onNextResultsAction() {
    importCtrl.onNextResultsActionFn()();
  }

  function onConsolidationCheckValidate() {
    const allValidatedTaxon = <AllValidatedTaxon>({
      fileName: importCtrl.filenName,
      txRefName: importCtrl.txRefName,
      txRefVersion: importCtrl.txRefVersion,
      listValidatedTaxon: importCtrl.newName
    })
    importCtrl.onConsolidationValidateFn()(importCtrl.publicationId, allValidatedTaxon);
    onTaxCheckChanged();
  }

  function getMask() {
        ImportFileService.onGetMask(importCtrl.publicationId);
  }
}
