require('./home.scss');

export default HomeDirective;

// @ngInject
function HomeDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: HomeController,
    controllerAs: 'homeCtrl',
    template: require('./home.html'),
    replace: true
  };
}

// @ngInject
function HomeController($scope, $rootScope, $state, HeaderService) {
  var homeCtrl = this;

  init();

  function init() {

    $state.go('publication-search');
    /*HeaderService.updateTitle([
      'Accueil'
    ]);*/
  }

}
