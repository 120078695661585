import * as models from '@biotope/shuriken-status-api'
import { PublicationApi, ModerationApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';

export const EVENTS = {
  PUBLICATION_CREATION_PROCESSING: "SHU-PUBLICATION_CREATION_PROCESSING",
  PUBLICATION_CREATION_PROCESS_DONE: "SHU-PUBLICATION_CREATION_PROCESS_DONE",
  PUBLICATION_CREATION_PROCESS_ERROR: "SHU-PUBLICATION_CREATION_PROCESS_ERROR",

  PUBLICATION_SIMILARITY_PROCESSING: "SHU-PUBLICATION_SIMILARITY_PROCESSING",
  PUBLICATION_SIMILARITY_FOUND: "SHU-PUBLICATION_SIMILARITY_FOUND",
  PUBLICATION_SIMILARITY_ERROR: "SHU-PUBLICATION_SIMILARITY_ERROR",

  PUBLICATION_TS_SEARCH_PROCESSING: "SHU-PUBLICATION_TS_SEARCH_PROCESSING",
  PUBLICATION_TS_SEARCH_DONE: "SHU-PUBLICATION_TS_SEARCH_DONE",
  PUBLICATION_TS_SEARCH_ERROR: "SHU-PUBLICATION_TS_SEARCH_ERROR",

  PUBLICATION_DETAILS_PROCESSING: "SHU-PUBLICATION_DETAILS_PROCESSING",
  PUBLICATION_DETAILS_PROCESS_DONE: "SHU-PUBLICATION_DETAILS_PROCESS_DONE",
  PUBLICATION_DETAILS_PROCESS_ERROR: "SHU-PUBLICATION_DETAILS_PROCESS_ERROR",

  PUBLICATION_USER_LIST_PROCESSING: "SHU-PUBLICATION_USER_LIST_PROCESSING",
  PUBLICATION_USER_LIST_DONE: "SHU-PUBLICATION_USER_LIST_DONE",
  PUBLICATION_USER_LIST_ERROR: "SHU-PUBLICATION_USER_LIST_ERROR",

  PUBLICATION_MODERATOR_LIST_PROCESSING: "SHU-PUBLICATION_MODERATOR_LIST_PROCESSING",
  PUBLICATION_MODERATOR_LIST_DONE: "SHU-PUBLICATION_MODERATOR_LIST_DONE",
  PUBLICATION_MODERATOR_LIST_ERROR: "SHU-PUBLICATION_MODERATOR_LIST_ERROR",

  PUBLICATION_UPDATE_DONE: "SHU-PUBLICATION_UPDATE_DONE",

}


export class PublicationService {

    private publicationApi: PublicationApi;
    private moderationApi: ModerationApi;
    private toaster: any;
    private $rootScope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, PublicationApi: PublicationApi, ModerationApi: ModerationApi) {
      this.publicationApi = PublicationApi;
      this.moderationApi = ModerationApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
    }

    public createPublication(publicationForm: models.PublicationForm, formController) {
       this.$rootScope.$emit(EVENTS.PUBLICATION_CREATION_PROCESSING);

       this.publicationApi.createPublication(publicationForm)
       .then(
         (publication) => {
           formController.$setPristine();
           this.toaster.success('Demande d\'ajout de statuts effectuée avec succès');
           this.$rootScope.$emit(EVENTS.PUBLICATION_CREATION_PROCESS_DONE, publication);
         },
         (error: any) => {
           this.toaster.error('Une erreur est survenue à la création de la demande d\'ajout de statuts. ' + (error.data || ''));
           this.$rootScope.$emit(EVENTS.PUBLICATION_CREATION_PROCESS_ERROR);
         }
       );
    }

    /*public async searchPublicationsBySimilarity(citation: string) {
        this.$rootScope.$emit(EVENTS.PUBLICATION_TS_SEARCH_PROCESSING);

        try {
          const response = await this.publicationApi.searchPublicationsBySimilarity(citation);

          //this.$rootScope.$emit(EVENTS.PUBLICATION_TS_SEARCH_DONE, _.defaults({}, response.data));
          return response.data;
        }
        catch (error) {
          //console.log(error);
          this.toaster.error('xxxx' + (error.data || ''));
          this.$rootScope.$emit(EVENTS.PUBLICATION_TS_SEARCH_ERROR, error);
        }
    }*/

    public searchPublicationsBySimilarity(publication: models.Publication) {
        this.$rootScope.$emit(EVENTS.PUBLICATION_SIMILARITY_PROCESSING);

        return this.publicationApi.searchPublicationsBySimilarity(publication.citation, publication.id)
        .then(
            (response) => {
            this.$rootScope.$emit(EVENTS.PUBLICATION_SIMILARITY_FOUND, _.defaults({}, response.data));
          }, (error: any) => {
            //console.log(error);
            this.toaster.error('Une erreur est survenue lors de la recherche de similarité des publications. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.PUBLICATION_SIMILARITY_ERROR, error);
          });
    }

    public getUserPublicationsList() {
        this.$rootScope.$emit(EVENTS.PUBLICATION_USER_LIST_PROCESSING);

        return this.publicationApi.getUserPublicationsList()
        .then(
            (response) => {
            this.$rootScope.$emit(EVENTS.PUBLICATION_USER_LIST_DONE, _.defaults({}, response.data));
          }, (error: any) => {
            //console.log(error);
            this.toaster.error('Une erreur est survenue lors de la recherche des publications associées à l\'utilisateur.' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.PUBLICATION_USER_LIST_ERROR, error);
          });
    }

    public getModeratorPublicationsList() {
        this.$rootScope.$emit(EVENTS.PUBLICATION_MODERATOR_LIST_PROCESSING);

        return this.moderationApi.getModeratorPublicationsList()
        .then(
            (response) => {
            this.$rootScope.$emit(EVENTS.PUBLICATION_MODERATOR_LIST_DONE, _.defaults({}, response.data));
          }, (error: any) => {
            //console.log(error);
            this.toaster.error('Une erreur est survenue lors de la recherche des publications associées au modérateur.' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.PUBLICATION_MODERATOR_LIST_ERROR, error);
          });
    }

    public updatePublication(publicationId: number, publicationForm, formController) {
      this.$rootScope.$emit(EVENTS.PUBLICATION_DETAILS_PROCESSING);

      this.publicationApi.updatePublication(publicationId, publicationForm)
        .then(
          (publication) => {
            formController.$setPristine();
            this.toaster.success('Publication enregistrée');
            this.$rootScope.$emit(EVENTS.PUBLICATION_UPDATE_DONE, publication);
          },
          (error) => {
            this.toaster.error('Une erreur est survenue à la sauvegarde de la publication. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.PUBLICATION_DETAILS_PROCESS_ERROR);
          }
        );
    }
}
