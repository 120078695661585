import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/dictionary-ref.service';

export default DictionaryRefStore;

const EVENTS = {
  DICTIONARY_STATUS_VALUE_STATE_CHANGED: 'shu-dictionary-ref.dictionary-status-value-state-changed'
};

// @ngInject
function DictionaryRefStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STATUS_VALUES_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STATUS_VALUES_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STATUS_VALUES_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      statusValues: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.DICTIONARY_STATUS_VALUE_STATE_CHANGED);
  }

  function onLoadSuccess(event, statusValues) {
    state = Immutable({
      statusValues,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.DICTIONARY_STATUS_VALUE_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      statusValues: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.DICTIONARY_STATUS_VALUE_STATE_CHANGED);
  }

}

export { EVENTS };
