import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../stores/txgroup-list.store';

export default TxGroupKeyFilter;

// @ngInject
function TxGroupKeyFilter($rootScope, TxGroupListStore, TxGroupService) {

  var state = TxGroupListStore.getState();

  TxGroupService.getTxGroups();
  $rootScope.$on(STORE_EVENTS.TX_GROUP_LIST_RESULTS_STATE_CHANGED, () => { state = TxGroupListStore.getState(); });

  function filter(input) {

    if (!state.loaded || state.error) {
      return input;
    }

    return _.chain(state.results).find({key: input}).get('name').value() || input;
  }
  filter.$stateful = true;
  return filter;
}
