import angular from 'angular';

import studySelect from './components/study-select/study-select.component';
import studyListStore from './stores/study-list.store';
import studyService from './services/study.service';
import routerConfig from './routes';
import studyConfiguration from './configuration';

const moduleName = 'shu-study';

angular.module(moduleName, ['lumx'])
  .directive('shuStudySelect', studySelect)
  .factory('StudyService', studyService)
  .factory('StudyListStore', studyListStore)
  .constant('StudyConfiguration', studyConfiguration)
  .config(routerConfig);

export default { moduleName };
