import { EVENTS as AUTH_SERVICE_EVENTS } from '../../auth/services/auth.service';

const EVENTS = {
  PROFILE_RESET: 'shu-profile.profile-reset',
  PROFILE_LOADING: 'shu-profile.profile-loading',
  PROFILE_LOAD_SUCCESS: 'shu-profile.profile-load-success',
  PROFILE_LOAD_ERROR: 'shu-profile.profile-load-error'
};

export default ProfileService;

// @ngInject
function ProfileService($rootScope, AuthService, ProfileRepository, LxNotificationService) {
  var alreadyLoading = false;

  $rootScope.$on(AUTH_SERVICE_EVENTS.AUTH_LOG_OUT, reset);
  $rootScope.$on(AUTH_SERVICE_EVENTS.AUTH_SUCCESS, reload);

  return {load};

  function load() {
    if (alreadyLoading) {
      return;
    }

    alreadyLoading = true;

    $rootScope.$emit(EVENTS.PROFILE_LOADING);

    ProfileRepository.get()
      .then((data) => ProfileRepository.store(data))
      .then((data) => {
        $rootScope.$emit(EVENTS.PROFILE_LOAD_SUCCESS, data);
        alreadyLoading = false;
        //sendUser(data);
      }, (error) => {
        AuthService.forceLogOut();
        LxNotificationService.error('Une erreur est survenue au chargement du profil. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PROFILE_LOAD_ERROR);
        alreadyLoading = false;
      })
      ;

  }

  function reset() {
    $rootScope.$emit(EVENTS.PROFILE_RESET);

    return ProfileRepository.store(null);
  }

  function reload() {
    reset().then(() => load());
  }

  function sendUser(userInfo) {
    ProfileRepository.send(userInfo).then((data) => {
      $rootScope.$emit(EVENTS.PROFILE_LOAD_SUCCESS, data);

    }, (error) => {
      AuthService.forceLogOut();
      LxNotificationService.error('Une erreur est survenue à l\'envoit des infor user. ' + (error.data || ''));
      $rootScope.$emit(EVENTS.PROFILE_LOAD_ERROR);

    })
  }
}

export { EVENTS } ;
