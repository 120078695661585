import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/auth.service';

const EVENTS = {
  AUTH_STORE_STATE_CHANGED: 'shu-auth.auth-store.state-changed'
};
const LOCALSTORAGE_TOKEN = 'authStore.token';

const ANONYMOUS = Immutable({
  token: null,
  error: null
});
var state = retrieveState();

export default AuthStore;

// @ngInject
function AuthStore($rootScope) {
  $rootScope.$on(SERVICE_EVENTS.AUTH_SUCCESS, (event, newState) => {
    state = Immutable(newState);
    storeToken(state.token);

    $rootScope.$broadcast(EVENTS.AUTH_STORE_STATE_CHANGED);
  });
  $rootScope.$on(SERVICE_EVENTS.AUTH_FAILURE, () => {
    state = Immutable({
      token: null,
      error: true
    });
    storeToken(null);

    $rootScope.$broadcast(EVENTS.AUTH_STORE_STATE_CHANGED);
  });
  $rootScope.$on(SERVICE_EVENTS.AUTH_LOST, () => {
    state = Immutable({
      token: null,
      error: true
    });
    storeToken(null);
  });

  $rootScope.$on(SERVICE_EVENTS.AUTH_LOG_OUT, (event, newState) => {
    state = ANONYMOUS;
    storeToken(null);

    $rootScope.$broadcast(EVENTS.AUTH_STORE_STATE_CHANGED);
  });

  return {isAuthenticated, hasError, getState};

  function isAuthenticated() {
    return !!state.token;
  }

  function hasError() {
    return !!state.error;
  }

  function getState() {
    return state;
  }
}

export { EVENTS };

function retrieveState() {
  var token = localStorage && localStorage.getItem(LOCALSTORAGE_TOKEN);
  if (!token) {
    return ANONYMOUS;
  }
  return {token};
}

function storeToken(token) {
  if (!token) {
    localStorage && localStorage.removeItem(LOCALSTORAGE_TOKEN);
    return;
  }
  localStorage && localStorage.setItem(LOCALSTORAGE_TOKEN, token);
}
