import _ from 'lodash';

import { EVENTS as TERR_UNIT_LIST_STORE_EVENTS } from '../../stores/terr-unit-list.store';

import { TerrUnitService } from '../../services/terr-unit.service';

require('./terr-unit-select.scss');

export default TerrUnitSelectDirective;

// @ngInject
function TerrUnitSelectDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueIdProvider: '&initialValueId',
      placeholder: '@',
      required: '@',
      multipleProvider: '&multiple',
      name: "@",
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit'
    },
    bindToController: true,
    controller: TerrUnitSelectController,
    controllerAs: 'terrUnitSelectCtrl',
    template: require('./terr-unit-select.html'),
    replace: true
  };
}

// @ngInject
function TerrUnitSelectController($scope, TerrUnitService: TerrUnitService, TerrUnitListStore) {
  var terrUnitSelectCtrl = this;

  terrUnitSelectCtrl.currentValue = null;
  terrUnitSelectCtrl.deletedValue = null;
  terrUnitSelectCtrl.availableValues = [];
  terrUnitSelectCtrl.state = {};

  terrUnitSelectCtrl.onTerrUnitUpdated = onTerrUnitUpdated;
  terrUnitSelectCtrl.onChange = onChange;

  $scope.$on(TERR_UNIT_LIST_STORE_EVENTS.TERR_UNIT_LIST_STATE_CHANGED, reloadResults);

  TerrUnitService.resetTerrUnit();

  $scope.$watch(terrUnitSelectCtrl.canEditProvider, (canEdit) => {
    terrUnitSelectCtrl.canEdit = canEdit;
  });

  $scope.$watch(terrUnitSelectCtrl.multipleProvider, (multiple) => {
    terrUnitSelectCtrl.multiple = multiple;
  });

  var removeWatcher = $scope.$watch(terrUnitSelectCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId) {
      return;
    }

    terrUnitSelectCtrl.initialValueId = initialValueId;
    //TerrUnitService.getTerrUnitByKey(initialValueId);

    updateInitialValue();

    removeWatcher();
  });

  function onTerrUnitUpdated(newValue) {
    TerrUnitService.onUpdateTerrUnitForm(newValue);
  }

  function reloadResults() {
    terrUnitSelectCtrl.state = TerrUnitListStore.getState();

    if (!terrUnitSelectCtrl.state.results) {
      return;
    }

    terrUnitSelectCtrl.availableValues = [];

    _.forEach(_.reject(terrUnitSelectCtrl.state.results, 'deleted'), function(x) {
      terrUnitSelectCtrl.availableValues.push(x.asMutable());
    })

    updateInitialValue();
  }

  function updateInitialValue() {

    if (!terrUnitSelectCtrl.initialValueId || !terrUnitSelectCtrl.state.loaded) {
      return;
    }

    var initialValue = _.find(terrUnitSelectCtrl.state.results, {key: terrUnitSelectCtrl.initialValueId});
    if (!initialValue) {
      return;
    }

    //onChange(initialValue.name);

    //terrUnitSelectCtrl.onChangeFn() && terrUnitSelectCtrl.onChangeFn()(initialValue.name);
    if (initialValue.deleted) {
      terrUnitSelectCtrl.deletedValue = initialValue;
      return;
    }

    terrUnitSelectCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {

    if (!newValue) {
      return;
    }
    terrUnitSelectCtrl.onChangeFn() && terrUnitSelectCtrl.onChangeFn()(newValue);

    TerrUnitService.onUpdateTerrUnitForm(newValue);
  }


}
