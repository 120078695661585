import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/import-file.service';

const EVENTS = {
  IMPORT_FILE_STATE_CHANGED: 'shu-import.import-file-state-changed',
  TAXON_SEARCH_QUERY_STATE_CHANGED: 'shu-import.taxon-search-query-state-changed',
  TAXON_SEARCH_RESULTS_STATE_CHANGED: 'shu-import.taxon-search-results-state-changed',
  TAXON_CHECK_STATE_CHANGED: 'shu-import.taxon-check-state-changed'
};

export default ImportFileStore;

// @ngInject
function ImportFileStore($rootScope) {
  var state = Immutable({});

  var queryState = Immutable({});
  var resultsState = Immutable({});
  var taxonCheckState = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.IMPORT_FILE_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.IMPORT_FILE_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.IMPORT_FILE_LOAD_ERROR, onLoadError);
  $rootScope.$on(SERVICE_EVENTS.IMPORT_FILE_PROCESSING, onProcessing);
  $rootScope.$on(SERVICE_EVENTS.IMPORT_FILE_PROCESS_ERROR, onProcessError);

  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_RESULTS_LOADING, onTaxonSearchResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_RESULTS_LOAD_SUCCESS, onTaxonSearchResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_RESULTS_LOAD_ERROR, onTaxonSearchResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOADING, onTaxonSearchNextResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, onTaxonSearchNextResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_ERROR, onTaxonSearchNextResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_QUERY_UPDATE, onUpdateTaxonSearchQuery);

  $rootScope.$on(SERVICE_EVENTS.TAXON_CHECK_SUCCESS, onTaxonCheckSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXON_CHECK_PROCESSING, onTaxonCheckProgress);
  $rootScope.$on(SERVICE_EVENTS.TAXON_CHECK_ERROR, onTaxonCheckError);
  return { getState, getQueryState, getResultsState, getTaxonCheckState };

  function getQueryState() {
    return queryState;
  }

  function getResultsState() {
    return resultsState;
  }

  function getState() {
    return state;
  }

  function getTaxonCheckState() {
    return taxonCheckState;
  }

  function onUpdateTaxonSearchQuery(events, {query, selectedReferentiel}) {

    queryState = Immutable({query, selectedReferentiel});
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_QUERY_STATE_CHANGED);
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchResultsLoading() {
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchResultsLoadSuccess(event, results, totalResults, {query, selectedReferentiel}) {
    if (queryState.query !== query || queryState.selectedReferentiel !== selectedReferentiel) {
      return;
    }
    resultsState = Immutable({
      results,
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchResultsLoadError({query, selectedReferentiel}) {
    if (queryState.query !== query || queryState.selectedReferentiel !== selectedReferentiel) {
      return;
    }
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchNextResultsLoading() {
    resultsState = Immutable({
      results: resultsState.results,
      totalResults: resultsState.totalResults,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchNextResultsLoadSuccess(event, results, totalResults, {query, referentiel}) {
    if (queryState.query !== query || queryState.selectedReferentiel !== referentiel) {
      return;
    }
    resultsState = Immutable({
      results: [].concat.call(resultsState.results, results),
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchNextResultsLoadError({query, referentiel}) {
    if (queryState.query !== query || queryState.selectedReferentiel !== referentiel) {
      return;
    }
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onLoading() {
    state = Immutable({
      files: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.IMPORT_FILE_STATE_CHANGED);
  }

  function onLoadSuccess(event, files) {
    state = Immutable({
      files,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.IMPORT_FILE_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      files: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.IMPORT_FILE_STATE_CHANGED);
  }

  function onProcessing() {
    state = Immutable({
      files: state.layers,
      processing: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.IMPORT_FILE_STATE_CHANGED);
  }

  function onProcessError() {
    state = Immutable({
      files: state.files,
      processing: false,
      loaded: true,
      error: true
    });
    $rootScope.$broadcast(EVENTS.IMPORT_FILE_STATE_CHANGED);
  }


  function onTaxonCheckProgress() {
    taxonCheckState = Immutable({
      data: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_CHECK_STATE_CHANGED);
  }

  function onTaxonCheckSuccess(event, data) {
    taxonCheckState = Immutable({
      data,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_CHECK_STATE_CHANGED);
  }

  function onTaxonCheckError() {
    taxonCheckState = Immutable({
      data: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXON_CHECK_STATE_CHANGED);
  }

}

export { EVENTS };
