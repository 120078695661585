import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/profile.service';

const EVENTS = {
  PROFILE_STATE_CHANGED: 'shu-profile.profile-state-changed'
};

export default ProfileStore;

// @ngInject
function ProfileStore($rootScope) {
  var state = Immutable({profile: null, loaded: false, loading: false, error: false});

  $rootScope.$on(SERVICE_EVENTS.PROFILE_RESET, reset);
  $rootScope.$on(SERVICE_EVENTS.PROFILE_LOADING, loading);
  $rootScope.$on(SERVICE_EVENTS.PROFILE_LOAD_SUCCESS, loadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PROFILE_LOAD_ERROR, loadError);

  return {get, loadSuccess};

  function get() {
    return state;
  }

  function reset() {
    setState({profile: null, loaded: false, loading: false, error: false});
  }

  function loading() {
    setState({profile: null, loaded: false, loading: true, error: false});
  }

  function loadSuccess(event, profile) {
    setState({profile: profile, loaded: true, loading: false, error: false});
  }

  function loadError() {
    setState({profile: null, loaded: true, loading: false, error: true});
  }

  function setState(newState) {
    state = Immutable(newState);
    $rootScope.$broadcast(EVENTS.PROFILE_STATE_CHANGED);


  }
}

export { EVENTS } ;
