const TX_GROUP_LEVEL = [
  {key: 'PLANTS', level: 1},
  {key: 'FUNGI', level: 3},
  {key: 'MOLLUSCS', level: 4},
  {key: 'ARACHNIDS', level: 5},
  {key: 'INSECTS_ODO', level: 6},
  {key: 'INSECTS_ORTHO', level: 7},
  {key: 'INSECTS_RHO', level: 8},
  {key: 'INSECTS_OTHER', level: 9},
  {key: 'CRUSTACEANS', level: 10},
  {key: 'FISHES', level: 11},
  {key: 'AMPHIBIA', level: 12},
  {key: 'REPTILES', level: 13},
  {key: 'BIRDS', level: 14},
  {key: 'MAMMALS', level: 15},
  {key: 'BATS', level: 16},
  {key: 'OTHERS', level: 17},
];

export { TX_GROUP_LEVEL };
