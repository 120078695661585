import _ from 'lodash';

import { SectionFlowService } from '../../services/section-flow.service';

import { EVENTS as SECTION_STATE_LIST_STORE_EVENTS } from '../../stores/section-state-list.store';

require('./section-state-select.scss');

export default SectionStateSelectDirective;

// @ngInject
function SectionStateSelectDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueIdProvider: '&initialValueId',
      placeholder: '@',
      required: '@',
      multipleProvider: '&multiple',
      name: '@',
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit',
      sectionIdProvider: '&sectionId'
    },
    bindToController: true,
    controller: SectionStateSelectController,
    controllerAs: 'sectionStateSelectCtrl',
    template: require('./section-state-select.html'),
    replace: true
  };
}

// @ngInject
function SectionStateSelectController($scope, SectionFlowService: SectionFlowService, SectionStateListStore) {
  var sectionStateSelectCtrl = this;

  sectionStateSelectCtrl.onChange = onChange;
  sectionStateSelectCtrl.currentValue = null;
  sectionStateSelectCtrl.deletedValue = null;

  $scope.$on(SECTION_STATE_LIST_STORE_EVENTS.SECTION_STATE_LIST_STATE_CHANGED, reloadResults);
  sectionStateSelectCtrl.availableValues = [];
  sectionStateSelectCtrl.state = {};

  $scope.$watch(sectionStateSelectCtrl.sectionIdProvider, (sectionId) => {
    SectionFlowService.getSectionStateList(sectionId);
  });

  $scope.$watch(sectionStateSelectCtrl.canEditProvider, (canEdit) => {
    sectionStateSelectCtrl.canEdit = canEdit;
  });

  $scope.$watch(sectionStateSelectCtrl.multipleProvider, (multiple) => {
    sectionStateSelectCtrl.multiple = multiple;
  });

  var removeWatcher = $scope.$watch(sectionStateSelectCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId) {
      return;
    }

    sectionStateSelectCtrl.initialValueId = initialValueId;
    updateInitialValue();

    removeWatcher();
  });

  function reloadResults() {
    sectionStateSelectCtrl.state = SectionStateListStore.getState();

    if (!sectionStateSelectCtrl.state.sectionStates) {
      return;
    }

    sectionStateSelectCtrl.availableValues = sectionStateSelectCtrl.state.sectionStates;

    updateInitialValue();
  }

  function updateInitialValue() {
    if (!sectionStateSelectCtrl.initialValueId || !sectionStateSelectCtrl.state.loaded) {
      return;
    }

    var initialValue = sectionStateSelectCtrl.initialValueId;
    if (!initialValue) {
      return;
    }

    onChange(initialValue);

    sectionStateSelectCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {
    if (!newValue) {
      return;
    }
    sectionStateSelectCtrl.onChangeFn() && sectionStateSelectCtrl.onChangeFn()(newValue);
  }
}
