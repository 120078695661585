import _ from 'lodash';

require('./number-input.scss');

export default NumberInputDirective;

// @ngInject
function NumberInputDirective() {
  return {
    restrict: 'E',
    scope: {
      model: '=',
      name: '@',
      step: '@',
      incStr1: '@inc1',
      incStr2: '@inc2',
      incStr3: '@inc3',
      formProvider: '&form',
      placeholder: '@',
      fixedLabelProvider: '&fixedLabel',
      requiredProvider: '&required',
      onChangeFn: '&onChange',
      without3Provider: '&without3',
      without2Provider: '&without2',
      disabledProvider: '&disabled'
    },
    bindToController: true,
    controller: NumberInputController,
    controllerAs: 'numberInputCtrl',
    template: require('./number-input.html'),
    replace: true
  };
}

// @ngInject
function NumberInputController($scope) {
  var numberInputCtrl = this;

  numberInputCtrl.onChange = onChange;
  numberInputCtrl.clearValue = clearValue;
  numberInputCtrl.inc = inc;
  numberInputCtrl.currentValue = null;

  numberInputCtrl.inc1 = numberInputCtrl.incStr1 ? parseFloat(numberInputCtrl.incStr1) : 1;
  numberInputCtrl.inc2 = numberInputCtrl.incStr2 ? parseFloat(numberInputCtrl.incStr2) : 10;
  numberInputCtrl.inc3 = numberInputCtrl.incStr3 ? parseFloat(numberInputCtrl.incStr3) : 100;

  if (!numberInputCtrl.step || numberInputCtrl.step === '1') {
    numberInputCtrl.type = 'number';
  } else {
    numberInputCtrl.type = 'tel';
    numberInputCtrl.pattern = '[0-9]+([\.][0-9]+)?';
  }

  $scope.$watch(numberInputCtrl.disabledProvider, (disabled) => numberInputCtrl.disabled = disabled);
  $scope.$watch(numberInputCtrl.without3Provider, (without3) => numberInputCtrl.without3 = without3);
  $scope.$watch(numberInputCtrl.without2Provider, (without2) => numberInputCtrl.without2 = without2);
  $scope.$watch(numberInputCtrl.requiredProvider, (required) => numberInputCtrl.required = required);
  $scope.$watch(numberInputCtrl.fixedLabelProvider, (fixedLabel) => numberInputCtrl.fixedLabel = fixedLabel);
  $scope.$watch(numberInputCtrl.formProvider, (form) => numberInputCtrl.form = form);

  function onChange() {
    // numberInputCtrl.model a été mis à jour avant l'appeler onChange
    numberInputCtrl.onChangeFn() && numberInputCtrl.onChangeFn()(numberInputCtrl.model);
  }

  function clearValue() {
    numberInputCtrl.model = null;
    onChange();

    numberInputCtrl.form[numberInputCtrl.name].$setDirty();
  }

  function inc(delta) {
    if (!isInteger(delta)) {
      numberInputCtrl.model = numberInputCtrl.model ? _.round((numberInputCtrl.model + delta), 2) : _.round(delta, 2);
    } else {
      numberInputCtrl.model = numberInputCtrl.model ? numberInputCtrl.model + delta : delta;
    }

    onChange();
    numberInputCtrl.form[numberInputCtrl.name].$setDirty();
  }
  function isInteger(value) {
    return typeof value === 'number' &&
           isFinite(value) &&
           Math.floor(value) === value;
  }
}
