export default ExpertRepository;

// @ngInject
function ExpertRepository(ExpertConfiguration, $http) {
  return {query};

  function query(query, offset, limit) {
    return $http({
      method: 'GET',
      url: ExpertConfiguration.searchEndpoint,
      params: {query, offset, limit}
    }).then((response) => response.data);
  }
}
