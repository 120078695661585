import { EVENTS as PUBLICATION_STORE_EVENTS } from '../../../publication/stores/publication-details.store';
import { EVENTS as SECTION_STORE_EVENTS } from '../../../section/stores/section-details.store';

import * as models from '@biotope/shuriken-status-api'

require('../../../section/components/section-new-container/section-new-container.scss');

export default SectionNewContainerDirective;

/** @ngInject */
function SectionNewContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      publicationId: '@'
    },
    bindToController: true,
    controller: SectionNewContainerController,
    controllerAs: 'sectionNewContainerCtrl',
    template: require('../../../section/components/section-new-container/section-new-container.html'),
    replace: true
  };
}

/** @ngInject */
function SectionNewContainerController($scope, $state, HeaderService, PublicationDetailsService, PublicationDetailsStore, SectionService, SectionDetailsStore) {
  var sectionNewContainerCtrl = this;

  sectionNewContainerCtrl.publicationState = {};
  sectionNewContainerCtrl.sectionState = {};
  sectionNewContainerCtrl.headerUpdated = false;

  sectionNewContainerCtrl.isLoaded = isLoaded;
  sectionNewContainerCtrl.isProcessing = isProcessing;
  sectionNewContainerCtrl.onSectionChanged = onSectionChanged;
  sectionNewContainerCtrl.onCreate = onCreate;

  $scope.$on(PUBLICATION_STORE_EVENTS.PUBLICATION_DETAILS_STATE_CHANGED, reloadPublicationDetails);

  $scope.$on(SECTION_STORE_EVENTS.SECTION_DETAILS_STATE_CHANGED, reloadSectionNew);

  $scope.$on(SECTION_STORE_EVENTS.SECTION_CREATED, redirectToPublicationMainPage);

  loadPublication();

  function isLoaded() {
    return sectionNewContainerCtrl.publicationState && sectionNewContainerCtrl.publicationState.loaded;
  }

  function isProcessing() {
    return sectionNewContainerCtrl.publicationState && sectionNewContainerCtrl.publicationState.processing;
  }

  function reloadSectionNew() {
    sectionNewContainerCtrl.sectionState = SectionDetailsStore.getState();
    if (sectionNewContainerCtrl.sectionState.error) {
      return;
    }
    if (sectionNewContainerCtrl.sectionState.isLoaded && sectionNewContainerCtrl.sectionState.section) {
      $state.go('section-details',
        {
          publicationId: sectionNewContainerCtrl.publicationState.publication.id,
          sectionId: sectionNewContainerCtrl.sectionState.section.id
        });
    }
  }

  function reloadPublicationDetails() {
    sectionNewContainerCtrl.publicationState = PublicationDetailsStore.getState();

    if (sectionNewContainerCtrl.publicationState.error) {
      return;
    }
    updateHeader();
  }

  function redirectToPublicationMainPage() {
    sectionNewContainerCtrl.publicationState = PublicationDetailsStore.getState();

    $state.go('publication-overview',
      {
        publicationId: sectionNewContainerCtrl.publicationState.publication.publication.id
      });
  }

  function updateHeader() {
    if (!sectionNewContainerCtrl.publicationState.publication || sectionNewContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: sectionNewContainerCtrl.publicationState.publication.publication.label,
        link: {
          sref: 'publication-overview',
          params: {publicationId: sectionNewContainerCtrl.publicationId}
        }
      },
      'Nouveau Section'
    ]);
    sectionNewContainerCtrl.headerUpdated = true;
  }

  function loadPublication() {
    PublicationDetailsService.load(sectionNewContainerCtrl.publicationId);
  }

  function onSectionChanged(formData) {
    sectionNewContainerCtrl.formData = formData;
  }

  function onCreate(formData: models.Section, form) {

    formData.publication = sectionNewContainerCtrl.publicationId;

    SectionService.createSection(
      formData,
      form
    );
  }
}
