import _ from 'lodash';

export default ProfileRepository;

// @ngInject
function ProfileRepository(ProfileConfiguration, $http, DatabaseProvider, StorageService, $q) {
  return {get, store};

  function get() {
    return getLocally()
      .then((expert) => {
        if (!expert) {
          return getRemotely();
        }
        return expert;
      });
  }

  function getLocally() {
    return DatabaseProvider.getDatabase()
      .then((database) => {
        return StorageService.executeSqlQuery(database, 'SELECT * FROM expert LIMIT 1');
      })
      .then((experts) => {
        var expert = _.clone(_.head(experts));
        if (!expert) {
          return null;
        }

        expert.isAdmin = !!expert.isAdmin;
        expert.isActive = !!expert.isActive;
        return expert;
      });
  }

  function getRemotely() {
    return $http({
      method: 'GET',
      url: ProfileConfiguration.userinfoEndpoint
    }).then((response) => response.data);
  }

  function store(expert) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase()
        .then((database) => {
          return StorageService.executeSqlQuery(database, 'DELETE FROM expert')
            .then(() => {
              if (!expert) {
                return $q.when();
              }
              return StorageService.executeSqlQuery(
                database,
                'INSERT INTO expert VALUES (?, ?, ?, ?, ?, ?)',
                [expert.id, expert.displayName, expert.login, expert.email, expert.isAdmin ? 1 : 0, expert.isActive ? 1 : 0]
              );
            });
        })
        .then(() => resolve(expert), () => reject());
    });
  }
}
