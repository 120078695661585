export default ProfileRepository;

// @ngInject
function ProfileRepository(ProfileConfiguration, $http, $q) {
  return {get, store, send};


  function get() {
    return $http({
      method: 'GET',
      url: ProfileConfiguration.userinfoEndpoint
    }).then((response) => response.data);
  }

  function send(userInfo) {
    return $http({
      method: 'POST',
      url: ProfileConfiguration.userInfoSendEndpoint,
      headers: { 'Content-Type': 'application/json'},
      data: userInfo
    }).then((response) => response.data);
  }

  function store(expert) {
    return $q.when(expert);

  }
}
