import { EVENTS as ASIDE_EVENTS } from '../aside/aside.component';
import { EVENTS as HEADER_STORE_EVENTS } from '../../stores/header.store';
import { EVENTS as PROFILE_STORE_EVENTS } from '../../../profile/stores/profile.store';

require('./header.scss');

export default HeaderDirective;

// @ngInject
function HeaderDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: HeaderController,
    controllerAs: 'headerCtrl',
    template: require('./header.html'),
    replace: true
  };
}

// @ngInject
function HeaderController($rootScope, $scope, HeaderStore, AuthStore, ProfileStore, ProfileService, $state, $timeout) {

  var headerCtrl = this;

  //field - survey - current - state - changed;
  headerCtrl.headerState = HeaderStore.getState();
  headerCtrl.profileState = ProfileStore.get();

  if (AuthStore.isAuthenticated()) {
    ProfileService.load();
  }

  headerCtrl.getHref = getHref;
  headerCtrl.showAside = showAside;

  headerCtrl.isCordovaUser = isCordovaUser;

  headerCtrl.logoUrl = require('./logo-biotope.png');

  $scope.$on(HEADER_STORE_EVENTS.HEADER_STATE_CHANGED, onTitleStateChanged);
  $scope.$on(PROFILE_STORE_EVENTS.PROFILE_STATE_CHANGED, onProfileStateChanged);

  function getHref(link) {
    return $state.href(link.sref, link.params);
  }

  function showAside() {
    $rootScope.$broadcast(ASIDE_EVENTS.ASIDE_SHOW);
  }

  function onTitleStateChanged() {
    headerCtrl.headerState = HeaderStore.getState();
  }

  function onProfileStateChanged() {
    headerCtrl.profileState = ProfileStore.get();
  }

  function isCordovaUser() {
    return IS_CORDOVA;
  }
}
