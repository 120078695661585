
require('./consolidation-file-upload.scss');

export default ConsolidationFileUploadDirective;

// @ngInject
function ConsolidationFileUploadDirective() {
  return {
    restrict: 'E',
    scope: {
      onFileUploadFn: '&onFileUpload'
    },
    bindToController: true,
    controller: ConsolidationFileUploadController,
    controllerAs: 'consolidationFileUploadCtrl',
    template: require('./consolidation-file-upload.html'),
    replace: true
  };
}

// @ngInject
function ConsolidationFileUploadController($scope, $timeout) {
  var consolidationFileUploadCtrl = this;

  consolidationFileUploadCtrl.fileInfo = {};

  consolidationFileUploadCtrl.onSelectFileServer = onSelectFileServer;

  function onSelectFileServer() {
    onSelectFile(consolidationFileUploadCtrl.selectedFile, null, 'csv');
  }

  function onSelectFile(file, path, format) {
    var lastDotIndex = file.name.lastIndexOf('.');
    if (lastDotIndex === -1) {
      consolidationFileUploadCtrl.fileNameFromFile = file.name;
    } else {
      consolidationFileUploadCtrl.fileNameFromFile = file.name.substring(0, lastDotIndex);
    }
    consolidationFileUploadCtrl.fileInfo = {
      file,
      path,
      format
    };

    $timeout(function() {
      consolidationFileUploadCtrl.onFileUploadFn()(consolidationFileUploadCtrl.fileInfo);
    }, 1000);

  }

}
