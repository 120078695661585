import * as models from '@biotope/shuriken-ng-referentiel-api'
import { TxGroupApi } from '@biotope/shuriken-ng-referentiel-api'
import _ from 'lodash';

export const EVENTS = {
  // Actions lancées quand l'utilisateur charge les txgroupes
  TX_GROUP_LIST_RESULTS_LOADING: 'shu-txgroup.txgroup-list-results-loading',
  TX_GROUP_LIST_RESULTS_LOAD_SUCCESS: 'shu-txgroup.txgroup-list-results-load-success',
  TX_GROUP_LIST_RESULTS_LOAD_ERROR: 'shu-txgroup.txgroup-list-results-load-error',
}

export class TxGroupService {

    private txGroupApi: TxGroupApi;
    private toaster: any;
    private $rootScope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, TxGroupApi: TxGroupApi) {
      this.txGroupApi = TxGroupApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
    }

    public getTxGroups() {

      this.$rootScope.$emit(EVENTS.TX_GROUP_LIST_RESULTS_LOADING);

      return this.txGroupApi.getTxGroups()
      .then(
          (response) => {

          _.remove(response.data, function (txgroup: models.TxGroup) {
            return txgroup.id === 62;
          });

          let index = _.findIndex(response.data, function(o) { return o.id === 61; });
          var obj = _.find(response.data, {'key': 'PLANTS (FR-METRO)'});
          obj.key = 'PLANTS'

          response.data.splice(index, 1, obj)
          this.$rootScope.$emit(EVENTS.TX_GROUP_LIST_RESULTS_LOAD_SUCCESS, response.data);

        }, (error: any) => {
          //console.log(error);
          this.toaster.error('Une erreur est survenue au chargement des groupes taxonomiques. ' + (error.data || ''));
          this.$rootScope.$emit(EVENTS.TX_GROUP_LIST_RESULTS_LOAD_ERROR, error);
        });
    }
}
