import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/section.service';

export default SectionServiceStore;

const EVENTS = {
  SECTION_UPDATED: 'SHU-SECTION_UPDATED',
  SECTION_DELETED: 'SHU-SECTION_DELETED',
  SECTION_STATUTES_STATE_CHANGED: 'SHU-SECTION_STATUTES_STATE_CHANGED',
};

// @ngInject
function SectionServiceStore($rootScope) {
  var state = Immutable({});
  var statutesState = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.SECTION_UPDATE_DONE, onUpdateSuccess);
  $rootScope.$on(SERVICE_EVENTS.SECTION_CONSOLIDATE_PROCESS_DONE, onUpdateSuccess);
  $rootScope.$on(SERVICE_EVENTS.SECTION_DELETION_DONE, onDeleteSuccess);

  $rootScope.$on(SERVICE_EVENTS.SECTION_STATUTES_LOADING, onSectionStatutesLoading);
  $rootScope.$on(SERVICE_EVENTS.SECTION_STATUTES_LOAD_SUCCESS, onSectionStatutesLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.SECTION_STATUTES_LOAD_ERROR, onSectionStatutesLoadError);

  return {getState, getStatutesStates};

  function getState() {
    return state;
  }

  function getStatutesStates() {
    return statutesState;
  }

  function onUpdateSuccess(event, section) {
    state = Immutable({
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SECTION_UPDATED);
  }

  function onDeleteSuccess(event) {
    state = Immutable({
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SECTION_DELETED);
  }

  function onSectionStatutesLoading() {
    statutesState = Immutable({
      statutes: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SECTION_STATUTES_STATE_CHANGED);
  }

  function onSectionStatutesLoadSuccess(event, statutes) {
    statutesState = Immutable({
      statutes,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SECTION_STATUTES_STATE_CHANGED);
  }

  function onSectionStatutesLoadError() {
    statutesState = Immutable({
      statutes: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.SECTION_STATUTES_STATE_CHANGED);
  }
}

export { EVENTS };
