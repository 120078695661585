import _ from 'lodash';

require('./publication-search-form.scss');

export default PublicationSearchFormDirective;

// @ngInject
function PublicationSearchFormDirective() {
  return {
    restrict: 'E',
    scope: {
      queryStateProvider: '&queryState',
      onUpdateFormFn: '&onUpdateForm'
    },
    bindToController: true,
    controller: PublicationSearchFormController,
    controllerAs: 'publicationSearchFormCtrl',
    template: require('./publication-search-form.html'),
    replace: true
  };
}


// @ngInject
function PublicationSearchFormController($scope) {
  var publicationSearchFormCtrl = this;

  publicationSearchFormCtrl.onQueryUpdated = onQueryUpdated;

  $scope.$watch(publicationSearchFormCtrl.queryStateProvider, (queryState) => {

    publicationSearchFormCtrl.query = queryState.query;
    publicationSearchFormCtrl.txGroups = queryState.txGroups;
    publicationSearchFormCtrl.types = queryState.types;
    publicationSearchFormCtrl.terrUnits = queryState.terrUnits;

  });

  function onQueryUpdated() {

    publicationSearchFormCtrl.onUpdateFormFn()(publicationSearchFormCtrl.query, publicationSearchFormCtrl.txGroups, publicationSearchFormCtrl.types, publicationSearchFormCtrl.terrUnits);
  }
}
