import _ from 'lodash';

import { EVENTS as SERVICE_EVENTS } from '../services/terr-unit.service';

export default TerrUnitKeyFilter;

// @ngInject
function TerrUnitKeyFilter($rootScope, $async, TerrUnitListStore, TerrUnitService) {

  var terrUnitSelectCtrl = this;
  terrUnitSelectCtrl.terrUnit = new Map();
  terrUnitSelectCtrl.inputs = [];
  terrUnitSelectCtrl.input = null;

  const getFilterValue = $async(function*(input) {
      let response = yield TerrUnitService.getTerrUnitByKey(input);
      terrUnitSelectCtrl.terrUnit.set(input, response.name);
  });

  function filter(input) {

    if(input  === undefined)
      return input;

    if(_.indexOf(terrUnitSelectCtrl.inputs, input) == -1) {
      getFilterValue(input);
      terrUnitSelectCtrl.inputs.push(input);
    }

    let filteredValue = terrUnitSelectCtrl.terrUnit.get(input) === undefined ? input :  terrUnitSelectCtrl.terrUnit.get(input);

    //console.log(input +" "+filteredValue);
    return filteredValue
  }

  filter.$stateful = true;
  return filter;
}
