/**
 * API Gestion des statuts
 * API du module de gestion des statuts
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as models from '@biotope/shuriken-status-api'

/* tslint:disable:no-unused-variable member-ordering */

export class ConsolidationApi {
    protected basePath = 'https://shuriken-status.biotope.fr/status';
    public defaultHeaders : any = {};

    static $inject: string[] = ['$http', '$httpParamSerializer', 'basePath'];

    constructor(protected $http: ng.IHttpService, protected $httpParamSerializer?: (d: any) => any, basePath?: string) {
        if (basePath !== undefined) {
            this.basePath = basePath;
        }
    }

  /**
     * import a file of taxon to be consolidated
     * @summary import a file of taxon to be consolidated
     * @param referentiel
     * @param file
     */
    public uploadFileToConsolidation (referentiel?: models.Taxa, file?: any, extraHttpRequestParams?: any ) : ng.IHttpPromise<Array<models.Taxa>> {
        const localVarPath = this.basePath + '/ref/consolidation';

        let queryParameters: any = {};
        let headerParams: any = (<any>Object).assign({}, this.defaultHeaders);
        let formParams: any = {};

        //headerParams['Content-Type'] = 'application/x-www-form-urlencoded';
        headerParams['Content-Type'] = undefined;
        //headerParams['Content-Type'] = 'multipart/form-data';

        formParams['referentiel'] = referentiel;

        formParams['file'] = file;

        let httpRequestParams: ng.IRequestConfig = {
            method: 'POST',
            url: localVarPath,
            //data: this.$httpParamSerializer(formParams),
            data: this.toFormData(file, referentiel),
            params: queryParameters,
            headers: headerParams
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        return this.$http(httpRequestParams);
    }

    public toFormData(file, refid) {
      var formData = new FormData();
      formData.append('referentiel', JSON.stringify(refid));
      formData.append('file', file);
      return formData;
    }
}
