import * as models from '@biotope/shuriken-status-api'
import { FlowApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';

export const EVENTS = {
  // Actions lancées quand l'utilisateur charge les statusType
  SECTION_STATE_LIST_LOADING: 'SHU-SECTION_STATE_LIST',
  SECTION_STATE_LIST_LOAD_SUCCESS: 'SHU-SECTION_STATE_LIST_LOAD_SUCCESS',
  SECTION_STATE_LIST_LOAD_ERROR: 'SHU-SECTION_STATE_LIST_LOAD_ERROR',
}

export class SectionFlowService {

    private api: FlowApi;
    private toaster: any;
    private $rootScope: any;
    //private $scope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, FlowApi: FlowApi) {
      this.api = FlowApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
      //this.$scope = $scope;
    }

    public getSectionStateList(sectionId: number) {

      this.$rootScope.$emit(EVENTS.SECTION_STATE_LIST_LOADING);

      return this.api.getSectionAvailableStatus(sectionId)
      .then(
          (response) => {
          this.$rootScope.$emit(EVENTS.SECTION_STATE_LIST_LOAD_SUCCESS, response.data);
          return response.data;
        }, (error: any) => {
          //console.log(error);
          this.toaster.error('Une erreur est survenue lors du chargement de la liste des états. ' + (error.data || ''));
          this.$rootScope.$emit(EVENTS.SECTION_STATE_LIST_LOAD_ERROR, error);
        });
    }
}
