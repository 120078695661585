
require('./dictionary-status-value-container.scss');

export default DictionaryStatusValueContainerDirective;

// @ngInject
function DictionaryStatusValueContainerDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    bindToController: true,
    controller: DictionaryStatusValueContainerController,
    controllerAs: 'dictionaryStatusValueContainerCtrl',
    template: require('./dictionary-status-value-container.html'),
    replace: true
  };
}

// @ngInject
function DictionaryStatusValueContainerController($scope) {
  var dictionaryStatusValueContainerCtrl = this;

}
