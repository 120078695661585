import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxa-status.service';

export default TaxaStatusStoreStore;

const EVENTS = {
  TAXA_STATUS_STORE_STATE_CHANGED: 'shu-taxa-status-store.taxa-status-store-state-changed'
};

// @ngInject
function TaxaStatusStoreStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TAXA_STATUS_STORE_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXA_STATUS_STORE_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXA_STATUS_STORE_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      taxaStatus: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXA_STATUS_STORE_STATE_CHANGED);
  }

  function onLoadSuccess(event, taxaStatus) {
    state = Immutable({
      taxaStatus,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXA_STATUS_STORE_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      taxaStatus: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXA_STATUS_STORE_STATE_CHANGED);
  }

}

export { EVENTS };
