import { EVENTS as AUTH_SERVICE_EVENTS } from '../../services/auth.service';

require('./signin-container.scss');

export default SigninContainerDirective;

// @ngInject
function SigninContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      toState: '&',
      toParams: '&'
    },
    bindToController: true,
    controller: SigninContainerController,
    controllerAs: 'signinContainerCtrl',
    template: require('./signin-container.html'),
    replace: true
  };
}

// @ngInject
function SigninContainerController($scope, AuthStore, AuthService) {
  var signinContainerCtrl = this;

  signinContainerCtrl.logoUrl = require('./logo.svg');
  signinContainerCtrl.onSubmit = onSubmit;

  function onSubmit(email, password) {
    AuthService.authenticate(email, password, signinContainerCtrl.toState(),
      signinContainerCtrl.toParams());
  }
}
