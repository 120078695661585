import * as models from '@biotope/shuriken-status-api'
import { SectionApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';

export const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'un section
  SECTION_DETAILS_LOADING: 'shu-section-details.section-details-loading',
  SECTION_DETAILS_PROCESSING: 'shu-section-details.section-details-processing',
  SECTION_DETAILS_PROCESS_ERROR: 'shu-section-details.section-details-process-error',
  SECTION_DETAILS_PROCESS_DONE: 'shu-section-details.section-details-process-done',
  SECTION_DETAILS_LOAD_SUCCESS: 'shu-section-details.section-details-load-success',
  SECTION_DETAILS_LOAD_ERROR: 'shu-section-details.section-details-load-error',

  SECTION_CONSOLIDATE_PROCESSING: 'shu-section-consolidate.section-consolidate-processing',
  SECTION_CONSOLIDATE_PROCESS_DONE: 'shu-section-consolidate.section-consolidate-process-done',
  SECTION_CONSOLIDATE_PROCESS_ERROR: 'shu-section-consolidate.section-consolidate-process-error',

  SECTION_CREATION_PROCESSING: 'shu-section-creation.section-creation-processing',
  SECTION_CREATION_PROCESS_DONE: 'shu-section-creation.section-creation-process-done',
  SECTION_CREATION_PROCESS_ERROR: 'shu-section-creation.section-creation-process-error',

  SECTION_UPDATE_DONE: "SHU-SECTION_UPDATE_DONE",
  SECTION_DELETION_DONE: 'SHU-SECTION_DELETION_DONE',

  SECTION_STATUTES_LOADING: 'shu-section-statutes.section-statutes-loading',
  SECTION_STATUTES_LOAD_SUCCESS: 'shu-section-statutes.section-statutes-load-success',
  SECTION_STATUTES_LOAD_ERROR: 'shu-section-statutes.section-statutes-load-error',
}

//let rootScope: ng.IRootScopeService;

export class SectionService {

    private sectionApi: SectionApi;
    private toaster: any;
    private $rootScope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, SectionApi: SectionApi) {
      this.sectionApi = SectionApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;

      //console.log(SectionApi);
    }

    public load (sectionId: number) {
        this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOADING);

        return this.sectionApi.getSection(sectionId)
        .then(
            (response) => {
            this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, _.defaults({}, response.data));
          }, (error: any) => {
            //console.log(error);
            this.toaster.error('Une erreur est survenue au chargement de la section. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_ERROR, error);
          });
    }


/*
    public async loadh (sectionId: number) {
        this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOADING);

        try {
          const response = await this.sectionApi.getSection(sectionId);
          this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, _.defaults({}, response.data));
        }
        catch (error) {
          //console.log(error);
          this.toaster.error('Une erreur ***. ' + (error.data || ''));
          this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_ERROR, error);
      }
    }
*/


    public updateSection(sectionId: number, sectionForm, formController) {

      //this.$rootScope.$emit(EVENTS.SECTION_DETAILS_PROCESSING);

      this.sectionApi.updateSection(sectionId, sectionForm)
        .then(
          (section) => {
            formController.$setPristine();
            this.toaster.success('Section enregistrée');
            this.$rootScope.$emit(EVENTS.SECTION_UPDATE_DONE);
          },
          (error) => {
            this.toaster.error('Une erreur est survenue à la sauvegarde de la section. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_ERROR);
          }
        );
    }

    public consolidateSection(sectionId: number) {
      this.$rootScope.$emit(EVENTS.SECTION_CONSOLIDATE_PROCESSING);

      this.sectionApi.consolidateSection(sectionId)
        .then(
          (section) => {
            this.toaster.success('Section consolidée');
            this.$rootScope.$emit(EVENTS.SECTION_CONSOLIDATE_PROCESS_DONE, section);
          },
          (error: any) => {
            this.toaster.error('Une erreur est survenue à la sauvegarde de la section. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.SECTION_CONSOLIDATE_PROCESS_ERROR);
          }
        );
    }

    public createSection(section: models.Section) {
       this.$rootScope.$emit(EVENTS.SECTION_CREATION_PROCESSING);

       this.sectionApi.createSection(section)
       .then(
         (section) => {
           this.toaster.success('Section créée avec succès');
           this.$rootScope.$emit(EVENTS.SECTION_CREATION_PROCESS_DONE, section);
         },
         (error: any) => {
           this.toaster.error('Une erreur est survenue à la création de la section. ' + (error.data || ''));
           this.$rootScope.$emit(EVENTS.SECTION_CREATION_PROCESS_ERROR);
         }
       );
    }

    public deleteSection(sectionId: number) {
        return this.sectionApi.deleteSection(sectionId)
        .then(
            (response) => {
            this.toaster.success('Section supprimée avec succès');
            this.$rootScope.$emit(EVENTS.SECTION_DELETION_DONE, _.defaults({}, response.data));
          }, (error: any) => {
            //console.log(error);
            this.toaster.error('Une erreur est survenue à la suppression de la section. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.SECTION_DETAILS_PROCESS_ERROR, error);
          });
    }

    public getSectionStatutes(sectionId: number) {
          this.$rootScope.$emit(EVENTS.SECTION_STATUTES_LOADING);

          return this.sectionApi.getSectionStatutes(sectionId)
          .then(
              (response) => {
              this.$rootScope.$emit(EVENTS.SECTION_STATUTES_LOAD_SUCCESS, _.defaults({}, response.data));
            }, (error: any) => {
              //console.log(error);
              this.toaster.error('Une erreur est survenue au chargement des statuts de la section. ' + (error.data || ''));
              this.$rootScope.$emit(EVENTS.SECTION_STATUTES_LOAD_ERROR, error);
            });

    }


}
