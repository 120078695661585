import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/moderation.service';

export default ModerationServiceStore;

const EVENTS = {
  MODERATION_PENDING_PUBLICATION_SERVICE_STATE_CHANGED: 'shu-moderation-service.moderation-pending-publication-service-state-changed',
  MODERATION_PENDING_SECTION_SERVICE_STATE_CHANGED: 'shu-moderation-service.moderation-pending-publication-service-state-changed'
};

// @ngInject
function ModerationServiceStore($rootScope) {
  var pendingPublicationsState = Immutable({});
  var pendingSectionsState = Immutable({});


  $rootScope.$on(SERVICE_EVENTS.MODERATION_PENDING_PUBLICATION_LOADING, onPendingPublicationLoading);
  $rootScope.$on(SERVICE_EVENTS.MODERATION_PENDING_PUBLICATION_LOAD_SUCCESS, onPendingPublicationLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.MODERATION_PENDING_PUBLICATION_LOAD_ERROR, onPendingPublicationLoadError);

  $rootScope.$on(SERVICE_EVENTS.MODERATION_PENDING_SECTION_LOADING, onPendingSectionLoading);
  $rootScope.$on(SERVICE_EVENTS.MODERATION_PENDING_SECTION_LOAD_SUCCESS, onPendingSectionLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.MODERATION_PENDING_SECTION_LOAD_ERROR, onPendingSectionLoadError);

  return {getPendingPublicationsState, getPendingSectionsState};

  function getPendingPublicationsState() {
    return pendingPublicationsState;
  }

  function getPendingSectionsState() {
    return pendingSectionsState;
  }

  function onPendingPublicationLoading() {
    pendingPublicationsState = Immutable({
      pendingPublications: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.MODERATION_PENDING_PUBLICATION_SERVICE_STATE_CHANGED);
  }

  function onPendingPublicationLoadSuccess(event, pendingPublications) {
    pendingPublicationsState = Immutable({
      pendingPublications,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.MODERATION_PENDING_PUBLICATION_SERVICE_STATE_CHANGED);
  }

  function onPendingPublicationLoadError() {
    pendingPublicationsState = Immutable({
      pendingPublications: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.MODERATION_PENDING_PUBLICATION_SERVICE_STATE_CHANGED);
  }

  function onPendingSectionLoading() {
    pendingSectionsState = Immutable({
      pendingSections: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.MODERATION_PENDING_SECTION_SERVICE_STATE_CHANGED);
  }

  function onPendingSectionLoadSuccess(event, pendingSections) {
    pendingSectionsState = Immutable({
      pendingSections,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.MODERATION_PENDING_SECTION_SERVICE_STATE_CHANGED);
  }

  function onPendingSectionLoadError() {
    pendingSectionsState = Immutable({
      pendingSections: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.MODERATION_PENDING_SECTION_SERVICE_STATE_CHANGED);
  }

}

export { EVENTS };
