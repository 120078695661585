const STORAGE_KEY = 'shu-publication.repository';

export default PublicationRepository;

// @ngInject
function PublicationRepository($http, PublicationConfiguration) {
  return { query, getPublication, getTerrUnit, sendAdvancedSearch, getStudy, getPublicationStatutes, getPublicationStatutesTypes, exportPublication, uploadStatusFile };

  function query(query, txGroups, types, terrUnits, offset, limit) {
    return $http({
      method: 'GET',
      url: PublicationConfiguration.searchEndpoint,
      params: {query, txGroups, types, terrUnits, offset, limit}
    }).then((response) => response.data);
  }

  function sendAdvancedSearch(advancedSearchJson) {
    return $http({
      method: 'POST',
      url: PublicationConfiguration.advancedSearchEndpoint,
      headers: { 'Content-Type': 'application/json'},
      data: advancedSearchJson
    }).then((response) => response.data);
  }


  function getPublication(publicationId) {
    return $http({
      method: 'GET',
      url: PublicationConfiguration.publicationDetailsEndpoint,
      pathParams: {publicationId}
    }).then((response) => _.defaults({}, response.data));
  }

  function getTerrUnit(query) {
    return $http({
      method: 'GET',
      url: PublicationConfiguration.searchTerrUnitEndPoint,
      params: { query }
    }).then((response) => response.data);
  }

  function getStudy(limit, noGeom, offset, query, searchAll, status) {
    return $http({
      method: 'GET',
      url: PublicationConfiguration.searchStudyEndPoint,
      params: { limit, noGeom, offset, query, searchAll, status  }
    }).then((response) => response.data);
  }

  function getPublicationStatutes(publicationId) {
    return $http({
      method: 'GET',
      url: PublicationConfiguration.publicationStatutesEndPoint,
      pathParams: { publicationId }
    }).then((response) => response.data);
  }

  function getPublicationStatutesTypes(publicationId) {
    return $http({
      method: 'GET',
      url: PublicationConfiguration.publicationStatutesMetadataEndPoint,
      pathParams: { publicationId }
    }).then((response) => response.data);
  }

  function exportPublication(publicationId){
    return $http({
      method: 'GET',
      url: PublicationConfiguration.publicationExportEndpoint,
      pathParams: { publicationId },
      responseType: 'arraybuffer'
    }).then((response) => {
       let options = { type: 'application/octet-stream;charset=utf-8;' };
       let filename = 'statuts-export-publication-'+publicationId+'.xlsx';
       var disposition = response.headers()['Content-Disposition'];
       createAndDownloadBlobFile(response.data, options, filename);
  });

}

function uploadStatusFile(publicationId,fileName,fileInfo, refid) {
  return $http({
    method: 'POST',
    url: PublicationConfiguration.publicationImportEndpoint,
    headers: { 'Content-Type': undefined},
    pathParams: { publicationId },
    data: toFormData(fileName, fileInfo, refid),
    responseType: 'text'
  }).then((response) => response.data);
}
function toFormData(fileName, fileInfo, refid) {
  var formData = new FormData();
  formData.append('fileToUpload', fileInfo.file);
  formData.append('refId', refid);
  return formData;
}

    function createAndDownloadBlobFile(body, options, filename) {

        var blob = new Blob([body], options);

        var link = document.createElement("a");

        // Browsers that support HTML5 download attribute
        if (link.download !== undefined)
        {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    }
}
