import * as models from '@biotope/shuriken-status-api'
import { ImportApi } from '@biotope/shuriken-status-api'
import { ConsolidationApi } from '../api/ConsolidationApi'
import _ from 'lodash';

export const EVENTS = {
  TAXA_FILE_CONSOLIDATION_PROCESSING: 'shu-account.taxa-file-consolidation-processing',
  TAXA_FILE_CONSOLIDATION_PROCESS_DONE: 'shu-account.taxa-file-consolidation-process-done',
  TAXA_FILE_CONSOLIDATION_PROCESS_ERROR: 'shu-account.taxa-file-consolidation-process-error'
}

export class ConsolidationService {

    private importApi: ImportApi;
    private consolidationApi: ConsolidationApi;
    private toaster: any;
    private $rootScope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, ImportApi: ImportApi, ConsolidationApi: ConsolidationApi) {
      this.importApi = ImportApi;
      this.consolidationApi = ConsolidationApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
    }

    public uploadFileToConsolidation(referentiel: models.Taxa, file: any) {
       this.$rootScope.$emit(EVENTS.TAXA_FILE_CONSOLIDATION_PROCESSING);

       this.consolidationApi.uploadFileToConsolidation(referentiel, file)
       .then(
         (consolidatedRecords) => {
           //this.toaster.success('ok...');
           this.$rootScope.$emit(EVENTS.TAXA_FILE_CONSOLIDATION_PROCESS_DONE, consolidatedRecords.data);
         },
         (error: any) => {
           // sticky property set to true
           this.toaster.error('Erreurs : <br/>' + (error.data || ''), true);
           this.$rootScope.$emit(EVENTS.TAXA_FILE_CONSOLIDATION_PROCESS_ERROR);
         }
       );
    }

}
