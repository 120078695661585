import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/publication-statutes.service';

export default PublicationStatutesMetadataStore;

const EVENTS = {
  PUBLICATION_STATUTES_METADATA_STATE_CHANGED: 'shu-publication-statutes-types.publication-statutes-types-state-changed'
};

// @ngInject
function PublicationStatutesMetadataStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STATUTES_METADATA_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STATUTES_METADATA_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STATUTES_METADATA_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      publicationStatutesMetadata: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STATUTES_METADATA_STATE_CHANGED);
  }

  function onLoadSuccess(event, publicationStatutesMetadata) {
    state = Immutable({
      publicationStatutesMetadata,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STATUTES_METADATA_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      publicationStatutesMetadata: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STATUTES_METADATA_STATE_CHANGED);
  }

}

export { EVENTS };
