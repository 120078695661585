import _ from 'lodash';

export default UrlResolverInterceptor;

// @ngInject
function UrlResolverInterceptor($injector) {
  return {
    'request': function({url, pathParams, ...config}) {
      if (_.isString(url) && _.startsWith(url, 'http')) {
        // jshint ignore:start
        // ne gère pas spread object
        return {url, ...config};
        // jshint ignore:end
      }

      if (_.isString(url)) {
        return $injector.get('UrlResolverService')
          .resolveUrlFromString(url, pathParams)
          .then((resolvedUrl) => {
            return _.assign({url: resolvedUrl}, config);
          });
      }

      // On injecte UrlResolverService au moment voulu pour éviter
      // des problèmes de dépendences cycliques
      return $injector.get('UrlResolverService')
        .resolveUrl(url, pathParams)
        .then((resolvedUrl) => {
          return _.assign({url: resolvedUrl}, config);
        });
    }
  };
}
