import sectionStateListStore from './stores/section-state-list.store';

import sectionStateSelect from './components/section-state-select/section-state-select.component';
import sectionStatutesList from './components/section-statutes-list/section-statutes-list.component';
import angular from 'angular';

import sectionDetailsContainer from '../section/components/section-details-container/section-details-container.component';
import sectionDetails from '../section/components/section-details/section-details.component';
import sectionDetailsStore from '../section/stores/section-details.store';
import sectionServiceStore from './stores/section-service.store';
//import sectionDetailsService from './services/section-details.service';
//import sectionRepository from './repositories/section.repository';
import sectionStatusFilter from '../section/filters/section-status.filter';
import sectionConsolidateStore from '../section/stores/section-consolidate.store';
//import sectionConsolidateService from './services/section-consolidate.service';
import sectionNewContainer from '../section/components/section-new-container/section-new-container.component';
import { SectionService } from '../section/services/section.service';
import { SectionFlowService } from './services/section-flow.service';

import routerConfig from '../section/routes';
//import { SectionRouterConfig } from './routes';
//import sectionConfiguration from './configuration';

const moduleName = 'shu-section';

angular.module(moduleName, ['lumx'])
  .directive('shuSectionDetailsContainer', sectionDetailsContainer)
  .directive('shuSectionNewContainer', sectionNewContainer)
  .directive('shuSectionStatutesList', sectionStatutesList)
  .directive('shuSectionStateSelect', sectionStateSelect)
  .directive('shuSectionDetails', sectionDetails)
  .service('SectionService', SectionService)
  //.factory('SectionDetailsService', sectionDetailsService)
  //.factory('SectionRepository', sectionRepository)
  .factory('SectionConsolidateStore', sectionConsolidateStore)
  //.factory('SectionConsolidateService', sectionConsolidateService)
  .factory('SectionServiceStore', sectionServiceStore)
  .service('SectionFlowService', SectionFlowService)
  .factory('SectionStateListStore', sectionStateListStore)
  .factory('SectionDetailsStore', sectionDetailsStore)
  .filter('sectionStatusLabel', sectionStatusFilter)
  //.constant('SectionConfiguration', sectionConfiguration)
  .config(routerConfig);
  // https://stackoverflow.com/questions/25073365/how-can-i-add-ui-router-stateprovider-configuration-to-my-application-with-types
  //.config(["$stateProvider",  ($stateProvider) => { return routerConfig }]);
  /*.config(
     ["$stateProvider",  "$stateParamsProvider", // more dependencies
         ($stateProvider, $stateParamsProvider) =>
         {
             return new SectionRouterConfig($stateProvider, $stateParamsProvider);
         }
     ]);*/

export default { moduleName };
