import * as models from '@biotope/shuriken-ng-referentiel-api'
import { TerrUnitApi } from '@biotope/shuriken-ng-referentiel-api'
import _ from 'lodash';

export const EVENTS = {
  // Actions lancées quand l'utilisateur charge les txgroupes
  TERR_UNIT_LIST_LOADING: 'shu-terr-unit.terr-unit-list-results-loading',
  TERR_UNIT_LIST_LOAD_SUCCESS: 'shu-terr-unit.terr-unit-list-results-load-success',
  TERR_UNIT_LIST_LOAD_ERROR: 'shu-terr-unit.terr-unit-list-results-load-error',
  TERR_UNIT_BY_KEY_LOADING: 'SHU-TERR_UNIT_BY_KEY_LOADING',
  TERR_UNIT_BY_KEY_LOAD_SUCCESS: 'SHU-TERR_UNIT_BY_KEY_LOAD_SUCCESS',
  TERR_UNIT_BY_KEY_LOAD_ERROR: 'SHU-TERR_UNIT_BY_KEY_LOAD_ERROR',
}

export class TerrUnitService {

    private terrUnitApi: TerrUnitApi;
    private toaster: any;
    private $rootScope: any;
    //private $scope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, TerrUnitApi: TerrUnitApi) {
      this.terrUnitApi = TerrUnitApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
      //this.$scope = $scope;
    }

    public onUpdateTerrUnitForm(query: string) {
      //console.log(this.$rootScope);

      this.$rootScope.$emit(EVENTS.TERR_UNIT_LIST_LOADING);

      return this.terrUnitApi.searchTerrUnit(query)
      .then(
          (response) => {
          this.$rootScope.$emit(EVENTS.TERR_UNIT_LIST_LOAD_SUCCESS, response.data);
        }, (error: any) => {
          //console.log(error);
          this.toaster.error('Une erreur est survenue au chargement des zones administratives. ' + (error.data || ''));
          this.$rootScope.$emit(EVENTS.TERR_UNIT_LIST_LOAD_ERROR, error);
        });
    }

    public getTerrUnitByKey(key: string) {

      this.$rootScope.$emit(EVENTS.TERR_UNIT_BY_KEY_LOADING);

      let noGeom = true;
      
      return this.terrUnitApi.getTerrUnitByKey(key, noGeom)
      .then(
          (response) => {
          let r = new Array();
          r.push(response.data);
          this.$rootScope.$emit(EVENTS.TERR_UNIT_BY_KEY_LOAD_SUCCESS, r);
          return response.data;
        }, (error: any) => {
          //console.log(error);
          this.toaster.error('Une erreur est survenue lors de la recheche par clé. ' + (error.data || ''));
          this.$rootScope.$emit(EVENTS.TERR_UNIT_BY_KEY_LOAD_ERROR, error);
        });
    }

    public resetTerrUnit() {
      this.onUpdateTerrUnitForm('');
    }
}
