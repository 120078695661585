import { EVENTS as PUBLICATION_SEARCH_STORE_EVENTS } from '../../stores/publication-search.store';

require('./publication-search-container.scss');

export default PublicationSearchContainerDirective;

// @ngInject
function PublicationSearchContainerDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    //bindToController: true,
    controller: PublicationSearchContainerController,
    controllerAs: 'publicationSearchContainerCtrl',
    template: require('./publication-search-container.html'),
    replace: true
  };
}

// @ngInject
function PublicationSearchContainerController($scope, $rootScope, HeaderService, PublicationSearchStore, PublicationSearchService) {
  var publicationSearchContainerCtrl = this;

  publicationSearchContainerCtrl.queryState = {};
  publicationSearchContainerCtrl.resultsState = {};

  publicationSearchContainerCtrl.onUpdateForm = PublicationSearchService.onUpdateForm;
  publicationSearchContainerCtrl.onNextResultsAction = onNextResultsAction;
  publicationSearchContainerCtrl.showResults = showResults;

  $scope.$on(PUBLICATION_SEARCH_STORE_EVENTS.PUBLICATION_SEARCH_QUERY_STATE_CHANGED, reloadQuery);
  $scope.$on(PUBLICATION_SEARCH_STORE_EVENTS.PUBLICATION_SEARCH_RESULTS_STATE_CHANGED, reloadResults);

  resetStudySearch();
  init();

  function init() {
    HeaderService.updateTitle([
      'Recherche d\'une publication'
    ]);
  }

  function showResults() {
    return publicationSearchContainerCtrl.resultsState.loaded || publicationSearchContainerCtrl.resultsState.totalResults > 0;
  }

  function reloadQuery() {
    publicationSearchContainerCtrl.queryState = PublicationSearchStore.getQueryState();
  }

  function reloadResults() {
    publicationSearchContainerCtrl.resultsState = PublicationSearchStore.getResultsState();
    //console.log("loading ?"+publicationSearchContainerCtrl.resultsState.loading);
    //console.log("loaded ?"+publicationSearchContainerCtrl.resultsState.loaded);
  }

  function resetStudySearch() {
    //console.log("reset");
    PublicationSearchService.reset();
  }

  function onNextResultsAction() {
    PublicationSearchService.loadNextResults(
      publicationSearchContainerCtrl.queryState.query,
      publicationSearchContainerCtrl.queryState.txGroups,
      publicationSearchContainerCtrl.queryState.types,
      publicationSearchContainerCtrl.queryState.terrUnits,
      publicationSearchContainerCtrl.resultsState.results.length
    );
  }
}
