import { EVENTS as PUBLICATION_STATUTES_STORE_EVENTS } from '../../stores/publication-statutes.store';
import { EVENTS as PUBLICATION_STATUTES_METADATA_STORE_EVENTS } from '../../stores/publication-statutes-metadata.store';

require('./publication-statutes-list.scss');

export default PublicationStatutesListDirective;

// @ngInject
function PublicationStatutesListDirective() {
  return {
    restrict: 'E',
    scope: {
      //initialPublicationStatutesProvider: '&initialPublicationStatutes',
      publicationId: '@'
    },
    bindToController: true,
    controller: PublicationStatutesListController,
    controllerAs: 'publicationStatutesListCtrl',
    template: require('./publication-statutes-list.html'),
    replace: true
  };
}

// @ngInject
function PublicationStatutesListController($scope, PublicationStatutesService, PublicationStatutesStore, PublicationStatutesMetadataStore) {
  var publicationStatutesListCtrl = this;

  // https://makitweb.com/add-remove-class-with-ngclass-in-angularjs/
  publicationStatutesListCtrl.fitStatusList = true;

  publicationStatutesListCtrl.publicationStatutesMetadataState = {};
  publicationStatutesListCtrl.publicationStatutesState = {};

  publicationStatutesListCtrl.statutesValuesAreLoaded = statutesValuesAreLoaded;
  publicationStatutesListCtrl.statutesTypesAreLoaded = statutesTypesAreLoaded;
  publicationStatutesListCtrl.getStatusCssClass = getStatusCssClass;
  publicationStatutesListCtrl.isLoading = isLoading;

  $scope.$watch(() => publicationStatutesListCtrl.publicationId, () => {
    if (publicationStatutesListCtrl.publicationId) {
      //first, load statutes types
      loadPublicationStatutesTypes();
    }

  });

  $scope.$on(PUBLICATION_STATUTES_METADATA_STORE_EVENTS.PUBLICATION_STATUTES_METADATA_STATE_CHANGED, reload_types);
  $scope.$on(PUBLICATION_STATUTES_STORE_EVENTS.PUBLICATION_STATUTES_STATE_CHANGED, reload);

  function reload_types() {
    publicationStatutesListCtrl.publicationStatutesMetadataState = PublicationStatutesMetadataStore.getState();

    if (publicationStatutesListCtrl.publicationStatutesMetadataState.error) {
      return;
    }

    // Create a copy of the immutable initialPublication
    publicationStatutesListCtrl.metadata = _.defaultsDeep({}, publicationStatutesListCtrl.publicationStatutesMetadataState.publicationStatutesMetadata);

    publicationStatutesListCtrl.statutesTypes = publicationStatutesListCtrl.metadata.types;
    publicationStatutesListCtrl.statutesTerrUnits = publicationStatutesListCtrl.metadata.terrUnits;

    publicationStatutesListCtrl.headers = publicationStatutesListCtrl.metadata.headers;
  
    if(publicationStatutesListCtrl.headers && publicationStatutesListCtrl.headers.length > 5) {
      publicationStatutesListCtrl.fitStatusList = false;
    }


    /*publicationStatutesListCtrl.headers = [];
    _.forEach(publicationStatutesListCtrl.statutesTypes, function(type) {

      _.forEach(publicationStatutesListCtrl.statutesTerrUnits, function(terrUnit) {
          publicationStatutesListCtrl.headers.push({"label": type.key+"_"+terrUnit.name, "typeKey": type.key, "terrUnitKey": terrUnit.key});
      });
    });
    console.log(publicationStatutesListCtrl.headers);*/

    publicationStatutesListCtrl.taxonomicGroups = publicationStatutesListCtrl.metadata.taxonomicGroups;

    if(statutesTypesAreLoaded()) {
      loadPublicationStatutes()
    }
  }

  function reload() {
    //publicationStatutesListCtrl.publicationStatutesMetadataState = PublicationStatutesMetadataStore.getState();
    publicationStatutesListCtrl.publicationStatutesState = PublicationStatutesStore.getState();

    if (publicationStatutesListCtrl.publicationStatutesState.error || publicationStatutesListCtrl.publicationStatutesMetadataState.error) {
      return;
    }

    // Create a copy of the immutable initialPublication
    publicationStatutesListCtrl.publicationStatutes = _.toArray(_.defaultsDeep({}, publicationStatutesListCtrl.publicationStatutesState.publicationStatutes));
  }

  function statutesTypesAreLoaded() {
    return publicationStatutesListCtrl.publicationStatutesMetadataState && publicationStatutesListCtrl.publicationStatutesMetadataState.loaded;
  }

  function statutesValuesAreLoaded() {
    return publicationStatutesListCtrl.publicationStatutesState && publicationStatutesListCtrl.publicationStatutesState.loaded;
  }

  function isLoading() {
    //return false;
    return (publicationStatutesListCtrl.publicationStatutesMetadataState && publicationStatutesListCtrl.publicationStatutesMetadataState.loading ) ||
    (publicationStatutesListCtrl.publicationStatutesState && publicationStatutesListCtrl.publicationStatutesState.loading);
  }

  function loadPublicationStatutesTypes() {
    PublicationStatutesService.getPublicationStatutesTypes(publicationStatutesListCtrl.publicationId);
  }

  function loadPublicationStatutes() {
    PublicationStatutesService.load(publicationStatutesListCtrl.publicationId);
  }

  function getStatusCssClass(stKey, svKey) {
    if(svKey != undefined){
      return "R-"+stKey+"-"+svKey.replaceAll(' ', '');
    }
    return ;
  }

}
