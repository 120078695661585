import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/txgroup-list.service';
import TxgroupUtils from '../tools/txgroup-utils';

const EVENTS = {
  TX_GROUP_LIST_RESULTS_STATE_CHANGED: 'shu-txgroup.txgroup-list-results-state-changed'
};

export default TxGroupListStore;

// @ngInject
function TxGroupListStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TX_GROUP_LIST_RESULTS_LOADING, onTxGroupListResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.TX_GROUP_LIST_RESULTS_LOAD_SUCCESS, onTxGroupListResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TX_GROUP_LIST_RESULTS_LOAD_ERROR, onTxGroupListResultsLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onTxGroupListResultsLoading() {
    state = Immutable({
      results: [],
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TX_GROUP_LIST_RESULTS_STATE_CHANGED);
  }

  function onTxGroupListResultsLoadSuccess(event, results) {
    state = Immutable({
      results: TxgroupUtils.sort(results),
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TX_GROUP_LIST_RESULTS_STATE_CHANGED);
  }

  function onTxGroupListResultsLoadError() {
    state = Immutable({
      results: [],
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TX_GROUP_LIST_RESULTS_STATE_CHANGED);
  }

}

export { EVENTS };
