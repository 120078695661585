import expertSearchService from './services/expert-search.service';
import expertSearchStore from './stores/expert-search.store';
import remoteExpertRepository from './repositories/expert.remote.repository';
import localExpertRepository from './repositories/expert.local.repository';
import expertConfiguration from './configuration';

const moduleName = 'shu-expert';

angular.module(moduleName, ['lumx'])
  .factory('ExpertRepository', IS_CORDOVA ? localExpertRepository : remoteExpertRepository)
  .factory('ExpertSearchService', expertSearchService)
  .factory('ExpertSearchStore', expertSearchStore)
  .constant('ExpertConfiguration', expertConfiguration);

export default {moduleName};
