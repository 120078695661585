export default PublicationRouterConfig;

import { EVENTS as PROFILE_STORE_EVENTS } from '../profile/stores/profile.store';

// @ngInject
function PublicationRouterConfig($stateProvider) {
  $stateProvider.state('publication-text-search', {
    url: '/publication/text-search',
    template: '<shu-publication-search-container></shu-publication-search-container>',
    needAuthenticatedUser: true,
    controller: PublicationRouteController,
    controllerAs: 'routeCtrl'
  });
  $stateProvider.state('publication-search', {
    url: '/publication/search',
    template: '<shu-publication-advanced-search-container></shu-publication-advanced-search-container>',
    needAuthenticatedUser: true,
    controller: PublicationRouteController,
    controllerAs: 'routeCtrl'
  });
  $stateProvider.state('publication-overview', {
    url: '/publication/{publicationId}/overview',
    template: '<shu-publication-overview-container publication-id="{{ ::routeCtrl.publicationId }}"></shu-publication-overview-container>',
    needAuthenticatedUser: true,
    controller: PublicationRouteController,
    controllerAs: 'routeCtrl'
  });
  $stateProvider.state('publication', {
    url: '/publication/{publicationId}',
    template: '<shu-publication-details-container publication-id="{{ ::routeCtrl.publicationId }}"></shu-publication-details-container>',
    needAuthenticatedUser: true,
    controller: PublicationRouteController,
    controllerAs: 'routeCtrl'
  });
  $stateProvider.state('my-publications', {
    url: '/user-publications',
    template: '<shu-publication-user-list></shu-publication-user-list>',
    needAuthenticatedUser: true,
    controller: PublicationRouteController,
    controllerAs: 'routeCtrl'
  });
  $stateProvider.state('publication-new', {
    url: '/publication',
    template: '<shu-publication-new-container></shu-publication-new-container>',
    needAuthenticatedUser: true,
    controller: PublicationRouteController,
    controllerAs: 'routeCtrl'
  });

}

// @ngInject
function PublicationRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.publicationId = $stateParams.publicationId;
}
