import _ from 'lodash';
require('./settings-form.scss');

export default SettingsFormDirective;

// @ngInject
function SettingsFormDirective() {
  return {
    restrict: 'E',
    scope: {
      settings: '&',
      onSubmitFn: '&onSubmit'
    },
    bindToController: true,
    controller: SettingsFormController,
    controllerAs: 'settingsFormCtrl',
    template: require('./settings-form.html'),
    replace: true
  };
}

// @ngInject
function SettingsFormController($scope) {
  var settingsFormCtrl = this;

  settingsFormCtrl.onSubmit = onSubmit;

  $scope.$watch(settingsFormCtrl.settings, (value) => {
    settingsFormCtrl.route_auth = value.route_auth;
    settingsFormCtrl.route_referentiel = value.route_referentiel;
    settingsFormCtrl.route_naturaliste = value.route_naturaliste;
    settingsFormCtrl.route_status = value.route_status;
  });

  function onSubmit() {
    var settings = _.pick(settingsFormCtrl, ['route_auth', 'route_referentiel', 'route_naturaliste', 'route_status']);
    settingsFormCtrl.onSubmitFn()(settings);
  }
}
