import moment from 'moment-timezone';

export default TzDateFilter;

// @ngInject
function TzDateFilter() {
  return function(date, format, timezone = 'Europe/Paris') {
    return moment(date).tz(timezone).format(format);
  };
}
