export default ConsolidationRouterConfig;

// @ngInject
function ConsolidationRouterConfig($stateProvider) {
  $stateProvider.state('consolidation-tool', {
    url: '/consolidation',
    template: '<shu-consolidation-tool-container></shu-consolidation-tool-container>',
    needAuthenticatedUser: true,
    controller: ConsolidationRouteController,
    controllerAs: 'routeCtrl'
  });
}

// @ngInject
function ConsolidationRouteController($scope, $stateParams) {
  var routeCtrl = this;
}
