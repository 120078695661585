import { EVENTS as MODERATION_STORE_EVENTS } from '../../stores/moderation-service.store';

require('./moderation-pending-sections.scss');

export default ModerationPendingSectionsDirective;

import { ModerationService } from '../../services/moderation.service';

// @ngInject
function ModerationPendingSectionsDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    bindToController: true,
    controller: ModerationPendingSectionsController,
    controllerAs: 'moderationPendingSectionsCtrl',
    template: require('./moderation-pending-sections.html'),
    replace: true
  };
}

// @ngInject
function ModerationPendingSectionsController($scope, $state, ModerationService: ModerationService, ModerationServiceStore) {
  var moderationPendingSectionsCtrl = this;

    moderationPendingSectionsCtrl.moderationState = {};

    moderationPendingSectionsCtrl.pendingSections = []

    moderationPendingSectionsCtrl.isLoading = true;

    moderationPendingSectionsCtrl.onSectionSelected = onSectionSelected;

    $scope.$on(MODERATION_STORE_EVENTS.MODERATION_PENDING_SECTION_SERVICE_STATE_CHANGED, reloadPendingSectionSearchResults);

    ModerationService.getPendingSectionsList();

    function reloadPendingSectionSearchResults() {

      moderationPendingSectionsCtrl.moderationState = ModerationServiceStore.getPendingSectionsState();
      moderationPendingSectionsCtrl.pendingSections = moderationPendingSectionsCtrl.moderationState.pendingSections;
      moderationPendingSectionsCtrl.isLoading = false;
    }

    function onSectionSelected(publicationId, sectionId) {
      $state.go('section-details', {publicationId: publicationId, sectionId});
    }

}
