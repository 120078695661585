import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/publication-details.service';

export default PublicationDetailsStore;

const EVENTS = {
  PUBLICATION_DETAILS_STATE_CHANGED: 'shu-publication-details.publication-details-state-changed'
};

//PublicationDetailsService
// @ngInject
function PublicationDetailsStore($rootScope) {
  var state = Immutable({});

   $rootScope.$on(SERVICE_EVENTS.PUBLICATION_DETAILS_LOADING, onLoading);
   $rootScope.$on(SERVICE_EVENTS.PUBLICATION_DETAILS_PROCESSING, onProcessing);
   $rootScope.$on(SERVICE_EVENTS.PUBLICATION_DETAILS_PROCESS_DONE, onProcessDone);
   
   $rootScope.$on(SERVICE_EVENTS.PUBLICATION_DETAILS_LOAD_SUCCESS, onLoadSuccess);
   $rootScope.$on(SERVICE_EVENTS.PUBLICATION_DETAILS_LOAD_ERROR, onLoadError);

   return {getState};

   function getState() {
     return state;
   }

   function onLoading() {
     state = Immutable({
       publication: null,
       processing: true,
       loaded: false,
       error: false
     });
     $rootScope.$broadcast(EVENTS.PUBLICATION_DETAILS_STATE_CHANGED);
   }

   function onLoadSuccess(event, publication) {
     state = Immutable({
       publication,
       processing: false,
       loaded: true,
       error: false
     });
     $rootScope.$broadcast(EVENTS.PUBLICATION_DETAILS_STATE_CHANGED);
   }

   function onLoadError() {
     state = Immutable({
       publication: null,
       processing: false,
       loaded: false,
       error: true
     });
     $rootScope.$broadcast(EVENTS.PUBLICATION_DETAILS_STATE_CHANGED);
   }

   function onProcessing() {
     state = Immutable({
       publication: state.publication,
       processing: true,
       loaded: true,
       error: false
     });
     $rootScope.$broadcast(EVENTS.PUBLICATION_DETAILS_STATE_CHANGED);
   }

   function onProcessDone() {
     state = Immutable({
       publication: state.publication,
       processing: false,
       loaded: true,
       error: false
     });
     $rootScope.$broadcast(EVENTS.PUBLICATION_DETAILS_STATE_CHANGED);
   }
 }

 export { EVENTS };
