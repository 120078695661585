import { EVENTS as STORE_EVENTS } from '../../stores/settings.store';

require('./settings-container.scss');

export default SettingsContainerDirective;

// @ngInject
function SettingsContainerDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: SettingsContainerController,
    controllerAs: 'settingsContainerCtrl',
    template: require('./settings-container.html'),
    replace: true
  };
}

// @ngInject
function SettingsContainerController($scope, $rootScope, HeaderService, SettingsStore, SettingsService) {
  var settingsContainerCtrl = this;

  settingsContainerCtrl.state = {};

  settingsContainerCtrl.onFormSubmit = SettingsService.onFormSubmit;

  $scope.$on(STORE_EVENTS.SETTINGS_STATE_CHANGED, reload);
  loadSettings();
  init();

  function init() {
    HeaderService.updateTitle([
      {
        label: 'Accueil',
        link: {
          sref: 'home'
        }
      },
      'Paramètres'
    ]);
  }

  function reload() {
    settingsContainerCtrl.state = SettingsStore.get();
  }

  function loadSettings() {
    SettingsService.load();
  }
}
