import { EVENTS as DICTIONARY_STORE_EVENTS } from '../../stores/dictionary-ref.store';
import _ from 'lodash';
import { DictionaryRefService } from '../../services/dictionary-ref.service';

require('./dictionary-status-value.scss');

export default DictionaryStatusValueDirective;

// @ngInject
function DictionaryStatusValueDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    bindToController: true,
    controller: DictionaryStatusValueController,
    controllerAs: 'dictionaryStatusValueCtrl',
    template: require('./dictionary-status-value.html'),
    replace: true
  };
}

// @ngInject
function DictionaryStatusValueController($scope, DictionaryRefStore, DictionaryRefService: DictionaryRefService) {
  var dictionaryStatusValueCtrl = this;

  dictionaryStatusValueCtrl.onStatusTypeChange = onStatusTypeChange;
  //dictionaryStatusValueCtrl.
  dictionaryStatusValueCtrl.statusTypeKey = {};
  dictionaryStatusValueCtrl.statusValueState = {};
  dictionaryStatusValueCtrl.getStatusCssClass = getStatusCssClass;


  $scope.$on(DICTIONARY_STORE_EVENTS.DICTIONARY_STATUS_VALUE_STATE_CHANGED, onStatusValueDictionaryChanged);

  function onStatusTypeChange(value) {
    //console.log(value);
    dictionaryStatusValueCtrl.statusTypeKey = value.key;
    console.log(value);
    DictionaryRefService.getStatusValuesForStatus(value.id);
  }

  function onStatusValueDictionaryChanged() {

    dictionaryStatusValueCtrl.statusValueState = DictionaryRefStore.getState();

    if (dictionaryStatusValueCtrl.statusValueState.error || !dictionaryStatusValueCtrl.statusValueState.statusValues) {
      return;
    }

    // Create a copy of the immutable initialPublication
    dictionaryStatusValueCtrl.statusValues = _.toArray(_.defaultsDeep({}, dictionaryStatusValueCtrl.statusValueState.statusValues.values));

    //console.log(dictionaryStatusValueCtrl.statusValues);
  }

  function getStatusCssClass(stKey, svKey) {
    return "R-"+stKey+"-"+svKey.replaceAll(' ', '');
  }


}
