import _ from 'lodash';

import { PUBLICATION_TYPES,  TX_GROUPS} from '../model';

const EVENTS = {
  // Action lancée quand un des champs du formulaire de recherche est modifié
  PUBLICATION_SEARCH_QUERY_UPDATE: 'shu-publication-search.publication-search-query-update',
  // Actions lancées quand l'utilisateur lance une recherche
  PUBLICATION_SEARCH_RESULTS_LOADING: 'shu-publication-search.publication-search-results-loading',
  PUBLICATION_SEARCH_RESULTS_LOAD_SUCCESS: 'shu-publication-search.publication-search-results-load-success',
  PUBLICATION_SEARCH_RESULTS_LOAD_ERROR: 'shu-publication-search.publication-search-results-load-error',
  // Actions lancées quand l'utilisateur consulte les résultats suivants d'une recherche
  PUBLICATION_SEARCH_NEXT_RESULTS_LOADING: 'shu-publication-search.publication-search-next-results-loading',
  PUBLICATION_SEARCH_NEXT_RESULTS_LOAD_SUCCESS: 'shu-publication-search.publication-search-next-results-load-success',
  PUBLICATION_SEARCH_NEXT_RESULTS_LOAD_ERROR: 'shu-publication-search.publication-search-next-results-load-error',

  PUBLICATION_TERR_UNIT_SEARCH_RESULTS_LOADING: 'shu-publication-search.publication-terr-unit-search-results-loading',
  PUBLICATION_TERR_UNIT_SEARCH_RESULTS_LOAD_SUCCESS: 'shu-publication-search.publication-terr-unit-search-results-load-success',
  PUBLICATION_TERR_UNIT_SEARCH_RESULTS_LOAD_ERROR: 'shu-publication-search.publication-terr-unit-search-results-load-error',

  PUBLICATION_STUDY_SEARCH_RESULTS_LOADING: 'shu-publication-search.publication-study-search-results-loading',
  PUBLICATION_STUDY_SEARCH_RESULTS_LOAD_SUCCESS: 'shu-publication-search.publication-study-search-results-load-success',
  PUBLICATION_STUDY_SEARCH_RESULTS_LOAD_ERROR: 'shu-publication-search.publication-study-search-results-load-error',

};
const LIMIT = 2;

export default PublicationSearchService;

// @ngInject
function PublicationSearchService($rootScope, $state, PublicationRepository, LxNotificationService) {

  return {onUpdateForm, onUpdateTerrUnitForm , onUpdateStudyForm,  loadNextResults, onPublicationSelected, reset, resetTerrUnit, sendAdvancedForm};

  function onUpdateForm(query, txGroups, types, terrUnits) {

    $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_QUERY_UPDATE, {query, txGroups, types, terrUnits});
    $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_RESULTS_LOADING);
    
    PublicationRepository.query(query, txGroups, types, terrUnits, 0, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_RESULTS_LOAD_SUCCESS, result.publications, result.count);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la rercherche des statuts. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_RESULTS_LOAD_ERROR);
      });
  }

  function onUpdateTerrUnitForm(terrUnits) {

    PublicationRepository.getTerrUnit(terrUnits)
      .then((result) => {
        $rootScope.$emit(EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_LOAD_SUCCESS, result);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la rercherche des zones administratives. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_LOAD_ERROR);
      });
  }

  function onUpdateStudyForm(query) {

    PublicationRepository.getStudy(20, "false", 0, query, "true", "ACTIVE")
      .then((result) => {

        $rootScope.$emit(EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_LOAD_SUCCESS, result.studies, result.count);
      },(error) => {
        LxNotificationService.error('Une erreur est survenue à la rercherche des études:  ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_LOAD_ERROR);
      });
  }

  function loadNextResults(query, txGroups, types, terrUnits, currentOffset) {
    $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_NEXT_RESULTS_LOADING);

    PublicationRepository.query(query, txGroups, types, terrUnits, currentOffset, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, result.publications, result.count);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la rercherche des statuts. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_NEXT_RESULTS_LOAD_ERROR);
      });
  }

  function sendAdvancedForm(advancedJson) {

    $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_RESULTS_LOADING);

    PublicationRepository.sendAdvancedSearch(advancedJson)
      .then((result) => {
        $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_RESULTS_LOAD_SUCCESS, result.publications, result.count);

      }, (error) => {

        /*if(error.data.contains('taxonomicGroups')){

          LxNotificationService.error('Sélectionnez au moins un groupe taxonomique');
        }
        else */if(error.data.contains('étude')){
          LxNotificationService.error(error.data);
        }
        else {
          LxNotificationService.error('Une erreur est survenue à la récupération des sections. ' + (error.data || ''));
        }

        $rootScope.$emit(EVENTS.PUBLICATION_SEARCH_RESULTS_LOAD_ERROR);
      });


  }

  function onPublicationSelected(publicationId) {
    $state.go('publication-overview', {publicationId});
  }

  function reset() {
    onUpdateForm('', TX_GROUPS.VALUES, PUBLICATION_TYPES.VALUES, []);
  }


  function resetTerrUnit() {
    onUpdateTerrUnitForm('');
  }

}

export { EVENTS };
