
export default DeferredContainerDirective;

// @ngInject
function DeferredContainerDirective($compile) {
  return {
    restrict: 'A',
    scope: {
      realDirective: '@',
    },
    replace: true,
    terminal: false,
    priority: 10000,
    link: function link(scope, element, attrs) {
      // Ajout de la directive fille
      element.attr(attrs.realDirective, '');
      // Suppression de la directive actuelle
      element.removeAttr('shu-deferred-container');
      // Compilation de la directive fille avec le scope du parent
      // (celui qui a défini les parmaètres de cette directive)
      $compile(element)(scope.$parent);
    },
  };
}
