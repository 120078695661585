const STORAGE_KEY = 'shu-taxon.repository';

export default TaxonRepository;

// @ngInject
function TaxonRepository(TaxonConfiguration, $http) {
  return {query};

  function query(query, txRefType, txRefVersion, txGroupKeys, offset, limit) {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.searchEndpoint,
      pathParams: {txRefType, txRefVersion, query},
      params: {offset, limit}
    }).then((response) => response.data);
  }

}
