import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/publication-statutes.service';

export default PublicationStatutesStore;

const EVENTS = {
  PUBLICATION_STATUTES_STATE_CHANGED: 'shu-publication-statutes.publication-statutes-state-changed'
};

// @ngInject
function PublicationStatutesStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STATUTES_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STATUTES_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PUBLICATION_STATUTES_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      publicationStatutes: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STATUTES_STATE_CHANGED);
  }

  function onLoadSuccess(event, publicationStatutes) {
    state = Immutable({
      publicationStatutes,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STATUTES_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      publicationStatutes: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PUBLICATION_STATUTES_STATE_CHANGED);
  }

}

export { EVENTS };
