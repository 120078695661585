import _ from 'lodash';

export default SettingsRepository;

// @ngInject
function SettingsRepository($q, DatabaseProvider) {
  var database;

  return {get, store};

  function get() {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase()
        .then((database) => {
          database.readTransaction((transaction) => {
            transaction.executeSql(`
              SELECT key, value
              FROM ShuSettings
            `, [], (t, data) => {
              if (data.rows.length === 0) {
                resolve(null);
                return;
              }

              var settings = {};
              for (let i = 0; i < data.rows.length; i++) {
                settings[data.rows.item(i).key] = data.rows.item(i).value;
              }
              resolve(settings);
            }, (tx, error) => reject(error));
          });
        });
    });
  }

  function store(settings) {
    return DatabaseProvider.getDatabase()
      .then((database) => {
        database.transaction((transaction) => {
          _.forOwn(settings, (value, key) => {
            transaction.executeSql(
              'INSERT OR REPLACE INTO ShuSettings (key, value) VALUES (?, ?)',
              [key, value]
            );
          });
        });
      });
  }
}
