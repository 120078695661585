import { EVENTS as PUBLICATION_SEARCH_STORE_EVENTS } from '../../stores/publication-search.store';


require('./publication-advanced-search-container.scss');

export default PublicationAdvancedSearchContainerDirective;

// @ngInject
function PublicationAdvancedSearchContainerDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    //bindToController: true,
    controller: PublicationAdvancedSearchContainerController,
    controllerAs: 'publicationAdvancedSearchContainerCtrl',
    template: require('./publication-advanced-search-container.html'),
    replace: true
  };
}

// @ngInject
function PublicationAdvancedSearchContainerController($scope, $rootScope, HeaderService, PublicationSearchStore, PublicationSearchService) {
  var publicationAdvancedSearchContainerCtrl = this;

  publicationAdvancedSearchContainerCtrl.queryState = {};
  publicationAdvancedSearchContainerCtrl.resultsState = {};
  publicationAdvancedSearchContainerCtrl.resultsTerrUnitState = {};
  publicationAdvancedSearchContainerCtrl.resultsStudyState = {};

  publicationAdvancedSearchContainerCtrl.onUpdateForm = PublicationSearchService.onUpdateForm;
  publicationAdvancedSearchContainerCtrl.sendAdvancedForm = PublicationSearchService.sendAdvancedForm;
  publicationAdvancedSearchContainerCtrl.onUpdateTerrUnitForm = PublicationSearchService.onUpdateTerrUnitForm;
  publicationAdvancedSearchContainerCtrl.onUpdateStudyForm = PublicationSearchService.onUpdateStudyForm;
  publicationAdvancedSearchContainerCtrl.onStudySelected = PublicationSearchService.onStudySelected;
  publicationAdvancedSearchContainerCtrl.onNextResultsAction = onNextResultsAction;
  publicationAdvancedSearchContainerCtrl.showResults = showResults;


  $scope.$on(PUBLICATION_SEARCH_STORE_EVENTS.PUBLICATION_SEARCH_QUERY_STATE_CHANGED, reloadQuery);
  $scope.$on(PUBLICATION_SEARCH_STORE_EVENTS.PUBLICATION_SEARCH_RESULTS_STATE_CHANGED, reloadResults);

  $scope.$on(PUBLICATION_SEARCH_STORE_EVENTS.PUBLICATION_TERR_UNIT_SEARCH_RESULTS_STATE_CHANGED, reloadTerrUnitResults);
  $scope.$on(PUBLICATION_SEARCH_STORE_EVENTS.PUBLICATION_STUDY_SEARCH_RESULTS_STATE_CHANGED, reloadStudyResults);
  resetStudySearch();
  init();


  function init() {

    HeaderService.updateTitle([
      'Recherche d\'une publication'
    ]);

  }

  function showResults() {
    return publicationAdvancedSearchContainerCtrl.resultsState.loaded || publicationAdvancedSearchContainerCtrl.resultsState.totalResults > 0;
  }

  function reloadQuery() {
    publicationAdvancedSearchContainerCtrl.queryState = PublicationSearchStore.getQueryState();
  }

  function reloadResults() {
    publicationAdvancedSearchContainerCtrl.resultsState = PublicationSearchStore.getResultsState();
  }

  function reloadTerrUnitResults() {
    publicationAdvancedSearchContainerCtrl.resultsTerrUnitState = PublicationSearchStore.getResultsTerrUnitState();
  }

  function reloadStudyResults() {

    publicationAdvancedSearchContainerCtrl.resultsStudyState = PublicationSearchStore.getResultsStudyState();

  }

  function resetStudySearch() {
    //console.log("reset");
    PublicationSearchService.reset();
  }


  function onNextResultsAction() {
    PublicationSearchService.loadNextResults(
      publicationAdvancedSearchContainerCtrl.queryState.query,
      publicationAdvancedSearchContainerCtrl.queryState.txGroups,
      publicationAdvancedSearchContainerCtrl.queryState.types,
      publicationAdvancedSearchContainerCtrl.queryState.terrUnits,
      publicationAdvancedSearchContainerCtrl.resultsState.results.length
    );

  }

}
