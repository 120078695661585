import { EVENTS as PUBLICATION_STORE_EVENTS } from '../../../publication/stores/publication-service.store';

require('./publication-details.scss');

export default PublicationDetailsDirective;

import * as models from '@biotope/shuriken-status-api'
import * as referentielModels from '@biotope/shuriken-ng-referentiel-api'

import _ from 'lodash';

// @ngInject
function PublicationDetailsDirective() {
  return {
    restrict: 'E',
    scope: {
      formProvider: '&form',
      initialPublicationProvider: '&initialPublication',
      publicationtionStateProvider: '&publicationtionState',
      isModeratorProvider: '&isModerator',
      canEditProvider: '&canEdit',
      onSubmitFn: '&onSubmit',
      onChangeFn: '&onChange',
      onCreateFn: '&onCreate'
    },
    bindToController: true,
    controller: PublicationDetailsController,
    controllerAs: 'publicationDetailsCtrl',
    template: require('./publication-details.html'),
    replace: true
  };
}

// @ngInject
function PublicationDetailsController($scope, $state, $timeout, PublicationService, PublicationServiceStore) {
    var publicationDetailsCtrl = this;

    publicationDetailsCtrl.publication = {};

    publicationDetailsCtrl.creationInProgress = creationInProgress;
    publicationDetailsCtrl.isFormValid = isFormValid;
    publicationDetailsCtrl.onChange = onChange;
    publicationDetailsCtrl.onSubmit = onSubmit;
    publicationDetailsCtrl.onCitationChange = onCitationChange;
    publicationDetailsCtrl.onCreate = onCreate;
    publicationDetailsCtrl.getTooltipContent = getTooltipContent;
    publicationDetailsCtrl.withTooltips = true;

    publicationDetailsCtrl.alertPublicationsSimilarity = null;

    publicationDetailsCtrl.tooltips = {
      "label": "Mettre ici un nom abrégé de la référence bibliographique qui permette d'identifier rapidement le document de statuts. Exemples: \"Liste rouge des amphibiens d'Alsace\"; \"Déterminants ZNIEFF d'Occitanie\"; \"Bryophytes protégées de Normandie\", etc. Il n'est pas nécessaire de préciser l'année du document de statut dans le libellé.",
      "citation": "Copier ici la référence bibliographique complète, recommandée par l'auteur du document. A défaut, saisir la référence de la façon suivante: Nom auteur, Année. Nom du document de statuts.",
      "year": "Année de publication du document de statut",
      "url":  "Copier ici l'URL de la page Web sur laquelle le document de statut peut être téléchargé ou à défaut visualisé.",
      "statusType": "Sélectionner les types de statuts définis dans le document de statut et dont l'import dans la base de données de Biotope est souhaitable. Si le type de statut que vous recherchez n'existe pas, merci de faire part de votre demande par mail à un modérateur.",
      "txGroup": "Sélectionner le ou les groupes biologiques concernés par le document de statut. Les bryophytes et les lichens sont inclus dans la flore.",
      "terrUnit": "Sélectionner la ou les unités administratives concernées par le document de statut",
    }

    $scope.$on(PUBLICATION_STORE_EVENTS.PUBLICATION_SERVICE_SIMILARITY_FOUND, displaySimilarityAlert);

    $scope.$watch(publicationDetailsCtrl.canEditProvider, (canEdit) => {
      publicationDetailsCtrl.canEdit = canEdit;
    });

    $scope.$watch(publicationDetailsCtrl.isModeratorProvider, (isModerator) => {
      publicationDetailsCtrl.isModerator = isModerator;
    });

    $scope.$watch(publicationDetailsCtrl.initialPublicationProvider, (initialPublication) => {
      if (!initialPublication) {
        return;
      }

      // Create a copy of the immutable initialPublication
      publicationDetailsCtrl.publication = _.defaults({}, initialPublication.publication);
      publicationDetailsCtrl.approbationSelector = publicationDetailsCtrl.publication.approved != null;
      publicationDetailsCtrl.onChange();
      checkSimilarity(publicationDetailsCtrl.publication);
    });

    $scope.$watch(publicationDetailsCtrl.publicationStateProvider, (publicationState) => {
      if (!publicationState) {
        return;
      }
      publicationDetailsCtrl.publicationState = publicationState;
    });

    $scope.$watch(publicationDetailsCtrl.formProvider, (form) => {
      publicationDetailsCtrl.form = form;

    });


    function creationInProgress() {
      if (publicationDetailsCtrl.publication.id)
        return false;

      return true;
    }


    function onChange() {
      if (!publicationDetailsCtrl.publication) {
        return;
      }

      // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
      $timeout(() => {

        //publicationDetailsCtrl.publication = _.mapValues(_.pick($scope.publicationForm, ['citation', 'year', 'label']), '$viewValue');

        let publication: models.Publication = _.mapValues(_.pick($scope.publicationForm, ['citation', 'year', 'label', 'url']), '$viewValue');

        publication.id = publicationDetailsCtrl.publication.id;
        //console.log(publicationDetailsCtrl.publication.approved);

        publication.approved = publicationDetailsCtrl.approbationSelector && !publicationDetailsCtrl.publication.approved ? new Date() : publicationDetailsCtrl.publication.approved;

        let publicationFormData: models.PublicationForm = {
          publication: publication,
          /*terrUnit: _.mapValues(_.pick($scope.publicationForm, ['terrUnit']), '$viewValue').terrUnit,
          statusType: _.mapValues(_.pick($scope.publicationForm, ['statusType']), '$viewValue').statusType,
          taxonomicGroups: _.mapValues(_.pick($scope.publicationForm, ['txGroup']), '$viewValue').txGroup,*/
        };

        publicationDetailsCtrl.onChangeFn()({
          data: publicationFormData,
          formValid: isFormValid()
        });
      });
    }

    function onCitationChange() {

      //Trigger onChange to update main container
      onChange();

      let inPublication: models.Publication = _.mapValues(_.pick($scope.publicationForm, ['citation']), '$viewValue');

      checkSimilarity(inPublication);
      /*
      not prefered here because it slow down the execution
      const greet = async () => {
          let publicationSearchResults: models.PublicationSearchResults = await PublicationService.searchPublicationsBySimilarity(inPublication.citation);

          if(publicationSearchResults.count > 0) {
            publicationDetailsCtrl.alertPublicationsSimilarity = {
              isDuplicate: publicationSearchResults.publications[0].publication.citation == inPublication.citation,
              publication: publicationSearchResults.publications[0].publication
            }
          }

      }

      greet();
      */

    }

    function checkSimilarity(publication: models.Publication) {
      publicationDetailsCtrl.alertPublicationsSimilarity = null;

      PublicationService.searchPublicationsBySimilarity(publication);
    }

    function displaySimilarityAlert() {

      let inPublication: models.Publication = _.mapValues(_.pick($scope.publicationForm, ['citation']), '$viewValue');

      let publicationState = PublicationServiceStore.getState();
      let publicationSearchResults: models.PublicationSearchResults  = publicationState.publication;

      if(publicationSearchResults.count > 0) {
        publicationDetailsCtrl.alertPublicationsSimilarity = {
          isDuplicate: publicationSearchResults.publications[0].publication.citation == inPublication.citation,
          publication: publicationSearchResults.publications[0].publication
        }
      }

    }

    function onCreate() {

      let terrUnits: referentielModels.TerrUnit[] = _.mapValues(_.pick($scope.publicationForm, ['terrUnit']), '$viewValue').terrUnit;

      let publicationFormData: models.PublicationForm = {
        publication: _.mapValues(_.pick($scope.publicationForm, ['citation', 'year', 'label', 'url']), '$viewValue'),
        terrUnitKeys: _.map(terrUnits, 'key'),
        statusType: _.mapValues(_.pick($scope.publicationForm, ['statusType']), '$viewValue').statusType,
        taxonomicGroups: _.map(_.mapValues(_.pick($scope.publicationForm, ['txGroup']), '$viewValue').txGroup, 'key'),
      };

      //console.log(publicationFormData);

      publicationDetailsCtrl.onCreateFn()(publicationFormData, $scope.publicationForm);
    }

    function onSubmit() {
      publicationDetailsCtrl.onSubmitFn()($scope.publicationForm);
    }

    function isFormValid() {

      let isValid = (
        !!(publicationDetailsCtrl.publication.label
        && publicationDetailsCtrl.publication.citation
        && publicationDetailsCtrl.publication.year)
        || (!creationInProgress) ) && publicationDetailsCtrl.canEdit;

      return isValid;

      //return true;
    }

    function getTooltipContent(name) {

      return creationInProgress() && publicationDetailsCtrl.withTooltips ?  publicationDetailsCtrl.tooltips[name] : null;
    }
}
