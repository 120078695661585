import angular from 'angular'

import importContainer from './components/import-container/import-container.component';
import importFile from './components/import/import.component';
import fileChange from './components/file-change';
import remoteImportRepository from './repositories/import-file.remote.repository';
import importFileService from './services/import-file.service';
import importStore from './stores/import-file.store';
import routerConfig from './routes';
import importConfiguration from './configuration';
import utils from './utils';

const moduleName = 'shu-import';

angular.module(moduleName, ['lumx'])
  .directive('shuImport', importFile)
  .directive('shuImportContainer', importContainer)
  .directive('fileChange', fileChange)
  .factory('ImportFileService', importFileService)
  .factory('ImportFileStore', importStore)
  .factory('ImportRepository', remoteImportRepository)
  .constant('ImportConfiguration', importConfiguration)
  .constant('Utils', utils)
  .config(routerConfig);

export default { moduleName };
