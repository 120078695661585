import _ from 'lodash';

import { EVENTS as TX_GROUP_STORE_EVENTS } from '../../stores/txgroup-list.store';

require('./select-txgroup.scss');

export default SelectTxGroupDirective;

// @ngInject
function SelectTxGroupDirective() {
  return {
    restrict: 'E',
    scope: {
      placeholder: '@',
      initialValue: '@',
      availableValuesProvider: '&availableValues',
      onChangeFn: '&onChange',
      disabledProvider: '&disabled',
      multipleProvider: '&multiple',
      name: '@',
      requiredProvider: '&required',
      canEditProvider: '&canEdit'
    },
    bindToController: true,
    controller: SelectTxGroupController,
    controllerAs: 'selectTxGroupCtrl',
    template: require('./select-txgroup.html'),
    replace: true
  };
}

// @ngInject
function SelectTxGroupController($scope, TxGroupService, TxGroupListStore) {
  var selectTxGroupCtrl = this;

  selectTxGroupCtrl.availableValues = [];

  selectTxGroupCtrl.onSelect = onSelect;
  selectTxGroupCtrl.onChange = onChange;

  $scope.$watch(selectTxGroupCtrl.availableValuesProvider, (availableValues) => {
    selectTxGroupCtrl.availableValues = availableValues;
  });
  $scope.$watch(selectTxGroupCtrl.disabledProvider, (disabled) => {
    selectTxGroupCtrl.disabled = disabled;
  });
  $scope.$watch(selectTxGroupCtrl.multipleProvider, (multiple) => {
    selectTxGroupCtrl.multiple = multiple;
  });
  $scope.$watch(selectTxGroupCtrl.requiredProvider, (required) => {
    selectTxGroupCtrl.required = required;
  });
  $scope.$watch(() => selectTxGroupCtrl.initialValue, (initialValue) => {
    selectTxGroupCtrl.currentValue = initialValue;
  });

  $scope.$watch(selectTxGroupCtrl.canEditProvider, (canEdit) => {
    selectTxGroupCtrl.canEdit = canEdit;
  });

  function onSelect() {
    if (selectTxGroupCtrl.currentValue == selectTxGroupCtrl.oldValue) {
      selectTxGroupCtrl.oldValue = null;
      selectTxGroupCtrl.currentValue = null;
    } else {
      selectTxGroupCtrl.oldValue = selectTxGroupCtrl.currentValue;
    }
    onChange(selectTxGroupCtrl.currentValue);
  }

  function onChange(selectedValue) {
    selectTxGroupCtrl.onChangeFn() && selectTxGroupCtrl.onChangeFn()(selectedValue);
  }

  $scope.$on(TX_GROUP_STORE_EVENTS.TX_GROUP_LIST_RESULTS_STATE_CHANGED, reloadResults);

  TxGroupService.getTxGroups();

  function reloadResults() {
    selectTxGroupCtrl.state = TxGroupListStore.getState();

    if (!selectTxGroupCtrl.state.results) {
      return;
    }

    selectTxGroupCtrl.availableValues = [];

    angular.forEach(_.reject(selectTxGroupCtrl.state.results, 'deleted'), function(x) {
      selectTxGroupCtrl.availableValues.push(x.asMutable());
    })

    //updateInitialValue();
  }
}
