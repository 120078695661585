import configuration from '../configuration';

const STORAGE_KEY = 'shu-settings.settings';

export default SettingsRepository;

// @ngInject
function SettingsRepository($q, $http) {
  return {get, store};

  function get() {
    if (!window.localStorage) {
      return getRemotely();
    }

    var serializedSettings = window.localStorage.getItem(STORAGE_KEY);
    if (serializedSettings) {
      return $q.when(JSON.parse(serializedSettings));
    }

    return getRemotely();
  }

  function getRemotely() {
    return $http({
      method: 'GET',
      url: configuration.settingsEndpoint
    }).then(
      (response) => store(response.data),
      () => null
    );
  }

  function store(settings) {
    window.localStorage && window.localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
    return $q.when(settings);
  }
}
