export default BaseRouterConfig;

// @ngInject
function BaseRouterConfig($stateProvider) {
  $stateProvider.state('home', {
    url: '/',
    template: '<shu-home></shu-home>',
    needAuthenticatedUser: true,
    ignoreAuthenticatedUser: false
  });
}
