import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/section.service';

export default SectionConsolidateStore;

const EVENTS = {
  SECTION_CONSOLIDATE_STATE_CHANGED: 'shu-section-consolidate.section-consolidate-state-changed'
};

// @ngInject
function SectionConsolidateStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.SECTION_CONSOLIDATE_PROCESSING, onProcessing);
  $rootScope.$on(SERVICE_EVENTS.SECTION_CONSOLIDATE_PROCESS_SUCCESS, onProcessSuccess);
  $rootScope.$on(SERVICE_EVENTS.SECTION_CONSOLIDATE_PROCESS_ERROR, onProcessError);

  return {getState};

  function getState() {
    return state;
  }

  function onProcessing() {
    state = Immutable({
      section: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SECTION_CONSOLIDATE_STATE_CHANGED);
  }

  function onProcessSuccess(event, section) {
    state = Immutable({
      section,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SECTION_CONSOLIDATE_STATE_CHANGED);
  }

  function onProcessError() {
    state = Immutable({
      section: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.SECTION_CONSOLIDATE_STATE_CHANGED);
  }

}

export { EVENTS };
