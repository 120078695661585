import * as models from '@biotope/shuriken-status-api'
import { ModerationApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';

export const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'un section
  MODERATION_PENDING_SECTION_LOADING: 'shu-moderation.moderation-pending-section-loading',
  MODERATION_PENDING_SECTION_LOAD_SUCCESS: 'shu-moderation.moderation-pending-section-load-success',
  MODERATION_PENDING_SECTION_LOAD_ERROR: 'shu-moderation.moderation-pending-section-load-error',
  MODERATION_PENDING_PUBLICATION_LOADING: 'shu-moderation.moderation-pending-publication-loading',
  MODERATION_PENDING_PUBLICATION_LOAD_SUCCESS: 'shu-moderation.moderation-pending-publication-load-success',
  MODERATION_PENDING_PUBLICATION_LOAD_ERROR: 'shu-moderation.moderation-pending-publication-load-error',
}

export class ModerationService {

    private moderationApi: ModerationApi;
    private toaster: any;
    private $rootScope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, ModerationApi: ModerationApi) {
      this.moderationApi = ModerationApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
    }

    public getPendingSectionsList () {
        this.$rootScope.$emit(EVENTS.MODERATION_PENDING_SECTION_LOADING);

        return this.moderationApi.getModeratorSectionsList()
        .then(
            (response) => {
            this.$rootScope.$emit(EVENTS.MODERATION_PENDING_SECTION_LOAD_SUCCESS, response.data);
          }, (error: any) => {
            //console.log(error);
            this.toaster.error('Une erreur est survenue au chargement des informations de modération des sections. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.MODERATION_PENDING_SECTION_LOAD_ERROR, error);
          });
    }

    public getModeratorPublicationsList () {
        this.$rootScope.$emit(EVENTS.MODERATION_PENDING_PUBLICATION_LOADING);

        return this.moderationApi.getModeratorPublicationsList()
        .then(
            (response) => {
            this.$rootScope.$emit(EVENTS.MODERATION_PENDING_PUBLICATION_LOAD_SUCCESS, response.data);
          }, (error: any) => {
            //console.log(error);
            this.toaster.error('Une erreur est survenue au chargement des informations de modération des publications. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.MODERATION_PENDING_PUBLICATION_LOAD_ERROR, error);
          });
    }

}
