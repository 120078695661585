export default StatusValueRouterConfig;

// @ngInject
function StatusValueRouterConfig($stateProvider) {
  $stateProvider.state('statusvalue', {
    url: '/statusvalue',
    template: '<shu-statusvalue-dummy-container></shu-statusvalue-dummy-container>',
    needAuthenticatedUser: false
  });
}

// @ngInject
function StatusValueRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.statusvalueId = $stateParams.statusvalueId;
}