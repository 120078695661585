import { EVENTS as PUBLICATION_STORE_EVENTS } from '../../stores/publication-details.store';
import { EVENTS as PUBLICATION_SERVICE_STORE_EVENTS } from '../../stores/publication-service.store';
import { EVENTS as PROFILE_STORE_EVENTS } from '../../../profile/stores/profile.store';

import * as models from '@biotope/shuriken-status-api'

require('./publication-details-container.scss');

export default PublicationDetailsContainerDirective;

import { PublicationService } from '../../services/publication.service';

// @ngInject
function PublicationDetailsContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      publicationId: '@'
    },
    bindToController: true,
    controller: PublicationDetailsContainerController,
    controllerAs: 'publicationDetailsContainerCtrl',
    template: require('./publication-details-container.html'),
    replace: true
  };
}

/** @ngInject */
function PublicationDetailsContainerController($scope, $state, HeaderService, LxNotificationService,
                  PublicationDetailsStore, PublicationDetailsService, PublicationService: PublicationService, ProfileStore) {

    var publicationDetailsContainerCtrl = this;
    publicationDetailsContainerCtrl.publicationState = {};
    publicationDetailsContainerCtrl.profileState = ProfileStore.get();
    publicationDetailsContainerCtrl.headerUpdated = false;

    publicationDetailsContainerCtrl.isLoaded = isLoaded;
    publicationDetailsContainerCtrl.isProcessing = isProcessing;

    publicationDetailsContainerCtrl.onMainTabChanged = onMainTabChanged;

    publicationDetailsContainerCtrl.onSubmit = onSubmit;

    $scope.$on(PUBLICATION_STORE_EVENTS.PUBLICATION_DETAILS_STATE_CHANGED, reload);
    $scope.$on(PUBLICATION_SERVICE_STORE_EVENTS.PUBLICATION_UPDATED, redirectToOverview);
    $scope.$on(PROFILE_STORE_EVENTS.PROFILE_STATE_CHANGED, onProfileStateChanged);

    loadPublication();

    function isLoaded() {
      return publicationDetailsContainerCtrl.publicationState && publicationDetailsContainerCtrl.publicationState.loaded;
    }

    function isProcessing() {
      return publicationDetailsContainerCtrl.publicationState && publicationDetailsContainerCtrl.publicationState.processing;
    }

    function reload() {
      publicationDetailsContainerCtrl.publicationState = PublicationDetailsStore.getState();
      if (publicationDetailsContainerCtrl.publicationState.error) {
        return;
      }
      updateHeader();

    }

    function redirectToOverview() {
      publicationDetailsContainerCtrl.publicationState = PublicationDetailsStore.getState();
      if (publicationDetailsContainerCtrl.publicationState.error) {
        return;
      }
      $state.go('publication-overview', {publicationId: publicationDetailsContainerCtrl.publicationState.publication.publication.id});
    }

    function updateHeader() {
      if (!publicationDetailsContainerCtrl.publicationState.publication || publicationDetailsContainerCtrl.headerUpdated) {
        return;
      }
      HeaderService.updateTitle([
        {
          label: publicationDetailsContainerCtrl.publicationState.publication.publication.label,
          link: {
            sref: 'publication-overview',
            params: {publicationId: publicationDetailsContainerCtrl.publicationId}
          }
        }
      ]);
      publicationDetailsContainerCtrl.headerUpdated = true;
    }

    function loadPublication() {
      PublicationDetailsService.load(publicationDetailsContainerCtrl.publicationId);
    }

    function onMainTabChanged(mainTabFormData) {
      publicationDetailsContainerCtrl.mainTabFormData = mainTabFormData;
    }

    function onSubmit(form) {
      PublicationService.updatePublication(
        publicationDetailsContainerCtrl.publicationId,
        publicationDetailsContainerCtrl.mainTabFormData.data,
        form,
      );
    }

    function onProfileStateChanged() {
      publicationDetailsContainerCtrl.profileState = ProfileStore.get();
    }
}
