import * as models from '@biotope/shuriken-status-api'
import { RefApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';

const EVENTS = {
  STATUS_VALUES_LOADING: 'SHU-STATUS_VALUES_LOADING',
  STATUS_VALUES_LOAD_SUCCESS: 'SHU-STATUS_VALUES_LOAD_SUCCESS',
  STATUS_VALUES_LOAD_ERROR: 'SHU-STATUS_VALUES_LOAD_ERROR',
}

export class DictionaryRefService {

    private refApi: RefApi;
    private toaster: any;
    private $rootScope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, RefApi: RefApi) {
      this.refApi = RefApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
    }

    public getStatusValuesForStatus (statusId: number) {

        this.$rootScope.$emit(EVENTS.STATUS_VALUES_LOADING);
        return this.refApi.getStatusValuesForStatus(statusId)
        .then(
            (response) => {
            this.$rootScope.$emit(EVENTS.STATUS_VALUES_LOAD_SUCCESS, _.defaults({}, response.data));
          }, (error: any) => {
            //console.log(error);
            this.toaster.error('Une erreur est survenue au chargement des valeurs de statuts. ' + (error.data || ''));
            this.$rootScope.$emit(EVENTS.STATUS_VALUES_LOAD_ERROR, error);
          });
    }
}

export { EVENTS };
