export default BaseRouterConfig;

// @ngInject
function BaseRouterConfig($stateProvider) {
  $stateProvider.state('profile', {
    url: '/profile',
    template: '<shu-profile></shu-profile>',
    needAuthenticatedUser: true,
    ignoreAuthenticatedUser: false
  });
}
