export default StudyRouterConfig;

// @ngInject
function StudyRouterConfig($stateProvider) {
  $stateProvider.state('study', {
    url: '/study',
    template: '<shu-study-dummy-container></shu-study-dummy-container>',
    needAuthenticatedUser: false
  });
}

// @ngInject
function StudyRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
}