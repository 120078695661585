const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'un section
  SECTION_DETAILS_LOADING: 'shu-section-details.section-details-loading',
  SECTION_DETAILS_PROCESSING: 'shu-section-details.section-details-processing',
  SECTION_DETAILS_PROCESS_DONE: 'shu-section-details.section-details-process-done',
  SECTION_DETAILS_LOAD_SUCCESS: 'shu-section-details.section-details-load-success',
  SECTION_DETAILS_LOAD_ERROR: 'shu-section-details.section-details-load-error'
}

export default SectionDetailsService;

// @ngInject
function SectionDetailsService($http, $rootScope, SectionRepository, LxNotificationService) {
  return { load, updateSection, deleteSection, createSection };

  function load(publicationId, sectionId) {
    $rootScope.$emit(EVENTS.SECTION_DETAILS_LOADING);

    return SectionRepository.getSection(publicationId, sectionId)
      .then((section) => {
        $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, section);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des détails de la section. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_ERROR, error);
      });
  }

  function updateSection(sectionId, sectionForm, formController) {
    $rootScope.$emit(EVENTS.SECTION_DETAILS_PROCESSING);

    SectionRepository.updateSection(sectionId, sectionForm)
      .then(
        (section) => {
          formController.$setPristine();
          LxNotificationService.success('Section enregistré');
          $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, section);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la sauvegarde du section. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.SECTION_DETAILS_DETAILS_LOAD_ERROR);
        }
      );

    /*return SectionRepository.updateSection(sectionId)
      .then((section) => {
        $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, section);
      }, (error) => {
        LxNotificationService.error('Une erreur ***. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_ERROR, error);
      });*/
  }

  function createSection(sectionForm) {
    $rootScope.$emit(EVENTS.SECTION_DETAILS_PROCESSING);

    SectionRepository.createSection(sectionForm)
      .then(
        (section) => {
          //formController.$setPristine();
          LxNotificationService.success('Demande de création enregistrée');
          $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, section);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la création du section. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.SECTION_DETAILS_DETAILS_LOAD_ERROR);
        }
      );

    /*return SectionRepository.updateSection(sectionId)
      .then((section) => {
        $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, section);
      }, (error) => {
        LxNotificationService.error('Une erreur ***. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_ERROR, error);
      });*/
  }

  function deleteSection(sectionId) {
    $rootScope.$emit(EVENTS.SECTION_DETAILS_PROCESSING);

    SectionRepository.deleteSection(sectionId)
      .then(
        (section) => {
          LxNotificationService.success('Section supprimé');
          $rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, section);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la suppression du section. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.SECTION_DETAILS_DETAILS_LOAD_ERROR);
        }
      );
  }

}

export { EVENTS };
