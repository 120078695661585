const EVENTS = {
  PUBLICATION_STATUTES_LOADING: 'shu-publication-statutes.publication-statutes-results-loading',
  PUBLICATION_STATUTES_LOAD_SUCCESS: 'shu-publication-statutes.publication-statutes-results-load-success',
  PUBLICATION_STATUTES_LOAD_ERROR: 'shu-publication-statutes.publication-statutes-results-load-error',

  PUBLICATION_STATUTES_METADATA_LOADING: 'shu-publication-statutes.publication-statutes-types-results-loading',
  PUBLICATION_STATUTES_METADATA_LOAD_SUCCESS: 'shu-publication-statutes.publication-statutes-types-results-load-success',
  PUBLICATION_STATUTES_METADATA_LOAD_ERROR: 'shu-publication-statutes.publication-statutes-types-results-load-error',
}

export default PublicationStatutesService;

// @ngInject
function PublicationStatutesService($http, $rootScope, PublicationRepository, LxNotificationService) {
  return { load, getPublicationStatutesTypes };

  function load(publicationId) {
    $rootScope.$emit(EVENTS.PUBLICATION_STATUTES_LOADING);

    return PublicationRepository.getPublicationStatutes(publicationId)
      .then((statutes) => {
        $rootScope.$emit(EVENTS.PUBLICATION_STATUTES_LOAD_SUCCESS, statutes);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des statuts de la publication. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PUBLICATION_STATUTES_LOAD_ERROR, error);
      });
  }

  function getPublicationStatutesTypes(publicationId) {
    $rootScope.$emit(EVENTS.PUBLICATION_STATUTES_METADATA_LOADING);

    return PublicationRepository.getPublicationStatutesTypes(publicationId)
      .then((statutesTypes) => {
        $rootScope.$emit(EVENTS.PUBLICATION_STATUTES_METADATA_LOAD_SUCCESS, statutesTypes);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des types de statuts de la publication. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PUBLICATION_STATUTES_METADATA_LOAD_ERROR, error);
      });
  }

}

export { EVENTS };
