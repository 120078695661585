import { ConsolidationService } from "../../services/consolidation.service"

import { EVENTS as CONSOLIDATION_STORE_EVENTS } from '../../stores/consolidation.store';

/*const EVENTS = {
  CONSOLIDATION_FILE_UPLOADED: 'shu-consolidation-tool.consolidation-file-uploaded'
};*/

require('./consolidation-tool-container.scss');

export default ConsolidationToolContainerDirective;

// @ngInject
function ConsolidationToolContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      action: "@"
    },
    bindToController: true,
    controller: ConsolidationToolContainerController,
    controllerAs: 'consolidationToolContainerCtrl',
    template: require('./consolidation-tool-container.html'),
    replace: true
  };
}

// @ngInject
function ConsolidationToolContainerController($scope, $rootScope, $state, ConsolidationService: ConsolidationService, ConsolidationStore, HeaderService) {
  var consolidationToolContainerCtrl = this;

  consolidationToolContainerCtrl.fileInfo = null;
  consolidationToolContainerCtrl.refInfo = null;

  consolidationToolContainerCtrl.onFileUpload = onFileUpload;
  consolidationToolContainerCtrl.onRefSelect = onRefSelect;

  consolidationToolContainerCtrl.headerUpdated = false;
  consolidationToolContainerCtrl.fileIsUploaded = false;
  consolidationToolContainerCtrl.resultsAvalailables = false;

  consolidationToolContainerCtrl.consolidationState = {};

  $scope.$on(CONSOLIDATION_STORE_EVENTS.TAXA_FILE_CONSOLIDATION_STATE_CHANGED, reloadState);

  updateHeader({'label': 'Choix d\'un fichier'});

  function onFileUpload(fileInfo){
    if (fileInfo != null) {
      consolidationToolContainerCtrl.fileInfo = fileInfo;
      consolidationToolContainerCtrl.fileIsUploaded = true;

      updateHeader({'label': 'Sélectionner un référentiel'});
    }
  }

  function onRefSelect(refInfo){
    if (refInfo != null) {
      consolidationToolContainerCtrl.refInfo = JSON.parse(refInfo);
      //console.log(consolidationToolContainerCtrl.refInfo);
      launchConsolidation();
    }
  }

  function launchConsolidation(){
    ConsolidationService.uploadFileToConsolidation(consolidationToolContainerCtrl.refInfo, consolidationToolContainerCtrl.fileInfo.file);
  }

  function reloadState() {

    consolidationToolContainerCtrl.consolidationState = ConsolidationStore.getState();

    if(consolidationToolContainerCtrl.consolidationState.error) {
      $state.reload();
    }
    consolidationToolContainerCtrl.resultsAvalailables = true;

    if(consolidationToolContainerCtrl.consolidationState.consolidatedRecords != null) {

      consolidationToolContainerCtrl.consolidatedRecords = consolidationToolContainerCtrl.consolidationState.consolidatedRecords;
      updateHeader({'label': 'Consolider les taxons'});
    }
  }

  function updateHeader(label) {

    let defaultLabel = [{
      label: 'Outil de consolidation',
      link: {
        sref: 'consolidation-tool',
        params: {action: "refresh"}
      }
    }];

    let labels = defaultLabel;

    if (label != null) {
      let labels = defaultLabel.push(label)
    }

    HeaderService.updateTitle(labels);

    consolidationToolContainerCtrl.headerUpdated = true;

  }
}
