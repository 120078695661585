
const PUBLICATION_TYPES = {
  VALUES: [
    {
      "key": 'REGULATION',
      "name": 'Réglementation',
      "selected": true
    },
    {
      "key": 'OTHERS',
      "name": 'Rareté, Menace...',
      "selected": true
    },
    {
      "key": 'TAXA_ECOLOGY',
      "name": 'Écologie des taxons',
      "selected": true
    },
    {
      "key": 'EEE',
      "name": 'Espèces exotiques envahissantes',
      "selected": true
    }
  ]
};

const TX_GROUPS = {
  VALUES: [
    {key: 'PLANTS', name: "Flore"},
    {key: 'FUNGI', name: "Champignons"},
    {key: 'MOLLUSCS', name: "Mollusques"},
    {key: 'ARACHNIDS', name: "Arachnides"},
    {key: 'INSECTS_ODO', name: "Insectes Odonates"},
    {key: 'INSECTS_ORTHO', name: "Insectes Orthoptères"},
    {key: 'INSECTS_RHO', name: "Insectes Rhopalocères"},
    {key: 'INSECTS_OTHER', name: "Insectes autres"},
    {key: 'CRUSTACEANS', name: "Crustacés"},
    {key: 'FISHES', name: "Poissons"},
    {key: 'AMPHIBIA', name: "Amphibiens"},
    {key: 'REPTILES', name: "Reptiles"},
    {key: 'BIRDS', name: "Oiseaux"},
    {key: 'MAMMALS', name: "Mammifères terrestres"},
    {key: 'BATS', name: "Chiroptères"},
    {key: 'OTHERS', name: "Autres groupes"},
  ]
};

export { PUBLICATION_TYPES, TX_GROUPS };
