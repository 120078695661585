import profileComponent from './components/profile/profile.component';
import profileStore from './stores/profile.store';
import profileService from './services/profile.service';
import localProfileRepository from './repositories/profile.local.repository';
import remoteProfileRepository from './repositories/profile.remote.repository';
import routerConfig from './routes';
import profileConfiguration from './configuration';

require('./profile.scss');

const moduleName = 'shu-profile';

angular.module(moduleName, ['ui.router', 'lumx'])
  .directive('shuProfile', profileComponent)
  .factory('ProfileStore', profileStore)
  .factory('ProfileService', profileService)
  .factory('ProfileRepository', IS_CORDOVA ? localProfileRepository : remoteProfileRepository)
  .constant('ProfileConfiguration', profileConfiguration)
  .config(routerConfig);

export default {moduleName};
