import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/consolidation.service';
//import { EVENTS as CONTAINER_EVENTS } from '../components/consolidation-tool-container/consolidation-tool-container.component';


export default ConsolidationStore;

const EVENTS = {
  TAXA_FILE_CONSOLIDATION_STATE_CHANGED: 'shu-consolidation.taxa-file-consolidation-state-changed'
};

// @ngInject
function ConsolidationStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TAXA_FILE_CONSOLIDATION_PROCESSING, onProcessing);
  $rootScope.$on(SERVICE_EVENTS.TAXA_FILE_CONSOLIDATION_PROCESS_DONE, onProcessSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXA_FILE_CONSOLIDATION_PROCESS_ERROR, onProcessError);
  //$rootScope.$on(CONTAINER_EVENTS.CONSOLIDATION_FILE_UPLOADED, onFileUploaded);

  return {getState};

  function getState() {
    return state;
  }

  /*function onFileUploaded() {
    state = Immutable({
      consolidation: null,
      fileUploaded: true,
      processing: false,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXA_FILE_CONSOLIDATION_STATE_CHANGED);
  }*/

  function onProcessing() {
    state = Immutable({
      consolidation: null,
    //  fileUploaded: true,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXA_FILE_CONSOLIDATION_STATE_CHANGED);
  }

  function onProcessSuccess(event, consolidatedRecords) {
    state = Immutable({
      consolidatedRecords,
    //  fileUploaded: true,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXA_FILE_CONSOLIDATION_STATE_CHANGED);
  }

  function onProcessError() {
    state = Immutable({
      consolidatedRecords: null,
      //fileUploaded: false,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXA_FILE_CONSOLIDATION_STATE_CHANGED);
  }

}

export { EVENTS };
