import _ from 'lodash';

import { EVENTS as SERVICE_EVENTS } from '../services/status-type.service';

export default StatusTypeKeyFilter;

// @ngInject
function StatusTypeKeyFilter($rootScope, $async, StatusTypeService) {

  var statusTypeKeyFilterCtrl = this;
  statusTypeKeyFilterCtrl.statusType = new Map();
  statusTypeKeyFilterCtrl.inputs = [];
  statusTypeKeyFilterCtrl.input = null;

  //@see : https://glebbahmutov.com/blog/async-angular-filter/

  const getFilterValue = $async(function*(input) {
      let response = yield StatusTypeService.getStatusTypeById(input);
      statusTypeKeyFilterCtrl.statusType.set(input, response);

  });

  function filter(input) {
    if(input  === undefined)
      return input;

    if(_.indexOf(statusTypeKeyFilterCtrl.inputs, input) == -1) {
      getFilterValue(input);
      statusTypeKeyFilterCtrl.inputs.push(input);
    }

    let filteredValue = statusTypeKeyFilterCtrl.statusType.get(input) === undefined ? input :  statusTypeKeyFilterCtrl.statusType.get(input);

    //console.log(input +" "+filteredValue);
    return filteredValue
  }

  filter.$stateful = true;
  return filter;
}
