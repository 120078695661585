import { EVENTS as PUBLICATION_STORE_EVENTS } from '../../../publication/stores/publication-service.store';

require('./publication-user-list.scss');

export default PublicationUserListDirective;

import * as models from '@biotope/shuriken-status-api'

import _ from 'lodash';

import { PublicationService } from '../../services/publication.service';

// @ngInject
function PublicationUserListDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    bindToController: true,
    controller: PublicationUserListController,
    controllerAs: 'publicationUserListCtrl',
    template: require('./publication-user-list.html'),
    replace: true
  };
}

// @ngInject
function PublicationUserListController($scope, $state, PublicationServiceStore, PublicationSearchService, PublicationService: PublicationService) {
  var publicationUserListCtrl = this;

  publicationUserListCtrl.publicationState = {};

  publicationUserListCtrl.publicationSearchResults = []

  publicationUserListCtrl.isLoading = true;

  publicationUserListCtrl.reloadPublicationSearchResults = reloadPublicationSearchResults;

  publicationUserListCtrl.onPublicationSelected = onPublicationSelected;


  $scope.$on(PUBLICATION_STORE_EVENTS.PUBLICATION_SERVICE_STATE_CHANGED, reloadPublicationSearchResults);

  PublicationService.getUserPublicationsList();

  function reloadPublicationSearchResults() {

    publicationUserListCtrl.publicationState = PublicationServiceStore.getState();
    publicationUserListCtrl.publicationSearchResults = publicationUserListCtrl.publicationState.publication;
    publicationUserListCtrl.isLoading = false;
  }

  function onPublicationSelected(publicationId) {
    $state.go('publication-overview', {publicationId});
  }

}
