export default SettingsRouterConfig;

// @ngInject
function SettingsRouterConfig($stateProvider) {
  $stateProvider.state('settings', {
    url: '/settings',
    template: '<shu-settings-container></shu-settings-container>',
    needAuthenticatedUser: false
  });
}
