export default SectionRouterConfig;



// @ngInject
function SectionRouterConfig($stateProvider) {
  $stateProvider.state('section-details', {
    url: '/publication/{publicationId}/section/{sectionId}',
    template: '<shu-section-details-container publication-id="{{ ::routeCtrl.publicationId }}" section-id="{{ ::routeCtrl.sectionId }}"></shu-section-details-container>',
    needAuthenticatedUser: true,
    controller: SectionRouteController,
    controllerAs: 'routeCtrl'
  }).state('section-new', {
    url: '/publication/{publicationId}/section',
    template: '<shu-section-new-container publication-id="{{ ::routeCtrl.publicationId }}"></shu-section-new-container>',
    needAuthenticatedUser: true,
    controller: SectionNewRouteController,
    controllerAs: 'routeCtrl'
  });
}

// @ngInject
function SectionNewRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.publicationId = $stateParams.publicationId;
}

// @ngInject
function SectionRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.publicationId = $stateParams.publicationId;
  routeCtrl.sectionId = $stateParams.sectionId;
}
