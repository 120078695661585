import angular from 'angular';

import terrUnitSelect from './components/terr-unit-select/terr-unit-select.component';
//import terrUnitRepository from './repositories/terr-unit.repository';
import terrUnitListStore from './stores/terr-unit-list.store';
//import terrUnitListService from './services/terr-unit-list.service';
import { TerrUnitService } from './services/terr-unit.service';
import terrUnitKeyFilter from './filters/terr-unit-key.filter';
import routerConfig from './routes';
import terrUnitConfiguration from './configuration';

const moduleName = 'shu-terrunit';

angular.module(moduleName, ['lumx'])
  .directive('shuTerrUnitSelect', terrUnitSelect)
  .service('TerrUnitService', TerrUnitService)
  .factory('TerrUnitListStore', terrUnitListStore)
  //.factory('TerrUnitListService', terrUnitListService)
  //.factory('TerrUnitRepository', terrUnitRepository)
  .filter('terrUnitKeyLabel', terrUnitKeyFilter)
  .constant('TerrUnitConfiguration', terrUnitConfiguration)
  .config(routerConfig);

export default { moduleName };
