import { EVENTS as PUBLICATION_STORE_EVENTS } from '../../../publication/stores/publication-service.store';

import * as models from '@biotope/shuriken-status-api'

require('./publication-new-container.scss');

export default PublicationNewContainerDirective;

// @ngInject
function PublicationNewContainerDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    bindToController: true,
    controller: PublicationNewContainerController,
    controllerAs: 'publicationNewContainerCtrl',
    template: require('./publication-new-container.html'),
    replace: true
  };
}

// @ngInject
function PublicationNewContainerController($scope, $state, PublicationService) {
  var publicationNewContainerCtrl = this;

  $scope.$on(PUBLICATION_STORE_EVENTS.PUBLICATION_CREATED, redirectToMyPublications);


  publicationNewContainerCtrl.onChanged = onChanged;
  publicationNewContainerCtrl.onCreate = onCreate;

  function onChanged() {

  }

  function onCreate(publicationFormData: models.PublicationForm, form) {
    PublicationService.createPublication(
      publicationFormData,
      form
    );
  }

  function redirectToMyPublications() {
    //sectionNewContainerCtrl.publicationState = PublicationDetailsStore.getState();

    $state.go('my-publications',
      {
      });
  }

}
