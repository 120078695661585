import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/section.service';

export default SectionDetailsStore;

const EVENTS = {
  SECTION_DETAILS_STATE_CHANGED: 'shu-section-details.section-details-state-changed',
  SECTION_CREATED: 'shu-section-details.section-created'
};

// @ngInject
function SectionDetailsStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.SECTION_DETAILS_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.SECTION_DETAILS_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.SECTION_DETAILS_LOAD_ERROR, onLoadError);
  $rootScope.$on(SERVICE_EVENTS.SECTION_CREATION_PROCESS_DONE, onCreateSuccess);
  $rootScope.$on(SERVICE_EVENTS.SECTION_DELETION_DONE, onDeleteSuccess);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      section: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SECTION_DETAILS_STATE_CHANGED);
  }

  function onLoadSuccess(event, section) {
    state = Immutable({
      section,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SECTION_DETAILS_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      section: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.SECTION_DETAILS_STATE_CHANGED);
  }

  function onCreateSuccess() {
    $rootScope.$broadcast(EVENTS.SECTION_CREATED);
  }

  function onDeleteSuccess() {
    $rootScope.$broadcast(EVENTS.SECTION_DELETED);
  }
}

export { EVENTS };
