import { EVENTS as STORE_EVENTS } from '../../stores/profile.store';

require('./profile.scss');

export default ProfileDirective;

// @ngInject
function ProfileDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: ProfileController,
    controllerAs: 'profileCtrl',
    template: require('./profile.html'),
    replace: true
  };
}

// @ngInject
function ProfileController($scope, ProfileStore, HeaderService, ProfileService) {
  var profileCtrl = this;

  profileCtrl.state = {};
  profileCtrl.data = {};

  $scope.$on(STORE_EVENTS.PROFILE_STATE_CHANGED, updateProfile);
  reloadProfile();

  init();

  function init() {
    HeaderService.updateTitle([
      'Profil utilisateur'
    ]);
    profileCtrl.state = ProfileStore.get();
  }

  function updateProfile() {
    profileCtrl.state = ProfileStore.get();

  }

  function reloadProfile() {
    ProfileService.load();


  }
}
