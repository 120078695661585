import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/expert-search.service';

const EVENTS = {
  EXPERT_SEARCH_QUERY_STATE_CHANGED: 'shu-expert-search.expert-search-query-state-changed',
  EXPERT_SEARCH_RESULTS_STATE_CHANGED: 'shu-expert-search.expert-search-results-state-changed'
};

export default ExpertSearchStore;

// @ngInject
function ExpertSearchStore($rootScope, ExpertSearchService) {
  var queryState = Immutable({});
  var resultsState = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.EXPERT_SEARCH_RESULTS_LOADING, onExpertSearchResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.EXPERT_SEARCH_RESULTS_LOAD_SUCCESS, onExpertSearchResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.EXPERT_SEARCH_RESULTS_LOAD_ERROR, onExpertSearchResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.EXPERT_SEARCH_QUERY_UPDATE, onUpdateExpertSearchQuery);

  return {getQueryState, getResultsState};

  function getQueryState() {
    return queryState;
  }

  function getResultsState() {
    return resultsState;
  }

  function onUpdateExpertSearchQuery(events, {query}) {
    queryState = Immutable({query});
    $rootScope.$broadcast(EVENTS.EXPERT_SEARCH_QUERY_STATE_CHANGED);
  }

  function onExpertSearchResultsLoading() {
    resultsState = Immutable({
      results: [],
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.EXPERT_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onExpertSearchResultsLoadSuccess(event, results) {
    resultsState = Immutable({
      results,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.EXPERT_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onExpertSearchResultsLoadError() {
    resultsState = Immutable({
      results: [],
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.EXPERT_SEARCH_RESULTS_STATE_CHANGED);
  }

}

export { EVENTS };
