const EVENTS = {
  // Actions lancées quand l'utilisateur charge les protocoles
  STATUS_TYPE_LIST_RESULTS_LOADING: 'shu-status-type.status-type-list-results-loading',
  STATUS_TYPE_LIST_RESULTS_LOAD_SUCCESS: 'shu-status-type.status-type-list-results-load-success',
  STATUS_TYPE_LIST_RESULTS_LOAD_ERROR: 'shu-status-type.status-type-list-results-load-error',
};

export default StatusTypeListService;

// @ngInject
function StatusTypeListService($http, $rootScope, StatusTypeListStore, StatusTypeRepository, LxNotificationService) {
  return { load };

  function load() {
    if (StatusTypeListStore.getState().loaded) {
      $rootScope.$emit(EVENTS.STATUS_TYPE_LIST_RESULTS_LOAD_SUCCESS, StatusTypeListStore.getState().statusType);
      return;
    }

    $rootScope.$emit(EVENTS.STATUS_TYPE_LIST_RESULTS_LOADING);

    StatusTypeRepository.list()
      .then((result) => {

        _.remove(result, function (status_type) {
          return status_type.archived;
        });

        $rootScope.$emit(EVENTS.STATUS_TYPE_LIST_RESULTS_LOAD_SUCCESS, result);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des types de statuts. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.STATUS_TYPE_LIST_RESULTS_LOAD_ERROR);
      });
  }
}
export { EVENTS };
