import _ from 'lodash';

import { EVENTS as IMPORT_FILE_STORE_EVENTS } from '../../stores/import-file.store';
import { EVENTS as PUBLICATION_STORE_EVENTS } from '../../../publication/stores/publication-details.store';
require('./import-container.scss');

export default ImportContainerDirective;

// @ngInject
function ImportContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      publicationId: '@'
    },
    bindToController: true,
    controller: ImportContainerController,
    controllerAs: 'importContainerCtrl',
    template: require('./import-container.html'),
    replace: true
  };
}

// @ngInject
function ImportContainerController($scope, $state, LxNotificationService, ImportFileService, ImportFileStore,
  HeaderService, PublicationDetailsService, PublicationDetailsStore) {

  var importContainerCtrl = this;

  importContainerCtrl.filesState = {};
  importContainerCtrl.processingFiles = false;
  importContainerCtrl.headerUpdated = false;

  importContainerCtrl.onSubmitFile = ImportFileService.onUploadFile;
  importContainerCtrl.onRemoveFile = ImportFileService.onRemoveFile;
  importContainerCtrl.onConsolidationValidate = ImportFileService.onConsolidationValidate;
  importContainerCtrl.queryState = {};
  importContainerCtrl.resultsState = {};

  importContainerCtrl.onUpdateForm = ImportFileService.onUpdateForm;
  importContainerCtrl.onTaxonSelected = onTaxonSelected;
  importContainerCtrl.onNextResultsAction = onNextResultsAction;
  importContainerCtrl.showResults = showResults;

  importContainerCtrl.headerUpdated = false;

  $scope.$on(IMPORT_FILE_STORE_EVENTS.IMPORT_FILE_STATE_CHANGED, reloadFile);
  $scope.$on(IMPORT_FILE_STORE_EVENTS.TAXON_SEARCH_QUERY_STATE_CHANGED, reloadQuery);
  $scope.$on(IMPORT_FILE_STORE_EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED, reloadResults);
  $scope.$on(PUBLICATION_STORE_EVENTS.PUBLICATION_DETAILS_STATE_CHANGED, reload);


  PublicationDetailsService.load(importContainerCtrl.publicationId);

  function showResults() {
    return importContainerCtrl.resultsState.loaded || importContainerCtrl.resultsState.totalResults > 0;
  }

  function reloadQuery() {
    importContainerCtrl.queryState = ImportFileStore.getQueryState();
  }

  function reloadResults() {
    importContainerCtrl.resultsState = ImportFileStore.getResultsState();

  }

  function onNextResultsAction() {
    ImportFileService.loadNextResults(
      importContainerCtrl.queryState.query,
      importContainerCtrl.selectedReferentielType,
      importContainerCtrl.selectedReferentielVersion,
      importContainerCtrl.resultsState.results.length
    );
  }

  function onTaxonSelected(taxonKey, taxonName) {
    ImportFileService.onTaxonSelected(
      importContainerCtrl.queryState.selectedReferentiel.key,
      importContainerCtrl.queryState.selectedReferentiel.version,
      taxonKey,
      taxonName
    );
  }


  function reloadFile() {
    importContainerCtrl.filesState = ImportFileStore.getState();
    importContainerCtrl.headerUpdated = false;
    updateHeader2();

  }

  function reload(event) {
    importContainerCtrl.publicationState = PublicationDetailsStore.getState();

    if(!importContainerCtrl.publicationState.processing){
        updateHeader();
    }

  }

  function updateHeader() {
    if (!importContainerCtrl.publicationState.publication || importContainerCtrl.headerUpdated) {
      return;
    }

    HeaderService.updateTitle([
      {
        label: importContainerCtrl.publicationState.publication.publication.label,
        link: {
          sref: 'publication-overview',
          params: {publicationId: importContainerCtrl.publicationId}
        }
      }, {
        label: 'Import'
      }, {
        label: 'Charger un fichier et choisir un référentiel'
      }
    ]);
    importContainerCtrl.headerUpdated = true;

  }

  function updateHeader2() {
    if (!importContainerCtrl.publicationState.publication || importContainerCtrl.headerUpdated) {
      return;
    }

    HeaderService.updateTitle([
      {
        label: importContainerCtrl.publicationState.publication.publication.label,
        link: {
          sref: 'publication-overview',
          params: {publicationId: importContainerCtrl.publicationId}
        }
      }, {
        label: 'Import'
      }, {
        label: 'Consolidation'
      }
    ]);
    importContainerCtrl.headerUpdated = true;
  }

}
