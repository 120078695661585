import _ from 'lodash';

require('./publication-advanced-search-form.scss');

export default PublicationAdvancedSearchFormDirective;

// @ngInject
function PublicationAdvancedSearchFormDirective() {
  return {
    restrict: 'E',
    scope: {
      queryStateProvider: '&queryState',
      resultsTerrUnitStateProvider: '&resultsTerrUnitState',
      resultsStudyStateProvider: '&resultsStudyState',
      onUpdateFormFn: '&onUpdateForm',
      onUpdateTerrUnitFormFn: '&onUpdateTerrUnitForm',
      sendAdvancedFormFn: '&sendAdvancedForm',
      onUpdateStudyFormFn: '&onUpdateStudyForm'

    },
    bindToController: true,
    controller: PublicationAdvancedSearchFormController,
    controllerAs: 'publicationAdvancedSearchFormCtrl',
    template: require('./publication-advanced-search-form.html'),
    replace: true
  };
}

// @ngInject
function PublicationAdvancedSearchFormController($scope, $timeout, LxNotificationService) {
  var publicationAdvancedSearchFormCtrl = this;

  publicationAdvancedSearchFormCtrl.onsearchTypes = onsearchTypes;
  publicationAdvancedSearchFormCtrl.advancedSearch = advancedSearch;
  publicationAdvancedSearchFormCtrl.onQueryUpdated = onQueryUpdated;
  publicationAdvancedSearchFormCtrl.onTerrUnitChanged = onTerrUnitChanged;
  publicationAdvancedSearchFormCtrl.test = [];
  publicationAdvancedSearchFormCtrl.searchType = "keyWord";
  publicationAdvancedSearchFormCtrl.search = false;
  publicationAdvancedSearchFormCtrl.pushtxGroupsKey = pushtxGroupsKey;
  publicationAdvancedSearchFormCtrl.onStudyUpdated = onStudyUpdated;
  publicationAdvancedSearchFormCtrl.onStudySelected = onStudySelected;

  publicationAdvancedSearchFormCtrl.terrUnits = [];
  publicationAdvancedSearchFormCtrl.txGroupsKey = [];
  publicationAdvancedSearchFormCtrl.txGroups = [];


  $scope.$watch(publicationAdvancedSearchFormCtrl.resultsTerrUnitStateProvider, (resultsTerrUnitState) => {
    publicationAdvancedSearchFormCtrl.resultsTerr = resultsTerrUnitState.results;
    if (publicationAdvancedSearchFormCtrl.resultsTerr != undefined) {
      publicationAdvancedSearchFormCtrl.resultsTerr =publicationAdvancedSearchFormCtrl.resultsTerr.filter(x=>!x.name.startsWith('EAUX TERRITORIALES'));
    }

    publicationAdvancedSearchFormCtrl.loading = resultsTerrUnitState.loading;
  });


  $scope.$watch(publicationAdvancedSearchFormCtrl.resultsStudyStateProvider, (resultsStudyState) => {
    publicationAdvancedSearchFormCtrl.resultsStudy = resultsStudyState.results;
    if (publicationAdvancedSearchFormCtrl.resultsStudy == undefined) {
      publicationAdvancedSearchFormCtrl.studyLoading = true;
    }
    publicationAdvancedSearchFormCtrl.studyLoading = resultsStudyState.loading;
    //resultsStudyState.loading;
    //if(publicationAdvancedSearchFormCtrl.studyLoading == undefined){
    //  publicationAdvancedSearchFormCtrl.studyLoading = true;
    //}

  });

  $scope.$watch(publicationAdvancedSearchFormCtrl.queryStateProvider, (queryState) => {

    publicationAdvancedSearchFormCtrl.txGroups = [];
    //FIXME : HACK https://medium.com/@ckoster22/seamless-immutable-an-alternative-to-immutablejs-12795d6bf577

    angular.forEach(queryState.txGroups.asMutable(), function(txGroup) {
      publicationAdvancedSearchFormCtrl.txGroups.push(txGroup.asMutable());
      publicationAdvancedSearchFormCtrl.txGroup = publicationAdvancedSearchFormCtrl.txGroups;
    })

    publicationAdvancedSearchFormCtrl.types = [];
    angular.forEach(queryState.types.asMutable(), function(type) {
      publicationAdvancedSearchFormCtrl.types.push(type.asMutable());
    })

  });


  function onsearchTypes() {

    if (publicationAdvancedSearchFormCtrl.searchType == "area") {
      publicationAdvancedSearchFormCtrl.search = true;
    }

    if (publicationAdvancedSearchFormCtrl.searchType == "keyWord") {
      //empty();

      publicationAdvancedSearchFormCtrl.search = false;
    }

  }

  function onQueryUpdated() {
    publicationAdvancedSearchFormCtrl.onUpdateFormFn()(publicationAdvancedSearchFormCtrl.query, publicationAdvancedSearchFormCtrl.txGroups, publicationAdvancedSearchFormCtrl.types, publicationAdvancedSearchFormCtrl.terrUnits);
  }

  function onStudyUpdated(study) {
    if (!study) {
      return;
    }


    if (study.length > 3) {
      $timeout(function() {

        //publicationAdvancedSearchFormCtrl.terrUnit = terrUnit;
        publicationAdvancedSearchFormCtrl.onUpdateStudyFormFn()(study);
      }, 1000);

    }

  }

  function onTerrUnitChanged(newValues) {
    // extract properties as { 'key': x, "name": x, "level": x }
    publicationAdvancedSearchFormCtrl.terrUnits = _.map(newValues, _.partialRight(_.pick, ['key', 'name', 'level']));
  }

  function onStudySelected(id) {
    publicationAdvancedSearchFormCtrl.studyId = id;
  }

  function pushtxGroupsKey(value) {
    // extract properties in an array

    publicationAdvancedSearchFormCtrl.txGroupsKey = _.map(value, 'key');

  }

  function advancedSearch() {

    publicationAdvancedSearchFormCtrl.typesKey = _.map(_.filter(publicationAdvancedSearchFormCtrl.types, { 'selected': true }), 'key');
    pushtxGroupsKey(publicationAdvancedSearchFormCtrl.txGroup);

    if (publicationAdvancedSearchFormCtrl.studyId == undefined) {
      publicationAdvancedSearchFormCtrl.studyId = null;
    }

    let terrUnitKeys = _.map(publicationAdvancedSearchFormCtrl.terrUnits, 'key');

    publicationAdvancedSearchFormCtrl.advancedSearchJson = {
      "taxonomicGroups": publicationAdvancedSearchFormCtrl.txGroupsKey,
      "statusGroup": publicationAdvancedSearchFormCtrl.typesKey,
      //"terrUnit": publicationAdvancedSearchFormCtrl.terrUnits,
      "terrUnitKey": terrUnitKeys,
      "studyId": publicationAdvancedSearchFormCtrl.studyId
    };


    publicationAdvancedSearchFormCtrl.sendAdvancedFormFn()(publicationAdvancedSearchFormCtrl.advancedSearchJson);
  }

  publicationAdvancedSearchFormCtrl.selectModel = {}

  publicationAdvancedSearchFormCtrl.multipaneLoading = true;
  $scope.$on('lx-dropdown__open-end', function onDropdownOpenEnd() {
    $timeout(function delayEndLoading() {
      publicationAdvancedSearchFormCtrl.multipaneLoading = false;
    }, 1500);
  });


}
