import angular from 'angular';

import dictionaryStatusValueContainer from './components/dictionary-status-value-container/dictionary-status-value-container.component';
import dictionaryStatusValue from './components/dictionary-status-value/dictionary-status-value.component';
import { DictionaryRefService } from './services/dictionary-ref.service';
import dictionaryRefStore from './stores/dictionary-ref.store';
import routerConfig from './routes';

const moduleName = 'shu-dictionnary';

angular.module(moduleName, ['lumx'])
  .directive('shuDictionaryStatusValue', dictionaryStatusValue)
  .directive('shuDictionaryStatusValueContainer', dictionaryStatusValueContainer)
  .factory('DictionaryRefStore', dictionaryRefStore)
  .service('DictionaryRefService', DictionaryRefService)
  .config(routerConfig);

export default { moduleName };
