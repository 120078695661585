const STORAGE_KEY = 'shu-status-type-list.repository';

export default StatusTypeRepository;

// @ngInject
function StatusTypeRepository($http, StatusTypeConfiguration) {
  return { list };

  function list() {
    return $http({
      method: 'GET',
      url: StatusTypeConfiguration.listEndpoint
    }).then((response) => response.data);
  }

}
