import _ from 'lodash';

const EVENTS = {
  // Action lancée quand le titre de la page est modifié
  HEADER_UPDATE: 'shu-base.header-update'
};

export default HeaderService;

// @ngInject
function HeaderService($rootScope) {
  return {updateTitle};

  /**
   * @param {array} titles - Array de chaînes de caractères ou array d'objets contenant
   *                         les propriétés label pour le libellé et link si l'on
   *                         souhaite créer un lien depuis le libellé
   */
  function updateTitle(titles) {
    var normalizedTitles = _.map(titles, (title) => {
      // On veille à ce que chaque élément de la liste soit un objet
      if (_.isString(title)) {
        return {label: title};
      }
      return title;
    });
    $rootScope.$emit(EVENTS.HEADER_UPDATE, normalizedTitles);
  }
}
export { EVENTS };
