
require('./moderation-dashboard-container.scss');

export default ModerationDashboardContainerDirective;

// @ngInject
function ModerationDashboardContainerDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    bindToController: true,
    controller: ModerationDashboardContainerController,
    controllerAs: 'moderationDashboardContainerCtrl',
    template: require('./moderation-dashboard-container.html'),
    replace: true
  };
}

// @ngInject
function ModerationDashboardContainerController($scope) {
  var moderationDashboardContainerCtrl = this;

}
