import _ from 'lodash';

require('./consolidation-results.scss');

export default ConsolidationResultsDirective;

// @ngInject
function ConsolidationResultsDirective() {
  return {
    restrict: 'E',
    scope: {
      consolidatedRecords: "=",
      consolidationInProgress: "=",
      refProvider: "&ref"
    },
    bindToController: true,
    controller: ConsolidationResultsController,
    controllerAs: 'consolidationResultsCtrl',
    template: require('./consolidation-results.html'),
    replace: true
  };
}

// @ngInject
function ConsolidationResultsController($scope) {
  var consolidationResultsCtrl = this;

  consolidationResultsCtrl.includeConsolidated = true;

  consolidationResultsCtrl.filterTaxa = filterTaxa;
  consolidationResultsCtrl.downloadAllTaxa = downloadAllTaxa;
  consolidationResultsCtrl.onTaxonUpdated = onTaxonUpdated;

  consolidationResultsCtrl.copyOfConsolidatedRecords = []


  $scope.$watch(() => consolidationResultsCtrl.consolidatedRecords, () => {
    if (consolidationResultsCtrl.consolidatedRecords != null) {
      consolidationResultsCtrl.copyOfConsolidatedRecords = consolidationResultsCtrl.consolidatedRecords;
      //first, load statutes types
      loadPublicationStatutesTypes();
    }
  });

  $scope.$watch(consolidationResultsCtrl.refProvider, (ref) => {
    consolidationResultsCtrl.ref = ref;
  });

  function loadPublicationStatutesTypes() {
    console.log(consolidationResultsCtrl.consolidatedRecords);
  }

  function filterTaxa(taxa) {

    return (taxa.txKey != null && consolidationResultsCtrl.includeConsolidated)
          //taxa not consolidated will be always displayed
          || taxa.txKey == null;
  }

  function onTaxonUpdated(quotedValue, newValue) {

    consolidationResultsCtrl.copyOfConsolidatedRecords = _.map(consolidationResultsCtrl.copyOfConsolidatedRecords, function(item) {
        if(quotedValue === item) {
          return {
               "txRefType": quotedValue.txRefType,
               "txRefVersion": quotedValue.txRefVersion,
               "txQuotedName": quotedValue.txQuotedName,
               "txKey": newValue.key,
               "txName": newValue.name,
               "txVernacularName": newValue.vernacularName != null ? newValue.vernacularName[0] : ""/*,
               "txGroup": newValue.txGroup*/
            };
        }

        return item;
    });
  }

  var headers = {
      "txRefType": 'txRefType', // remove commas to avoid errors
      "txRefVersion": "txRefVersion",
      "txQuotedName": "txQuotedName",
      "txKey": "txKey",
      "txName": "txName",
      "txVernacularName": "txVernacularName"/*,
      "txGroup": "txGroup"*/
  };

  function downloadAllTaxa() {

    consolidationResultsCtrl.downloadConsolidatedRecords = [];

    _.forEach(consolidationResultsCtrl.copyOfConsolidatedRecords, function(x) {
      if(typeof x.asMutable == 'function' ) {
        consolidationResultsCtrl.downloadConsolidatedRecords.push(x.asMutable());
      } else {
        consolidationResultsCtrl.downloadConsolidatedRecords.push(x);
      }
    })

    var fileTitle = 'export'; // or 'my-unique-title'

    exportCSVFile(headers, consolidationResultsCtrl.downloadConsolidatedRecords, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download

  }

  function convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
              if (line != '') line += ';'
                line += array[i][index];
          }

          str += line + '\r\n';
      }

      return str;
  }

  function exportCSVFile(headers, items, fileTitle) {
      if (headers) {
          items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = convertToCSV(jsonObject);

      var exportedFilename = fileTitle + '.csv' || 'export.csv';

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
      } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      }
  }
}
