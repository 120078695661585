const PUBLICATION_EXPERT_DATA_IMPORT = {
  CSV: 'CSV',
  VALUES: [
    'CSV'
  ],
  DEFAULT: 'CSV'
};
const PUBLICATION_EXPERT_DATA_IMPORT_SOURCE = {
  LOCAL: 'LOCAL',
  SERVER: 'SERVER',
  VALUES: [
    'LOCAL',
    'SERVER'
  ],
  DEFAULT: 'SERVER'
};
export {  PUBLICATION_EXPERT_DATA_IMPORT,PUBLICATION_EXPERT_DATA_IMPORT_SOURCE };
