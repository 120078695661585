
require('./consolidation-select-ref.scss');

import { CONSOLIDATION_REF } from '../../model.js';

export default ConsolidationSelectRefDirective;

// @ngInject
function ConsolidationSelectRefDirective() {
  return {
    restrict: 'E',
    scope: {
      onRefSelectFn: '&onRefSelect'
    },
    bindToController: true,
    controller: ConsolidationSelectRefController,
    controllerAs: 'consolidationSelectRefCtrl',
    template: require('./consolidation-select-ref.html'),
    replace: true
  };
}

// @ngInject
function ConsolidationSelectRefController($scope) {
  var consolidationSelectRefCtrl = this;

  consolidationSelectRefCtrl.TAXREF = CONSOLIDATION_REF.TAXREF;
  consolidationSelectRefCtrl.COL = CONSOLIDATION_REF.COL;

  consolidationSelectRefCtrl.onSubmit = onSubmit;

  function onSubmit() {
    //FIXME Dirty hack !
    if(consolidationSelectRefCtrl.referentiel==null) {
      return;
    }

    consolidationSelectRefCtrl.onRefSelectFn()(consolidationSelectRefCtrl.referentiel);
  }

}
