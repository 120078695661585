import { SECTION_STATUS } from '../model';

export default SectionStatusFilter;

// @ngInject
function SectionStatusFilter() {
  return function(input) {
    switch (input) {
      case SECTION_STATUS.NEW:
        return 'Demande en attente de validation';
      case SECTION_STATUS.ARCHIVED:
        return 'Archivé';
      case SECTION_STATUS.CANCELED:
        return 'Demande d\'ajout rejetée';
      case SECTION_STATUS.EDITED:
        return 'Ouvert à la saisie';
      case SECTION_STATUS.PUBLISHED:
        return 'Publié';
      case SECTION_STATUS.READY:
        return 'En attente de validation pour publication';
      case SECTION_STATUS.PENDING_ARCHIVE:
          return 'Archivage demandé';
      default:
        return '---';
    }
  };
}
