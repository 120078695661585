export default OnBeforeUnloadDirective;

const ON_BEFORE_UNLOAD_MESSAGE = `
  Des données n'ont pas été enregistrées.
  Voulez-vous vraiment quitter ce formulaire sans sauvegarder vos données ?
`;

// @ngInject
function OnBeforeUnloadDirective($window, $state, LxNotificationService) {
  return {
    restrict: 'A',
    require: 'form',
    link: ($scope, element, attrs, FormController) => {
      $scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
        if (event.defaultPrevented) {
          return;
        }
        if (FormController.$pristine) {
          return;
        }

        event.preventDefault();

        LxNotificationService.confirm('Données non sauvegardées', ON_BEFORE_UNLOAD_MESSAGE, {
          ok: 'Oui',
          cancel: 'Non'
        }, (answer) => {
          if (answer) {
            // On force le formulaire à l'état pristine pour ne pas réafficher le formulaire
            FormController.$setPristine();
            $state.go(toState.name, toParams);
            return;
          }
        });
      });

      $window.onbeforeunload = (event) => {
        if (FormController.$pristine) {
          return;
        }

        let e = event || window.event;

        if (e) {
          e.returnValue = ON_BEFORE_UNLOAD_MESSAGE;
        }

        return ON_BEFORE_UNLOAD_MESSAGE;
      };

      $scope.$on('$destroy', () => {
        $window.onbeforeunload = null;
      });
    }
  };
}
