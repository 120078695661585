const SECTION_STATUS = {
  NEW: 'NEW',
  ARCHIVED: 'ARCHIVED',
  CANCELED: 'CANCELED',
  EDITED: 'EDITED',
  PUBLISHED: 'PUBLISHED',
  READY: 'READY',
  PENDING_ARCHIVE: 'PENDING_ARCHIVE',
  VALUES: [
    'NEW',
    'ARCHIVED',
    'CANCELED',
    'EDITED',
    'PUBLISHED',
    'READY',
    'PENDING_ARCHIVE'
  ],
  DEFAULT: 'NEW'
};


/*const STATUS = {
  LR: 'LR',
  ZNIEFF: 'ZNIEFF',
  VULNERABLE: 'VULNERABLE',
  VU: 'VU',
  RARE: 'RARE',
  SUBSP.: 'SUBSP.',
  VALUES: [
    'LR',
    'ZNIEFF',
    'VULNERABLE',
    'VU',
    'RARE',
    'SUBSP.'
  ],
  DEFAULT: 'LR'
};*/

export { SECTION_STATUS };
