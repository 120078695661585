
require('./publication-search-results.scss');

export default PublicationSearchResultsDirective;

// @ngInject
function PublicationSearchResultsDirective() {
  return {
    restrict: 'E',
    scope: {
      resultsStateProvider: '&resultsState',
      onNextResultsActionFn: '&onNextResultsAction'
    },
    bindToController: true,
    controller: PublicationSearchResultsController,
    controllerAs: 'publicationSearchResultsCtrl',
    template: require('./publication-search-results.html'),
    replace: true
  };
}

// @ngInject
function PublicationSearchResultsController($scope, PublicationSearchService) {
  var publicationSearchResultsCtrl = this;
  publicationSearchResultsCtrl.itemTest = [];
  publicationSearchResultsCtrl.hasMoreResults = hasMoreResults;
  publicationSearchResultsCtrl.onNextResultsAction = onNextResultsAction;
  publicationSearchResultsCtrl.filterPublicationInfos = filterPublicationInfos;
  publicationSearchResultsCtrl.getPublicationInfosCssClass = getPublicationInfosCssClass;
  publicationSearchResultsCtrl.results;
  publicationSearchResultsCtrl.showArchived = false;
  publicationSearchResultsCtrl.onPublicationSelected = PublicationSearchService.onPublicationSelected;

  $scope.$watch(publicationSearchResultsCtrl.resultsStateProvider, (resultsState) => {

    publicationSearchResultsCtrl.results = resultsState.results;
    publicationSearchResultsCtrl.totalResults = resultsState.totalResults;
    publicationSearchResultsCtrl.loading = resultsState.loading;
  });

  function filterPublicationInfos(publicationInfos) {
    return !isFullyArchived(publicationInfos) || publicationSearchResultsCtrl.showArchived;
  };

  function getArchivedSections(publicationInfos) {
    return _.map(publicationInfos.section, (section) => { return section.status === "ARCHIVED"; });
  }

  function getToBePublishedSections(publicationInfos) {

    return _.map(publicationInfos.section, (section) => {
        var sectionStatus = "";
        //
        if(section.status=== "EDITED" ||section.status=== "NEW"){
          sectionStatus = "READY"
        }
      return sectionStatus === "READY";
    });

  }

  function isFullyArchived(publicationInfos) {
    //if all section are archived then the publication is considered fully archived
    return getArchivedSections(publicationInfos).every(a => a === true);
  }

  function toBePublished(publicationInfos) {
    //if at least one section is waiting for publication
    return _.includes(getToBePublishedSections(publicationInfos), true);

  }

  function getPublicationInfosCssClass(publicationInfos) {

    return isFullyArchived(publicationInfos) ? "greyColor" : toBePublished(publicationInfos) ? "redColor" : "";
  };

  function onChange(){
    publicationSearchResultsCtrl.showArchived = true;
  }
  function hasMoreResults() {
    return publicationSearchResultsCtrl.totalResults > (publicationSearchResultsCtrl.results ? publicationSearchResultsCtrl.results.length : 0);
  }

  function onNextResultsAction() {
    publicationSearchResultsCtrl.onNextResultsActionFn()();
  }
}
