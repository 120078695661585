import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/header.service';

const EVENTS = {
  HEADER_STATE_CHANGED: 'shu-base.header-state-changed'
};
const DEFAULT_STATE = Immutable({titles: [{label: 'Gestion des statuts'}]});

export default HeaderStore;

// @ngInject
function HeaderStore($rootScope) {
  var state = DEFAULT_STATE;

  $rootScope.$on(SERVICE_EVENTS.HEADER_UPDATE, onUpdate);
  $rootScope.$on('$stateChangeSuccess', resetTitle);

  return {getState};

  function getState() {
    return state;
  }

  function onUpdate(event, titles) {
    state = Immutable({titles});
    $rootScope.$broadcast(EVENTS.HEADER_STATE_CHANGED);
  }

  function resetTitle() {
    state = DEFAULT_STATE;
    $rootScope.$broadcast(EVENTS.HEADER_STATE_CHANGED);
  }
}

export { EVENTS };
