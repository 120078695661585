import angular from 'angular';

import taxonSearchStore from './stores/taxon-search.store';
import taxonReferentielsRepository from './repositories/taxon.repository';
import taxonSearchService from './services/taxon-search.service';
import taxonSearchTaxon from './components/taxon-search-container/taxon-search-container.component';
import taxaSelect from './components/taxa-select/taxa-select.component';
import routerConfig from './routes';
import taxonConfiguration from './configuration';

const moduleName = 'shu-taxon';

angular.module(moduleName, ['lumx'])
  .factory('TaxonReferentielsRepository', taxonReferentielsRepository)
  .factory('TaxonSearchService', taxonSearchService)
  .factory('TaxonSearchStore', taxonSearchStore)
  .directive('shuTaxonSearchTaxon', taxonSearchTaxon)
  .directive('shuTaxaSelect', taxaSelect)
  .constant('TaxonConfiguration', taxonConfiguration)
  .config(routerConfig);

export default { moduleName };
