import { REFERENTIEL } from '../base/services/url-resolver.service';

export default {
  searchEndpoint: {
    server: REFERENTIEL,
    path: '/{txRefType}/{txRefVersion}/taxon/search/{query}'
  },
  taxonDetailsEndpoint: {
    server: REFERENTIEL,
    path: '/{txRefType}/{txRefVersion}/taxon/{taxonKey}'
  }
};
