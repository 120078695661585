import { STATUS } from '../base/services/url-resolver.service';

export default {
  testEndpoint: {
    server: STATUS,
    path: '/section'
  },
  sectionDetailsEndpoint: {
    server: STATUS,
    path: '/section/{sectionId}'
  },
  sectionCondolidateEndpoint: {
    server: STATUS,
    path: '/section/{sectionId}/consolidate'
  },
  sectionCreateEndpoint: {
    server: STATUS,
    path: '/section/create'
  },
};
