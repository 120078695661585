export default TerrUnitRouterConfig;

// @ngInject
function TerrUnitRouterConfig($stateProvider) {
  $stateProvider.state('terrunit', {
    url: '/terrunit',
    template: '<shu-terrunit-dummy-container></shu-terrunit-dummy-container>',
    needAuthenticatedUser: false
  });
}

// @ngInject
function TerrUnitRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.terrunitId = $stateParams.terrunitId;
}