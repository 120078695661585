import { STATUS, REFERENTIEL  } from '../base/services/url-resolver.service';


export default {
  publicationImportEndpoint: {
    server: STATUS,
    path: '/publication/{publicationId}/upload'
  },
  consolidationCheckEndpoint: {
    server: STATUS,
    path: '/publication/{publicationId}/taxon-check'
  },
  inputMaskEndpoint: {
    server: STATUS,
    path: '/publication/{publicationId}/inputMask'
  },

  lastRefVersionEndpoint: {
    server: REFERENTIEL,
    path: '/tx_ref/{txRefType}/current'
  }
}
