export default ModerationRouterConfig;

// @ngInject
function ModerationRouterConfig($stateProvider) {
  $stateProvider.state('moderation-dashboard', {
    url: '/moderation/dashboard',
    template: '<shu-moderation-dashboard-container></shu-moderation-dashboard-container>',
    needAuthenticatedUser: true,
    controller: ModerationRouteController,
    controllerAs: 'routeCtrl'
  });
}

// @ngInject
function ModerationRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.moderationId = $stateParams.moderationId;
}
