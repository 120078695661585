export default AuthInterceptor;

// @ngInject
function AuthInterceptor($injector, $q, $rootScope, AuthStore, LxNotificationService) {
  return {
    'request': function(config) {
      if (AuthStore.isAuthenticated()) {
        config.headers.Authorization = 'Bearer ' + AuthStore.getState().token;
      }
      return config;
    },
    'responseError': function(rejection) {
      if (rejection.status === 401 && !rejection.config.doNotIntercept) {
        // On injecte AuthService au runtime pour éviter
        // des problèmes de dépendences cycliques
        $injector.get('AuthService').makeLoginExpired();
        return $q.reject(rejection);
      }
      if (rejection.status === 403 && !rejection.config.doNotIntercept) {
        // On injecte AuthService au runtime pour éviter
        // des problèmes de dépendences cycliques
        //$injector.get('AuthService').forceLogOut();
        LxNotificationService.info('Action non autorisée.');
        return $q.reject(rejection);
      }
      return $q.reject(rejection);
    }
  };
}
