import * as models from '@biotope/shuriken-status-api'
import { StatusValueApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';

export const EVENTS = {
  STATUS_VALUES_LOADING: 'SHU-STATUS_VALUES_LOADING',
}

export class StatusValueService {

    private statusValueApi: StatusValueApi;
    private toaster: any;
    private $rootScope: any;

    /** @ngInject */
    constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, StatusValueApi: StatusValueApi) {
      this.statusValueApi = StatusValueApi;
      this.toaster = LxNotificationService;
      this.$rootScope = $rootScope;
    }

    public getStatusValues(statusTypeId: number) {
      this.$rootScope.$emit(EVENTS.STATUS_VALUES_LOADING);

      /*return this.statusValueApi..getSection(statusTypeId)
      .then(
          (response) => {
          this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_SUCCESS, _.defaults({}, response.data));
        }, (error: any) => {
          //console.log(error);
          this.toaster.error('Une erreur ***. ' + (error.data || ''));
          this.$rootScope.$emit(EVENTS.SECTION_DETAILS_LOAD_ERROR, error);
        });*/
    }
}
