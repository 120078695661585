const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'une publication
  PUBLICATION_DETAILS_LOADING: 'shu-publication-details.publication-details-loading',
  PUBLICATION_DETAILS_PROCESSING: 'shu-publication-details.publication-details-processing',
  PUBLICATION_DETAILS_PROCESS_DONE: 'shu-publication-details.publication-details-process-done',
  PUBLICATION_DETAILS_LOAD_SUCCESS: 'shu-publication-details.publication-details-load-success',
  PUBLICATION_DETAILS_LOAD_ERROR: 'shu-publication-details.publication-details-load-error'
}

export default PublicationDetailsService;

// @ngInject
function PublicationDetailsService($http, $rootScope, PublicationRepository, PublicationDetailsStore, LxNotificationService) {
  return {
    load,
    exportStatus,
    getMask,
    uploadStatusFile
  };

  function load(publicationId) {
    $rootScope.$emit(EVENTS.PUBLICATION_DETAILS_LOADING);

    PublicationRepository.getPublication(publicationId)
      .then(
        (publication) => $rootScope.$emit(EVENTS.PUBLICATION_DETAILS_LOAD_SUCCESS, publication),
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement de la publication. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.PUBLICATION_DETAILS_LOAD_ERROR);
        }
      );
  }

  function exportStatus(publicationId) {
    PublicationRepository.exportPublication(publicationId)
      .then(
        (error) => {
          LxNotificationService.error('Une erreur est survenue à l\'export de la publication. ' + (error.data || ''));
        }
      );
  }

  function getMask(publicationId) {
    PublicationRepository.getMask(publicationId)
      .then(
        (error) => {
          LxNotificationService.error('Une erreur est survenue à récupération du masque de saisie. ' + (error.data || ''));
        }
      );
  }

  function uploadStatusFile(publicationId) {
    PublicationRepository.uploadStatusFile(publicationId)
      .then(
        (error) => {
          LxNotificationService.error('Une erreur est survenue à récupération du masque de saisie. ' + (error.data || ''));
        }
      );
  }

}

export {
  EVENTS
};
