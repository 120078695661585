import angular from 'angular';

import statusTypeSelect from './components/status-type-select/status-type-select.component';
import statusTypeListService from './services/status-type-list.service';
import statusTypeListStore from './stores/status-type-list.store';
import statusTypeRepository from './repositories/status-type.repository';
import statusTypeKeyFilter from './filters/status-type-key.filter';
import { StatusTypeService } from './services/status-type.service';
import routerConfig from './routes';
import statustypeConfiguration from './configuration';

const moduleName = 'shu-status-type';

angular.module(moduleName, ['lumx'])
  .directive('shuStatusTypeSelect', statusTypeSelect)
  .service('StatusTypeService', StatusTypeService)
  .factory('StatusTypeListStore', statusTypeListStore)
  .factory('StatusTypeRepository', statusTypeRepository)
  .factory('StatusTypeListService', statusTypeListService)
  .filter('statusTypeKeyLabel', statusTypeKeyFilter)
  .constant('StatusTypeConfiguration', statustypeConfiguration)
  .config(routerConfig);

export default { moduleName };
