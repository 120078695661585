import { EVENTS as MODERATION_STORE_EVENTS } from '../../stores/moderation-service.store';


require('./moderation-pending-publications.scss');

export default ModerationPendingPublicationsDirective;

import * as models from '@biotope/shuriken-status-api'

import _ from 'lodash';

import { ModerationService } from '../../services/moderation.service';

// @ngInject
function ModerationPendingPublicationsDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    bindToController: true,
    controller: ModerationPendingPublicationsController,
    controllerAs: 'moderationPendingPublicationsCtrl',
    template: require('./moderation-pending-publications.html'),
    replace: true
  };
}

// @ngInject
function ModerationPendingPublicationsController($scope, $state, ModerationServiceStore, ModerationService: ModerationService) {
  var moderationPendingPublicationsCtrl = this;

  moderationPendingPublicationsCtrl.moderationState = {};

  moderationPendingPublicationsCtrl.publicationSearchResults = []

  moderationPendingPublicationsCtrl.isLoading = true;

  moderationPendingPublicationsCtrl.reloadPublicationSearchResults = reloadPublicationSearchResults;

  moderationPendingPublicationsCtrl.onPublicationSelected = onPublicationSelected;

  $scope.$on(MODERATION_STORE_EVENTS.MODERATION_PENDING_PUBLICATION_SERVICE_STATE_CHANGED, reloadPublicationSearchResults);

  ModerationService.getModeratorPublicationsList();

  function reloadPublicationSearchResults() {

    moderationPendingPublicationsCtrl.moderationState = ModerationServiceStore.getPendingPublicationsState();
    moderationPendingPublicationsCtrl.publicationSearchResults = moderationPendingPublicationsCtrl.moderationState.pendingPublications;
    moderationPendingPublicationsCtrl.isLoading = false;
  }

  function onPublicationSelected(publicationId) {
    $state.go('publication-overview', {publicationId});
  }

}
