import angular from 'angular';

import moderationDashboardContainer from './components/moderation-dashboard-container/moderation-dashboard-container.component';
import moderationPendingPublications from './components/moderation-pending-publications/moderation-pending-publications.component';
import moderationPendingSections from './components/moderation-pending-sections/moderation-pending-sections.component';
import { ModerationService } from '../moderation/services/moderation.service';
import moderationServiceStore from './stores/moderation-service.store';
import routerConfig from './routes';
import moderationConfiguration from './configuration';

const moduleName = 'shu-moderation';

angular.module(moduleName, ['lumx'])
  .directive('shuModerationPendingPublications', moderationPendingPublications)
  .directive('shuModerationPendingSections', moderationPendingSections)
  .directive('shuModerationDashboardContainer', moderationDashboardContainer)
  .service('ModerationService', ModerationService)
  .factory('ModerationServiceStore', moderationServiceStore)
  .constant('ModerationConfiguration', moderationConfiguration)
  .config(routerConfig);

export default { moduleName };
