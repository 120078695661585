import _ from 'lodash';

import { EVENTS as TAXA_SEARCH_STORE_EVENTS } from '../../stores/taxon-search.store';

import TaxonSearchService from '../../services/taxon-search.service';

require('./taxa-select.scss');

export default TaxaSelectDirective;

// @ngInject
function TaxaSelectDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueProvider: '&initialValue',
      refProvider: '&ref',
      placeholder: '@',
      required: '@',
      multipleProvider: '&multiple',
      name: "@",
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit'
    },
    bindToController: true,
    controller: TaxaSelectController,
    controllerAs: 'taxaSelectCtrl',
    template: require('./taxa-select.html'),
    replace: true
  };
}

// @ngInject
function TaxaSelectController($scope, TaxonSearchService, TaxonSearchStore) {
  var taxaSelectCtrl = this;

  taxaSelectCtrl.currentValue = null;
  taxaSelectCtrl.deletedValue = null;
  taxaSelectCtrl.availableValues = [];
  taxaSelectCtrl.state = {};

  taxaSelectCtrl.onTaxaUpdated = onTaxaUpdated;
  taxaSelectCtrl.onChange = onChange;

  //taxaSelectCtrl.canEdit = true;
  //taxaSelectCtrl.multiple = false

  $scope.$on(TAXA_SEARCH_STORE_EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED, reloadResults);
  //$scope.$on(TAXA_SEARCH_STORE_EVENTS.TAXON_SEARCH_QUERY_STATE_CHANGED, reloadResults);


  //TaxonSearchService.resetTaxa({"key": "COL", "version": "1.0"});

  $scope.$watch(taxaSelectCtrl.canEditProvider, (canEdit) => {
    taxaSelectCtrl.canEdit = canEdit;
  });

  $scope.$watch(taxaSelectCtrl.multipleProvider, (multiple) => {
    taxaSelectCtrl.multiple = multiple;
  });

  $scope.$watch(taxaSelectCtrl.refProvider, (ref) => {
    taxaSelectCtrl.ref = ref;
  });

  var removeWatcher = $scope.$watch(taxaSelectCtrl.initialValueProvider, (initialValue) => {
    if (!initialValue) {
      return;
    }

    taxaSelectCtrl.initialValue = initialValue;
    //TerrUnitService.getTerrUnitByKey(initialValueId);

    //updateInitialValue();

    removeWatcher();
  });

  function onTaxaUpdated(newValue) {
    TaxonSearchService.onUpdateForm(newValue, {"key": taxaSelectCtrl.ref.txRefType, "version": taxaSelectCtrl.ref.txRefVersion});
  }

  function reloadResults() {
    taxaSelectCtrl.state = TaxonSearchStore.getResultsState();


    if (!taxaSelectCtrl.state.results) {
      return;
    }

    taxaSelectCtrl.availableValues = [];

    _.forEach(_.reject(taxaSelectCtrl.state.results, 'deleted'), function(x) {
      taxaSelectCtrl.availableValues.push(x.asMutable());
    })

    updateInitialValue();
  }

  function updateInitialValue() {

    if (!taxaSelectCtrl.initialValueId || !taxaSelectCtrl.state.loaded) {
      return;
    }

    var initialValue = _.find(taxaSelectCtrl.state.taxons, {key: taxaSelectCtrl.initialValueId});
    if (!initialValue) {
      return;
    }

    //onChange(initialValue.name);

    //taxaSelectCtrl.onChangeFn() && taxaSelectCtrl.onChangeFn()(initialValue.name);
    if (initialValue.deleted) {
      taxaSelectCtrl.deletedValue = initialValue;
      return;
    }

    taxaSelectCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {

    if (!newValue) {
      return;
    }

    taxaSelectCtrl.onChangeFn() && taxaSelectCtrl.onChangeFn()(taxaSelectCtrl.initialValue, newValue);

    TaxonSearchService.onUpdateForm(newValue, {"key": taxaSelectCtrl.ref.txRefType, "version": taxaSelectCtrl.ref.txRefVersion});
  }


}
