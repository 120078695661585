import _ from 'lodash';

const EVENTS = {
  // Action lancée quand un des champs du formulaire de recherche est modifié
  TAXON_SEARCH_QUERY_UPDATE: 'shu-taxon-search.taxon-search-query-update',
  // Actions lancées quand l'utilisateur lance une recherche
  TAXON_SEARCH_RESULTS_LOADING: 'shu-taxon-search.taxon-search-results-loading',
  TAXON_SEARCH_RESULTS_LOAD_SUCCESS: 'shu-taxon-search.taxon-search-results-load-success',
  TAXON_SEARCH_RESULTS_LOAD_ERROR: 'shu-taxon-search.taxon-search-results-load-error',
  // Actions lancées quand l'utilisateur consulte les résultats suivants d'une recherche
  TAXON_SEARCH_NEXT_RESULTS_LOADING: 'shu-taxon-search.taxon-search-next-results-loading',
  TAXON_SEARCH_NEXT_RESULTS_LOAD_SUCCESS: 'shu-taxon-search.taxon-search-next-results-load-success',
  TAXON_SEARCH_NEXT_RESULTS_LOAD_ERROR: 'shu-taxon-search.taxon-search-next-results-load-error'
};
const LIMIT = 20;

export default TaxonSearchService;

// @ngInject
function TaxonSearchService($http, $rootScope, TaxonReferentielsRepository, $q, $state, LxNotificationService) {
  return {onUpdateForm, loadNextResults, resetTaxa};

  function onUpdateForm(query, selectedReferentiel) {
    $rootScope.$emit(EVENTS.TAXON_SEARCH_QUERY_UPDATE, {query, selectedReferentiel});
    // On ne requête le serveur qu'à partir de 3 caractères
    if (!query || query.length <= 2 || !selectedReferentiel) {
      return;
    }

    $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOADING);

    TaxonReferentielsRepository.query(query, selectedReferentiel.key, selectedReferentiel.version, null, 0, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOAD_SUCCESS, result.taxons, result.count, {query, selectedReferentiel});
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOAD_ERROR, {query, selectedReferentiel});
      });
  }

  function loadNextResults(query, referentiel, currentOffset) {
    $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOADING);

    TaxonReferentielsRepository.query(query, referentiel.key, referentiel.version, null, currentOffset, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, result.taxons, result.count, {query, referentiel});
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_ERROR, {query, referentiel});
      });
  }

  function resetTaxa(selectedReferentiel) {
    this.onUpdateForm('Bufo', selectedReferentiel);
  }

}

export { EVENTS };
