import _ from 'lodash';

import { EVENTS as STATUS_TYPE_LIST_STORE_EVENTS } from '../../stores/status-type-list.store';

require('./status-type-select.scss');

export default StatusTypeSelectDirective;

// @ngInject
function StatusTypeSelectDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueIdProvider: '&initialValueId',
      placeholder: '@',
      required: '@',
      multipleProvider: '&multiple',
      name: "@",
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit'
    },
    bindToController: true,
    controller: StatusTypeSelectController,
    controllerAs: 'statusTypeSelectCtrl',
    template: require('./status-type-select.html'),
    replace: true
  };
}

// @ngInject
function StatusTypeSelectController($scope, StatusTypeListService, StatusTypeListStore) {
  var statusTypeSelectCtrl = this;

  statusTypeSelectCtrl.onChange = onChange;
  statusTypeSelectCtrl.currentValue = null;
  statusTypeSelectCtrl.deletedValue = null;

  $scope.$on(STATUS_TYPE_LIST_STORE_EVENTS.STATUS_TYPE_LIST_STATE_CHANGED, reloadResults);
  statusTypeSelectCtrl.availableValues = [];
  statusTypeSelectCtrl.state = {};
  StatusTypeListService.load();

  $scope.$watch(statusTypeSelectCtrl.canEditProvider, (canEdit) => {
    statusTypeSelectCtrl.canEdit = canEdit;
  });

  $scope.$watch(statusTypeSelectCtrl.multipleProvider, (multiple) => {
    statusTypeSelectCtrl.multiple = multiple;
  });

  var removeWatcher = $scope.$watch(statusTypeSelectCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId) {
      return;
    }

    statusTypeSelectCtrl.initialValueId = initialValueId;
    updateInitialValue();

    removeWatcher();
  });

  function reloadResults() {
    statusTypeSelectCtrl.state = StatusTypeListStore.getState();

    if (!statusTypeSelectCtrl.state.statusType) {
      return;
    }

    statusTypeSelectCtrl.availableValues = [];

    angular.forEach(_.reject(statusTypeSelectCtrl.state.statusType, 'deleted'), function(x) {
      statusTypeSelectCtrl.availableValues.push(x.asMutable());
    })
    
    updateInitialValue();
  }

  function updateInitialValue() {
    if (!statusTypeSelectCtrl.initialValueId || !statusTypeSelectCtrl.state.loaded) {
      return;
    }

    var initialValue = _.find(statusTypeSelectCtrl.state.statusType, {key: statusTypeSelectCtrl.initialValueId});
    if (!initialValue) {
      return;
    }

    onChange(initialValue);
    if (initialValue.deleted) {
      statusTypeSelectCtrl.deletedValue = initialValue;
      return;
    }

    statusTypeSelectCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {
    if (!newValue) {
      return;
    }
    statusTypeSelectCtrl.onChangeFn() && statusTypeSelectCtrl.onChangeFn()(newValue);
  }

}
