import { EVENTS as PUBLICATION_STORE_EVENTS } from '../../stores/publication-details.store';
import { EVENTS as PROFILE_STORE_EVENTS } from '../../../profile/stores/profile.store';

require('./publication-overview-container.scss');

export default PublicationOverviewContainerDirective;

// @ngInject
function PublicationOverviewContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      publicationId: '@'
    },
    bindToController: true,
    controller: PublicationOverviewContainerController,
    controllerAs: 'publicationOverviewContainerCtrl',
    template: require('./publication-overview-container.html'),
    replace: true
  };
}

// @ngInject
function PublicationOverviewContainerController($scope, $state, HeaderService, LxNotificationService,
                  PublicationDetailsStore, PublicationDetailsService, ProfileStore) {
  var publicationOverviewContainerCtrl = this;

  var publicationOverviewContainerCtrl = this;
  publicationOverviewContainerCtrl.publicationState = {};
  publicationOverviewContainerCtrl.profileState = ProfileStore.get();
  publicationOverviewContainerCtrl.headerUpdated = false;

  publicationOverviewContainerCtrl.isLoaded = isLoaded;
  publicationOverviewContainerCtrl.isProcessing = isProcessing;

  publicationOverviewContainerCtrl.onMainTabChanged = onMainTabChanged;

  $scope.$on(PUBLICATION_STORE_EVENTS.PUBLICATION_DETAILS_STATE_CHANGED, reload);
  $scope.$on(PROFILE_STORE_EVENTS.PROFILE_STATE_CHANGED, onProfileStateChanged);

  loadPublication();

  function isLoaded() {
    return publicationOverviewContainerCtrl.publicationState && publicationOverviewContainerCtrl.publicationState.loaded;
  }

  function isProcessing() {
    return publicationOverviewContainerCtrl.publicationState && publicationOverviewContainerCtrl.publicationState.processing;
  }

  function reload() {
    publicationOverviewContainerCtrl.publicationState = PublicationDetailsStore.getState();
    if (publicationOverviewContainerCtrl.publicationState.error) {
      return;
    }
    updateHeader();
  }

  function updateHeader() {
    if (!publicationOverviewContainerCtrl.publicationState.publication || publicationOverviewContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: publicationOverviewContainerCtrl.publicationState.publication.publication.label,
        link: {
          sref: 'publication-overview',
          params: {publicationId: publicationOverviewContainerCtrl.publicationId}
        }
      }
    ]);
    publicationOverviewContainerCtrl.headerUpdated = true;
  }

  function loadPublication() {
    PublicationDetailsService.load(publicationOverviewContainerCtrl.publicationId);
  }

  function onMainTabChanged(mainTabFormData) {
    publicationOverviewContainerCtrl.mainTabFormData = mainTabFormData;
  }

  function onProfileStateChanged() {
    publicationOverviewContainerCtrl.profileState = ProfileStore.get();
  }
}
