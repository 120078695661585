export default ExpertRepository;

// @ngInject
function ExpertRepository(ExpertConfiguration, $q) {
  return {query};

  function query(query, offset, limit) {
    // TODO: si un jour on à besoin de cette liste
    return $q.when([]);
  }
}
