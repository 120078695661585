import signinContainerComponent from './components/signin-container/signin-container.component';
import signinFormComponent from './components/signin-form/signin-form.component';
import authStore from './stores/auth.store';
import authService, { EVENTS as AUTH_SERVICE_EVENTS } from './services/auth.service';
import authInterceptor from './interceptors/auth.interceptor';
import routerConfig from './routes';
import authConfiguration from './configuration';

const moduleName = 'shu-auth';

angular.module(moduleName, ['ui.router', 'lumx'])
  .directive('shuSigninContainer', signinContainerComponent)
  .directive('shuSigninForm', signinFormComponent)
  .factory('AuthStore', authStore)
  .factory('AuthService', authService)
  .constant('AuthConfiguration', authConfiguration)
  .config(routerConfig)
  .factory('AuthHttpInterceptor', authInterceptor)
  .config(/** @ngInject */ ($httpProvider) => {
    $httpProvider.interceptors.push('AuthHttpInterceptor');
  })
  .run(/** @ngInject */ ($rootScope, $http, $state, $stateParams, AuthStore) => {
    $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
      if (toState.needAuthenticatedUser && !AuthStore.isAuthenticated()) {
        $state.go('signin', {toState: toState.name, toParams: angular.toJson(toParams)});
        event.preventDefault();
      }
      if (toState.ignoreAuthenticatedUser && AuthStore.isAuthenticated()) {
        $state.go('publication-search');
        event.preventDefault();
      }
    });
    $rootScope.$on(AUTH_SERVICE_EVENTS.AUTH_LOST, (event) => {
      $state.go('signin', {toState: $state.current.name, toParams: angular.toJson($stateParams)});
    });
  });

export default {moduleName};
