import _ from 'lodash';

import { EVENTS as STUDY_LIST_STORE_EVENTS } from '../../stores/study-list.store';

import { StudyService } from '../../services/study.service';

require('./study-select.scss');

export default StudySelectDirective;

// @ngInject
function StudySelectDirective() {
  return {
    restrict: 'E',
    scope: {
    },
    bindToController: true,
    controller: StudySelectController,
    controllerAs: 'studySelectCtrl',
    template: require('./study-select.html'),
    replace: true
  };
}

// @ngInject
function StudySelectController($scope, StudyService: StudyService, StudyListStore) {
  var studySelectCtrl = this;

  studySelectCtrl.currentValue = null;
  studySelectCtrl.deletedValue = null;
  studySelectCtrl.availableValues = [];
  studySelectCtrl.state = {};

  studySelectCtrl.onStudyUpdated = onStudyUpdated;
  studySelectCtrl.onChange = onChange;

  $scope.$on(STUDY_LIST_STORE_EVENTS.STUDY_LIST_STATE_CHANGED, reloadResults);

  StudyService.resetTerrUnit();

  $scope.$watch(studySelectCtrl.canEditProvider, (canEdit) => {
    studySelectCtrl.canEdit = canEdit;
  });

  $scope.$watch(studySelectCtrl.multipleProvider, (multiple) => {
    studySelectCtrl.multiple = multiple;
  });

  var removeWatcher = $scope.$watch(studySelectCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId) {
      return;
    }

    studySelectCtrl.initialValueId = initialValueId;
    //TerrUnitService.getTerrUnitByKey(initialValueId);

    updateInitialValue();

    removeWatcher();
  });

  function onStudyUpdated(newValue) {
    StudyService.onUpdateStudyForm(newValue);
  }

  function reloadResults() {
    studySelectCtrl.state = StudyListStore.getState();

    if (!studySelectCtrl.state.results) {
      return;
    }

    studySelectCtrl.availableValues = [];

    _.forEach(_.reject(studySelectCtrl.state.results, 'deleted'), function(x) {
      studySelectCtrl.availableValues.push(x.asMutable());
    })

    updateInitialValue();
  }

  function updateInitialValue() {

    if (!studySelectCtrl.initialValueId || !studySelectCtrl.state.loaded) {
      return;
    }

    var initialValue = _.find(studySelectCtrl.state.results, {key: studySelectCtrl.initialValueId});
    if (!initialValue) {
      return;
    }

    //onChange(initialValue.name);

    //studySelectCtrl.onChangeFn() && studySelectCtrl.onChangeFn()(initialValue.name);
    if (initialValue.deleted) {
      studySelectCtrl.deletedValue = initialValue;
      return;
    }

    studySelectCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {

    if (!newValue) {
      return;
    }
    studySelectCtrl.onChangeFn() && studySelectCtrl.onChangeFn()(newValue);

    StudyService.onUpdateStudyForm(newValue);
  }

}
