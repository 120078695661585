export default ImportRouterConfig;

// @ngInject
function ImportRouterConfig($stateProvider, $compileProvider) {
  $stateProvider.state('import', {
    url: '/publication/{publicationId}/upload',
    template: '<shu-import-container publication-id="{{ ::routeCtrl.publicationId }}"></shu-import-container>',
    needAuthenticatedUser: true,
    controller: ImportRouteController,
    controllerAs: 'routeCtrl'
  }).state('taxon-check', {
    url: '/publication/{publicationId}/taxon-check',
    template: '<shu-import-container publication-id="{{ ::routeCtrl.publicationId }}"></shu-import-container>',
    needAuthenticatedUser: true,
    controller: ImportRouteController,
    controllerAs: 'routeCtrl'
  });
  $compileProvider.debugInfoEnabled(true);
}

// @ngInject
function ImportRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.publicationId = $stateParams.publicationId;
}
