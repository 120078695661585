import angular from 'angular';

import selectTxGroupComponent from './components/select-txgroup/select-txgroup.component';
//import txGroupListService from './services/txgroup-list.service';
import txGroupListStore from './stores/txgroup-list.store';
//import txGroupRepository from './repositories/txgroup.repository';
import { TxGroupService } from '../txgroup/services/txgroup.service';
import txGroupKeyFilter from './filters/txgroup-key.filter';
//import txGroupConfiguration from './configuration';

const moduleName = 'shu-txgroup';

angular.module(moduleName, ['lumx'])
  .directive('shuSelectTxGroup', selectTxGroupComponent)
  //.factory('TxGroupRepository', txGroupRepository)
  //.factory('TxGroupListService', txGroupListService)
  .service('TxGroupService', TxGroupService)
  .factory('TxGroupListStore', txGroupListStore)
  .filter('txGroupKeyLabel', txGroupKeyFilter)
  //.constant('TxGroupConfiguration', txGroupConfiguration);

export default {moduleName};
