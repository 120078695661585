import _ from 'lodash';

const STORAGE_KEY = 'shu-section-section.repository';

export default SectionRepository;

// @ngInject
function SectionRepository($http, SectionConfiguration) {
  return { getSection, updateSection, deleteSection, consolidateSection, createSection};

  function getSection(publicationId, sectionId) {
    return $http({
      method: 'GET',
      url: SectionConfiguration.sectionDetailsEndpoint,
      pathParams: {publicationId, sectionId}
    }).then((response) => _.defaults({}, response.data));
  }

  function updateSection(sectionId, sectionForm) {
    return $http({
      method: 'PUT',
      url: SectionConfiguration.sectionDetailsEndpoint,
      pathParams: {sectionId},
      data: _.merge(sectionForm)
    })
    .then((response) => response.data)
  }

  function createSection(sectionForm) {
    return $http({
      method: 'PUT',
      url: SectionConfiguration.sectionCreateEndpoint,
      data: _.merge(sectionForm)
    })
    .then((response) => response.data)
  }

  function deleteSection(sectionId) {
    return $http({
      method: 'DELETE',
      url: SectionConfiguration.sectionDetailsEndpoint,
      pathParams: {sectionId}
      })
      .then((response) => response.data)
  }

  function consolidateSection(sectionId) {

    return $http({
      method: 'PUT',
      url: SectionConfiguration.sectionCondolidateEndpoint,
      pathParams: {sectionId}
    })

    .then((response) => response.data)
  }


}
