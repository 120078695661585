import angular from 'angular';

import { TaxaStatusService } from './services/taxa-status.service';
import taxaStatusStore from './stores/taxa-status.store';
import routerConfig from './routes';

const moduleName = 'shu-taxastatus';

angular.module(moduleName, ['lumx'])
  .factory('TaxaStatusStoreStore', taxaStatusStore)
  .service('TaxaStatusService', TaxaStatusService)
  .config(routerConfig);

export default { moduleName };
