import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/terr-unit.service';

export default TerrUnitListStore;

const EVENTS = {
  TERR_UNIT_LIST_STATE_CHANGED: 'shu-terr-unit-list.terr-unit-list-state-changed'
};

// @ngInject
function TerrUnitListStore($rootScope) {
  var state = Immutable({});
  var stateByKey = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TERR_UNIT_LIST_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.TERR_UNIT_LIST_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TERR_UNIT_LIST_LOAD_ERROR, onLoadError);

  $rootScope.$on(SERVICE_EVENTS.TERR_UNIT_BY_KEY_LOADING, onByKeyLoading);
  $rootScope.$on(SERVICE_EVENTS.TERR_UNIT_BY_KEY_LOAD_SUCCESS, onByKeyLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TERR_UNIT_BY_KEY_LOAD_ERROR, onByKeyLoadError);

  return {getState, getStateByKey};

  function getState() {
    return state;
  }

  function getStateByKey() {
    return stateByKey;
  }

  function onLoading() {
    state = Immutable({
      results: null,
      totalResults: 0,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TERR_UNIT_LIST_STATE_CHANGED);
  }

  function onLoadSuccess(event, results, totalResults) {
    state = Immutable({
      results,
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TERR_UNIT_LIST_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TERR_UNIT_LIST_STATE_CHANGED);
  }

  function onByKeyLoading() {
    state = Immutable({
      results: null,
      totalResults: 0,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TERR_UNIT_BY_KEY_STATE_CHANGED);
  }

  function onByKeyLoadSuccess(event, results, totalResults) {
    state = Immutable({
      results,
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TERR_UNIT_BY_KEY_STATE_CHANGED);
  }

  function onByKeyLoadError() {
    state = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TERR_UNIT_BY_KEY_STATE_CHANGED);
  }

}

export { EVENTS };
