import angular from 'angular';

import consolidationToolContainer from './components/consolidation-tool-container/consolidation-tool-container.component';
import consolidationFileUpload from './components/consolidation-file-upload/consolidation-file-upload.component';
import consolidationSelectRef from './components/consolidation-select-ref/consolidation-select-ref.component';
import consolidationResults from './components/consolidation-results/consolidation-results.component';
import { ConsolidationService } from './services/consolidation.service';
import { ConsolidationApi } from './api/ConsolidationApi';
import consolidationStore from './stores/consolidation.store';
import routerConfig from './routes';

const moduleName = 'shu-consolidation';

angular.module(moduleName, ['lumx'])
  .directive('shuConsolidationToolContainer', consolidationToolContainer)
  .directive('shuConsolidationFileUpload', consolidationFileUpload)
  .directive('shuConsolidationSelectRef', consolidationSelectRef)
  .directive('shuConsolidationResults', consolidationResults)
  .service('ConsolidationService', ConsolidationService)
  .service('ConsolidationApi', ConsolidationApi)
  .factory('ConsolidationStore', consolidationStore)
  .config(routerConfig);

export default { moduleName };
