import angular from 'angular';

import publicationSearchContainer from './components/publication-search-container/publication-search-container.component';
import publicationSearchForm from './components/publication-search-form/publication-search-form.component';
import publicationSearchResults from './components/publication-search-results/publication-search-results.component';
import publicationSearchService from './services/publication-search.service';
import publicationSearchStore from './stores/publication-search.store';
import publicationRepository from './repositories/publication.repository';
import publicationAdvancedSearchContainer from './components/publication-advanced-search-container/publication-advanced-search-container.component';
import publicationAdvancedSearchForm from './components/publication-advanced-search-form/publication-advanced-search-form.component';
import publicationDetailsContainer from './components/publication-details-container/publication-details-container.component';
import publicationDetails from './components/publication-details/publication-details.component';
import publicationDetailsStore from './stores/publication-details.store';
import publicationDetailsService from './services/publication-details.service';
import publicationOverview from './components/publication-overview/publication-overview.component';
import sectionStatusFilter from '../section/filters/section-status.filter';
import txGroupKeyFilter from '../txgroup/filters/txgroup-key.filter';
import publicationStatutesList from './components/publication-statutes-list/publication-statutes-list.component';
import publicationStatutesService from './services/publication-statutes.service';
import publicationStatutesStore from './stores/publication-statutes.store';
import publicationStatutesMetadataStore from './stores/publication-statutes-metadata.store';
import sectionDetailsService from '../section/services/section-details.service';
import sectionRepository from '../section/repositories/section.repository';
import sectionConfiguration from '../section/configuration';
import publicationOverviewContainer from './components/publication-overview-container/publication-overview-container.component';
import { PublicationService } from './services/publication.service';
import publicationNewContainer from './components/publication-new-container/publication-new-container.component';
import publicationUserList from './components/publication-user-list/publication-user-list.component';
import publicationServiceStore from './stores/publication-service.store';
import routerConfig from './routes';
import publicationConfiguration from './configuration';

import baseModule from '../base/base.module';
import profileModule from '../profile/profile.module';

const moduleName = 'shu-publication';

angular.module(moduleName, ['lumx', baseModule.moduleName,profileModule.moduleName])
  .directive('shuPublicationSearchForm', publicationSearchForm)
  .directive('shuPublicationSearchResults', publicationSearchResults)
  .directive('shuPublicationAdvancedSearchContainer', publicationAdvancedSearchContainer)
  .directive('shuPublicationAdvancedSearchForm', publicationAdvancedSearchForm)
  .directive('shuPublicationDetailsContainer', publicationDetailsContainer)
  .directive('shuPublicationDetails', publicationDetails)
  .directive('shuPublicationOverview', publicationOverview)
  .directive('shuPublicationStatutesList', publicationStatutesList)
  .directive('shuPublicationOverviewContainer', publicationOverviewContainer)
  .directive('shuPublicationNewContainer', publicationNewContainer)
  .directive('shuPublicationUserList', publicationUserList)
  .directive('shuPublicationSearchContainer', publicationSearchContainer)
  .service('PublicationService', PublicationService)
  .factory('PublicationSearchStore', publicationSearchStore)
  .factory('PublicationRepository', publicationRepository)
  .factory('PublicationDetailsStore', publicationDetailsStore)
  .factory('PublicationDetailsService', publicationDetailsService)
  .factory('PublicationStatutesService', publicationStatutesService)
  .factory('PublicationStatutesStore', publicationStatutesStore)
  .factory('PublicationStatutesMetadataStore', publicationStatutesMetadataStore)
  .factory('PublicationSearchService', publicationSearchService)
  .factory('SectionDetailsService', sectionDetailsService)
  .factory('PublicationServiceStore', publicationServiceStore)
  .factory('SectionRepository', sectionRepository)
  .constant('SectionConfiguration', sectionConfiguration)
  .filter('sectionStatusLabel', sectionStatusFilter)
  .filter('txGroupKeyLabel', txGroupKeyFilter)
  .constant('PublicationConfiguration', publicationConfiguration)
  .config(routerConfig);

export default { moduleName };
